import { Select, Space, Table } from 'antd'
import { calculateScrollX, generateSelectOptions } from 'modules/core/utils'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

const ContentItem = ({
  fieldName,
  index,
  data,
  type,
  dataSelected,
  setReclassificationData,
  setDataDimensions,
  allDimensionsList,
  valuesSelected,
}) => {
  const { t } = useTranslation()

  const setValues = (val, key, index) => {
    if (dataSelected.adjustment_type === 'RECLASSIFICATION') {
      setReclassificationData((prevState) => {
        return {
          ...prevState,
          [type]: prevState[type].map((el, indexState) => {
            if (indexState === index) {
              return {
                ...el,
                [key]: val,
              }
            }
            return el
          }),
        }
      })
    } else {
      setDataDimensions((prevState) =>
        prevState.map((el, indexstate) => {
          if (indexstate === index) {
            return {
              ...el,
              [key]: val,
            }
          }
          return el
        }),
      )
    }
  }

  const columns = (index, dataDim = []) => {
    return _.map(dataDim[index], (dataa, key) => {
      return {
        dataIndex: key,
        render: (value) => {
          return (
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Body level={2}>
                {allDimensionsList.find((el) => el.id === parseInt(key)).name_destination}
              </Typography.Body>
              <Select
                key={key}
                placeholder={t('ACTION_SELECT')}
                defaultValue={dataa}
                style={{ width: 300 }}
                onChange={(val) => setValues(val, key, index)}
                options={generateSelectOptions({
                  options: valuesSelected[key].map((el) => {
                    return { id: el.id, name: el.name_destination }
                  }),
                })}
              />
            </Space>
          )
        },
      }
    })
  }

  return (
    <>
      <div className="header-table-combination">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography.Body>
            {t('PLANNING_SAC_HEADER_COMBINATION_TITLE', { index: index + 1 })}
          </Typography.Body>
        </div>
        {/* {_.keys(data[field.name]).length >
                    0 && (
                    <Button.Icon
                    title="Editar"
                    icon={<Typography.Icon icon={EditOutlined} />}
                    onClick={() => {
                        setShowSelectModal(true)
                        setValueIndex(field.name)
                    }}
                    />
                )} */}
      </div>
      <Table
        size="small"
        dataSource={[data[fieldName]]}
        showHeader={false}
        columns={columns(fieldName, data)}
        pagination={false}
        scroll={{
          x: calculateScrollX(columns(fieldName, data)),
        }}
      />
    </>
  )
}

export default ContentItem
