import { Alert, Collapse } from 'antd'
import { Button, InputMonth, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import _ from 'lodash'

const CollapseA = ({ data, dataAmounts, setAmounts, onClickDetail, type }) => {
  const [dataInput, setDataInput] = useState('')
  const { t } = useTranslation()

  const renderTitleTooltip = () => {
    if (dataInput) {
      if (type === 'negative' && Math.sign(dataInput) !== -1)
        return 'PLANNING_SAC_INFORMATIVE_NEGATIVE_VALUES_ALERT_DESCRIPTION'
      if (type === 'positive' && Math.sign(dataInput) !== 1)
        return 'PLANNING_SAC_INFORMATIVE_POSITIVE_VALUES_ALERT_DESCRIPTION'
    }
    return ''
  }

  return (
    <Collapse style={{ marginTop: 10 }} accordion={true}>
      {data.map((el, index) => (
        <Collapse.Panel
          header={t('PLANNING_SAC_HEADER_COMBINATION_TITLE', { index: index + 1 })}
          extra={
            <Button.Link
              title="ACTION_OPEN_DETAIL"
              onClick={(e) => {
                e.stopPropagation()
                onClickDetail(index)
              }}
            />
          }
        >
          {dataInput &&
          ((type === 'negative' && Math.sign(dataInput) !== -1 && dataInput !== 0) ||
            (type === 'positive' && Math.sign(dataInput) !== 1 && dataInput !== 0)) ? (
            <Alert type="warning" showIcon description={t(renderTitleTooltip())} />
          ) : null}
          {_.map(dataAmounts[index], (value, date) => {
            return (
              <InputMonth
                index={index}
                month={date}
                defaultValue={value}
                style={{ paddingRight: 5 }}
                onChange={(val) => {
                  setAmounts(index, date, val)
                  setDataInput(val)
                }}
              />
            )
          })}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            <Typography.Body>{`${t('FIELD_TOTAL')}: ${_.reduce(
              dataAmounts[index],
              (acum, data) => {
                acum = acum + data
                return acum
              },
              0,
            )}`}</Typography.Body>
          </div>
        </Collapse.Panel>
      ))}
    </Collapse>
  )
}

export default CollapseA
