import React from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'

const AdjustmentCreationExitModal = ({
  visible,
  onClose,
  buttonName = 'ACTION_EXIT',
  onConfirm,
}) => {
  const { t } = useTranslation()
  return (
    <Modal
      title={t(buttonName)}
      visible={visible}
      closable={true}
      cancelText={t('ACTION_CANCEL')}
      onCancel={onClose}
      okText={t(buttonName)}
      onOk={onConfirm}
      destroyOnClose={true}
    >
      {t('EXIT_MODAL_DESCRIPTION')}
    </Modal>
  )
}

export default AdjustmentCreationExitModal
