import { Drawer, Select, Space } from 'antd'
import _ from 'lodash'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'

const DimensionDetailDrawer = ({
  visible,
  onClose,
  dataDimensions,
  allDimensionsList,
  valuesSelected,
}) => {
  const { t } = useTranslation()
  return (
    <Drawer
      title={t('PLANNING_SAC_COMBINATION_DETAIL_DRAWER_TITLE')}
      visible={visible}
      onClose={onClose}
    >
      {_.keys(dataDimensions).map((el) => (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Body>
            {allDimensionsList.find((dim) => dim.id === parseInt(el)).name_destination}
          </Typography.Body>
          <Select
            value={
              valuesSelected[el].find((valdim) => valdim.id === dataDimensions[el]).name_destination
            }
            disabled={true}
            style={{ width: '100%' }}
          />
        </Space>
      ))}
    </Drawer>
  )
}

export default DimensionDetailDrawer
