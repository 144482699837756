import { Col, message, notification, Row, Space, Spin } from 'antd'
import { Button, CreationExitModal, Typography } from 'modules/core/components'
import { LeftCircleOutlined } from '@ant-design/icons'
import { generateMessageError } from 'modules/core/utils'
import { useEffect, useState } from 'react'
import { SelectsOptions, StepsCreate } from './components'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import configuration from 'modules/configuration'
import planning from 'modules/planning'
import _ from 'lodash'

import './ApplicationManualCreation.scss'

const ApplicationManualCreation = ({
  onReturn,
  onRefetch,
  fetchAllDimensions,
  allDimensionsList,
  fetchAllDimensionsValues,
  fetchPeriodList,
  periodList,
  createAdjustment,
}) => {
  const [dataDimensions, setDataDimensions] = useState([{}])
  const [valuesSelected, setValuesSelected] = useState({})
  const [dimensionId, setDimensionId] = useState(null)
  const [amounts, setAmounts] = useState([])
  const [dataSelected, setDataSelected] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [reclassificationData, setReclassificationData] = useState({
    origin: [{}],
    destination: [{}],
  })
  const [reclassificationType, setReclassificationType] = useState('origin')
  const [reclassificationAmounts, setReclassificationAmounts] = useState({
    origin: [],
    destination: [],
  })
  const [showExitModal, setShowExitModal] = useState(false)
  const [step, setStep] = useState('actual')
  const [current, setCurrent] = useState(0)
  let { periodId, globalBudgetId } = useParams()
  const { t } = useTranslation()

  const dataPeriod = periodList.find((el) => el.id === parseInt(periodId))

  useEffect(() => {
    if (dataDimensions.length > 0) {
      const aux = dataDimensions.map(() => {
        return get_month_header(dataPeriod.start_date, dataPeriod.end_date).reduce((acum, data) => {
          acum = { ...acum, [data]: 0 }
          return acum
        }, {})
      })
      setAmounts(aux)
    }

    let dateOrigin = []

    if (reclassificationData.origin.length > 0) {
      dateOrigin = reclassificationData.origin.map(() => {
        return get_month_header(dataPeriod.start_date, dataPeriod.end_date).reduce((acum, data) => {
          acum = { ...acum, [data]: 0 }
          return acum
        }, {})
      })
    }

    let dateDestination = []
    if (reclassificationData.destination.length > 0) {
      dateDestination = reclassificationData.destination.map(() => {
        return get_month_header(dataPeriod.start_date, dataPeriod.end_date).reduce((acum, data) => {
          acum = { ...acum, [data]: 0 }
          return acum
        }, {})
      })
    }
    setReclassificationAmounts({
      ...reclassificationAmounts,
      destination: dateDestination,
      origin: dateOrigin,
    })
  }, [dataDimensions, dataPeriod, reclassificationData])

  useEffect(() => {
    fetchAllDimensions()
  }, [fetchAllDimensions])

  useEffect(() => {
    fetchPeriodList()
  }, [fetchPeriodList])

  useEffect(() => {
    if (dimensionId) {
      fetchAllDimensionsValues({ id_dimension: dimensionId }).then((response) => {
        setValuesSelected((prevState) => {
          return { ...prevState, [dimensionId]: response.payload.data }
        })
      })
    }
  }, [fetchAllDimensionsValues, dimensionId])

  const get_month_header = (start_date, end_date) => {
    let year = parseInt(start_date.split('-', 2)[0])
    let month = parseInt(start_date.split('-', 2)[1])

    const month_name_list = []

    while (`${year}-${month <= 9 ? `0${month}` : month}` <= end_date) {
      month_name_list.push(`${year}-${month <= 9 ? `0${month}` : month}`)

      //Avanzar al siguiente mes/año
      if (month === 12) {
        year = year + 1
        month = 1
      } else {
        month = month + 1
      }
    }

    return month_name_list
  }

  const handleConfirm = () => {
    const data = {
      period_id: periodId,
      projection: globalBudgetId,
      module: 'OVERALL_PLANNING',
      rows:
        dataSelected.adjustment_type !== 'RECLASSIFICATION'
          ? dataDimensions.map((dimension, index) => {
              return {
                amount: amounts[index],
                dimensions_filter: dimension,
                is_origin: false,
              }
            })
          : [
              ...reclassificationData.origin.map((dimOrigin, indexOrigin) => {
                return {
                  amount: reclassificationAmounts.origin[indexOrigin],
                  dimensions_filter: dimOrigin,
                  is_origin: true,
                }
              }),
              ...reclassificationData.destination.map((dimDestination, indexDestination) => {
                return {
                  amount: reclassificationAmounts.destination[indexDestination],
                  dimensions_filter: dimDestination,
                  is_origin: false,
                }
              }),
            ],
      ...dataSelected,
    }

    setIsSaving(true)
    createAdjustment(data)
      .then(() => {
        onRefetch()
        setIsSaving(false)
        setDataSelected({})
        setDataDimensions([])
        setAmounts([])
        onReturn()
        message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
      })
      .catch((error) => {
        setIsSaving(false)
        setDataSelected({})
        setDataDimensions([])
        setAmounts([])
        onReturn()
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error),
          duration: 0,
        })
      })
  }

  return (
    <Spin spinning={isSaving}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Space direction="horizontal">
            <Button.Icon
              title="ACTION_RETURN"
              icon={<LeftCircleOutlined style={{ fontSize: 20, color: '#0047ba' }} />}
              onClick={() => {
                if (
                  dataSelected.adjustment_type ||
                  dataSelected.currency ||
                  dataSelected.area ||
                  dataDimensions.length > 0 ||
                  reclassificationData.origin.length > 0 ||
                  reclassificationData.destination.length > 0
                ) {
                  setShowExitModal(true)
                } else {
                  onReturn()
                }
              }}
            />
            <Typography.Body>
              {t('PLANNING_SAC_RETURN_APPLICATIONS_LIST_TYPOGRAPHY')}
            </Typography.Body>
          </Space>
        </Col>
        <Col span={24}>
          <SelectsOptions setDataSelected={setDataSelected} current={current} />
        </Col>
        <Col span={24}>
          <StepsCreate
            dataDimensions={dataDimensions}
            dataSelected={dataSelected}
            reclassificationAmounts={reclassificationAmounts}
            reclassificationData={reclassificationData}
            amounts={amounts}
            handleConfirm={() => handleConfirm()}
            setDataDimensions={setDataDimensions}
            setReclassificationAmounts={setReclassificationAmounts}
            setReclassificationData={setReclassificationData}
            setReclassificationType={setReclassificationType}
            valuesSelected={valuesSelected}
            allDimensionsList={allDimensionsList}
            reclassificationType={reclassificationType}
            setAmounts={setAmounts}
            setDimensionId={setDimensionId}
            setShowExitModal={setShowExitModal}
            setStep={setStep}
            current={current}
            setCurrent={setCurrent}
          />
        </Col>
      </Row>
      <CreationExitModal
        visible={showExitModal}
        onClose={() => {
          setShowExitModal(false)
        }}
        buttonName="ACTION_RETURN"
        onConfirm={() => {
          if (step === 'actual') {
            setDataSelected({})
            setDataDimensions([])
            setReclassificationData({ origin: [], destination: [] })
            setReclassificationAmounts({ origin: [], destination: [] })
            setAmounts([])
            setReclassificationType('origin')
            setShowExitModal(false)
            onReturn()
          } else {
            setReclassificationAmounts({
              origin: reclassificationAmounts.origin.map((el) => {
                const keys = _.keys(el)
                let data = {}
                keys.forEach((key) => {
                  data = { ...data, [key]: 0 }
                })
                return data
              }),
              destination: reclassificationAmounts.destination.map((el) => {
                const keys = _.keys(el)
                let data = {}
                keys.forEach((key) => {
                  data = { ...data, [key]: 0 }
                })
                return data
              }),
            })
            setAmounts(
              amounts.map((el) => {
                const keys = _.keys(el)
                let data = {}
                keys.forEach((key) => {
                  data = { ...data, [key]: 0 }
                })
                return data
              }),
            )
            setCurrent((prevState) => prevState - 1)
            setShowExitModal(false)
          }
        }}
      />
    </Spin>
  )
}

const mapStateToProps = (state) => ({
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
  allDimensionsValues: configuration.selectors.getAllDimensionsValues(state),
  periodList: planning.selectors.getPeriodList(state),
})

const mapDispatchToProps = {
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  fetchAllDimensionsValues: configuration.actions.fetchAllDimensionsValues,
  fetchPeriodList: planning.actions.fetchPeriodList,
  createAdjustment: planning.actions.createAdjustment,
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationManualCreation)
