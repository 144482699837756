import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  DownloadModal,
  NestedTable,
  ProgressBar,
  TopTablePhasesActions,
} from 'modules/core/components'
import { Col, message, Row } from 'antd'
import { INTEGRATIONS, TABLES } from 'modules/core/constants'
import { connect } from 'react-redux'
import { normalizeFilters } from 'modules/core/utils/normalize-filters'
import { handleCellEdit, handleDelete, handleDeleteRow } from './utils'
import { useTranslation } from 'react-i18next'
import {
  handleSave,
  handleSavePrimary,
} from 'modules/control/containers/ControlDetail/components/Evolution/utils'
import planning from 'modules/planning'
import login from 'modules/login'
import moment from 'moment'
import control from 'modules/control'
import configuration from 'modules/configuration'
import _ from 'lodash'
import { generateMessageError } from 'modules/core/utils'

const DEFAULT_FORMAT_CREATE = {
  'Suma (Decimales)': 'float',
  'Suma (Enteros)': 'int',
  'Suma (Miles)': 't',
  'Suma (Millones)': 'm',
}

const DEFAULT_FORMAT_SET = {
  float: 'Suma (Decimales)',
  int: 'Suma (Enteros)',
  t: 'Suma (Miles)',
  m: 'Suma (Millones)',
}

const getRowAuxName = (rows, allDimensionsList) => {
  return rows.map((row) => {
    const dimension = allDimensionsList.find((el) => parseInt(row) === el.id)
    return dimension ? dimension.name_origin : ''
  })
}

const getColsAuxName = (columns, allDimensionsList) => {
  return columns.map((col) => {
    if (col === 'Fecha') {
      return 'Fecha'
    } else {
      const dimension = allDimensionsList.find((el) => parseInt(col) === el.id)
      return dimension ? dimension.name_origin : ''
    }
  })
}

const getRowAuxId = (settings, allDimensionsList) => {
  return (
    !_.isEmpty(settings) &&
    settings.rows.map((row) => {
      const id = allDimensionsList.find((el) => el.name_origin === row).id
      return id.toString()
    })
  )
}

const getColsAuxId = (settings, allDimensionsList) => {
  return (
    !_.isEmpty(settings) &&
    settings.cols.map((col) => {
      const id = allDimensionsList.find((el) => el.name_origin === col)
        ? allDimensionsList.find((el) => el.name_origin === col).id
        : null
      if (col === 'Fecha') {
        return 'Fecha'
      } else {
        return id.toString()
      }
    })
  )
}

const Nested = ({
  loading,
  dataPhase,
  budgetInstanceDetail,
  dataPeriod,
  canDelete,
  tableLoading,
  reloadTableNested,
  setData,
  comments,
  onRefetchComments,
  loadingComments,
  dataNestedTable,
  downloadBudgetTableByPhaseXLS,
  updateTransactionNested,
  loggedUser,
  createLayout,
  fetchLayoutList,
  layoutList,
  fetchAllDimensions,
  allDimensionsList,
  // updatePercentageTransactionNested,
  deleteTransactionFilter,
  deleteLayout,
  hasComment,
  setHasComment,
  createComment,
  removeNewComment,
  showProgressBar,
  downloadBudgetTableByPhaseExcel,
  downloadBudgetTableByPhaseParquet,
}) => {
  const [tableSettings, setTableSettings] = useState({
    ...TABLES.DEFAULT_SETTINGS,
    negativeValuesType: TABLES.NEGATIVE_VALUES_TYPES.NORMAL,
    showNegativeInRed: false,
  })
  const [dataDelete, setDataDelete] = useState({})
  const [isDeleting, setIsDeleting] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showPopover, setShowPopover] = useState(false)
  const [favouriteName, setFavouriteName] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [settings, setSettings] = useState({})
  const [isLayoutLoading, setIsLayoutLoading] = useState(false)
  const [viewSelect, setViewSelect] = useState({})
  const [viewComment, setViewComment] = useState(false)
  let { budgetId, periodId } = useParams()
  const { t } = useTranslation()

  const key = window.location.pathname.replaceAll('/', '-')

  useEffect(() => {
    setIsLayoutLoading(true)
    fetchLayoutList('planning', `${budgetId}-${dataPhase.key}`).then(() =>
      setIsLayoutLoading(false),
    )
  }, [fetchLayoutList, budgetId, dataPhase.key])

  useEffect(() => {
    fetchAllDimensions()
  }, [fetchAllDimensions])

  useEffect(() => {
    const defaultLayout = layoutList.values.find((el) => el.primary)
    setViewSelect(defaultLayout)
  }, [layoutList.values])

  useEffect(() => {
    if (layoutList.values.length > 0 && !_.isEmpty(viewSelect) && allDimensionsList.length > 0) {
      setSettings((prevState) => {
        return {
          ...prevState,
          ...viewSelect.config,
          hiddenFromDragDrop: ['amount', 'price', 'quantity'],
          cols:
            getColsAuxName(viewSelect.config.columns, allDimensionsList).length > 0
              ? getColsAuxName(viewSelect.config.columns, allDimensionsList)
              : ['Fecha'],
          rows: getRowAuxName(viewSelect.config.rows, allDimensionsList),
          aggregatorName: DEFAULT_FORMAT_SET[viewSelect.config.default_format],
          vals: [viewSelect.config.default_value],
        }
      })
    }
  }, [allDimensionsList, layoutList.values, viewSelect])

  useEffect(() => {
    setDataDelete(settings.tableFilter)
  }, [settings.tableFilter])

  const onCloseDeleteRow = () => {
    setIsDeleting(false)
  }

  const onClose = () => {
    setIsSaving(false)
    setShowPopover(false)
    setFavouriteName('')
  }

  const onCreate = () => {
    const data = {
      row_order: 0,
      column_order: 1,
      default_value: settings.vals[0],
      default_format: DEFAULT_FORMAT_CREATE[settings.aggregatorName],
      rows: getRowAuxId(settings, allDimensionsList),
      columns: getColsAuxId(settings, allDimensionsList),
    }

    return createLayout('planning', `${budgetId}-${dataPhase.key}`, favouriteName, { config: data })
  }

  const defaultData = !_.isEmpty(viewSelect) && {
    ...viewSelect.config,
    rows: getRowAuxName(viewSelect.config.rows, allDimensionsList),
    columns: getColsAuxName(viewSelect.config.columns, allDimensionsList),
    default_format: DEFAULT_FORMAT_SET[viewSelect.config.default_format],
  }
  const defaultSettings = !_.isEmpty(settings) && {
    rows: settings.rows,
    columns: settings.cols || ['Fecha'],
    row_order: settings.row_order,
    column_order: settings.column_order,
    default_value: settings.vals ? settings.vals[0] : '',
    default_format: settings.aggregatorName,
  }

  const onSavePrimary = (data) => {
    const dataAux = layoutList.values.find((el) => el.name === data.name)
    return createLayout('planning', `${budgetId}-${dataPhase.key}`, dataAux.name, {
      ...dataAux,
      primary: true,
    })
  }

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${key}-${dataPhase.key}-table-transacional`,
      module: `${key}-${dataPhase.key}`,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }

  const getFileName = () => {
    return t('PLANNING_PHASE_TABLE_FILE_NAME', {
      name: budgetInstanceDetail?.name,
      phase: t(dataPhase.title).toLowerCase(),
      conceptName: budgetInstanceDetail?.concept_name,
      periodName: dataPeriod.name,
      date: moment().format('lll'),
    })
  }

  const renderCanSave = () => {
    const { data, ...rest } = settings
    return !_.isEmpty(rest) || (!_.isEmpty(rest) && !_.isEqual(defaultData, defaultSettings))
  }

  return (
    <Row gutter={[4, 4]}>
      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
        {showProgressBar && <ProgressBar isLoading={tableLoading} />}
      </Col>
      <Col span={24}>
        <TopTablePhasesActions
          setTableSettings={setTableSettings}
          tableSettings={tableSettings}
          dataDelete={dataDelete}
          disabled={!canDelete}
          onConfirmDelete={() =>
            handleDeleteRow({
              setIsLoading: setIsDeleting,
              deleteRow: () =>
                deleteTransactionFilter(
                  budgetId,
                  dataPhase.key,
                  normalizeFilters(allDimensionsList, dataDelete),
                ),
              onRefetch: reloadTableNested,
              onClose: onCloseDeleteRow,
            })
          }
          isDeleting={isDeleting}
          loading={loading}
          onClickDownload={() =>
            downloadBudgetTableByPhaseXLS(
              t('PLANNING_PHASE_TABLE_FILE_NAME', {
                name: budgetInstanceDetail?.name,
                phase: t(dataPhase.title).toLowerCase(),
                conceptName: budgetInstanceDetail?.concept_name,
                periodName: dataPeriod.name,
                date: moment().format('lll'),
              }),
              {
                step: dataPhase.key,
                period_id: periodId,
                concept_id: budgetInstanceDetail?.concept_id,
                planning_id: budgetId,
              },
            )
          }
          onClickDownloadSpreadsheets={() => setShowModal(true)}
          onClickSaveFav={() => setShowPopover(true)}
          visibleFav={showPopover}
          onClickSave={() =>
            handleSave({
              setIsLoading: setIsSaving,
              createLayout: () => onCreate(),
              onRefetch: () => fetchLayoutList('planning', `${budgetId}-${dataPhase.key}`),
              onRefresh: reloadTableNested,
              onClose,
            })
          }
          onClickCancelSaveFav={onClose}
          setFavouriteName={setFavouriteName}
          favouriteName={favouriteName}
          isLoading={isSaving}
          hasFavourite={layoutList.values.length > 0}
          options={layoutList.values.map((el) => {
            return { name: el.name, id: el.name, primary: el.primary }
          })}
          canSave={renderCanSave()}
          viewSelect={viewSelect}
          setViewSelect={(val) => {
            const aux = layoutList.values.find((el) => el.name === val)
            setViewSelect(aux)
          }}
          alreadyExist={_.isEqual(defaultData, defaultSettings)}
          layoutLoading={isLayoutLoading}
          setPrimary={(data) =>
            handleSavePrimary({
              setIsLoading: setIsSaving,
              createLayout: () => onSavePrimary(data),
              onRefetch: () => fetchLayoutList('planning', `${budgetId}-${dataPhase.key}`),
              onClose: () => setIsSaving(false),
            })
          }
          onClickDeleteFav={(name) =>
            handleDelete({
              setIsLoading: setIsSaving,
              deleteLayout: () => deleteLayout('planning', `${budgetId}-${dataPhase.key}`, name),
              onRefetch: () => fetchLayoutList('planning', `${budgetId}-${dataPhase.key}`),
              onClose: () => setIsSaving(false),
            })
          }
          showComments={
            viewComment ||
            comments.filter(
              (el) => el.key === `${key}-${dataPhase.key}-table-transacional` && !el.resolved,
            ).length > 0
          }
          hasComment={hasComment}
          setHasComment={setHasComment}
          setViewComment={setViewComment}
          userDetail={loggedUser}
          settings={settings}
          setData={setData}
          comments={comments.filter(
            (el) =>
              (el.key === `${key}-${dataPhase.key}-table-transacional` && !el.resolved) || !el.text,
          )}
          onRefetchComments={onRefetchComments}
          loadingComments={loadingComments}
          onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
            handleCreate({ text, usersIds, idComment, setIsNewThread })
          }
          removeNewComment={removeNewComment}
          onDownloadExcel={() =>
            downloadBudgetTableByPhaseExcel(getFileName(), {
              step: dataPhase.key,
              period_id: periodId,
              concept_id: budgetInstanceDetail?.concept_id,
              planning_id: budgetId,
            })
          }
          onDownloadParquet={() =>
            downloadBudgetTableByPhaseParquet(getFileName(), {
              step: dataPhase.key,
              period_id: periodId,
              concept_id: budgetInstanceDetail?.concept_id,
              planning_id: budgetId,
            })
          }
        />
      </Col>
      <Col span={24}>
        <NestedTable
          dataNestedTable={dataNestedTable}
          tableLoading={tableLoading}
          onCellEdit={(amount, row, type_change, base_type) => {
            handleCellEdit({
              updateTransactionNested: () =>
                updateTransactionNested({
                  amount,
                  row,
                  type_change,
                  concept_id: budgetInstanceDetail?.concept_id,
                  planning_id: budgetId,
                  step: dataPhase.key,
                  period_id: periodId,
                  base_type,
                }),
              onRefetch: reloadTableNested,
            })
          }}
          canDelete={canDelete}
          settings={settings}
          setSettings={setSettings}
          defaultDataSettings={defaultData}
          setViewComment={setViewComment}
          hasComment={hasComment}
          setHasComment={setHasComment}
        />
      </Col>
      <DownloadModal
        title={INTEGRATIONS.KEYS.SPREADSHEETS_KEY}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        defaultValue={loggedUser.folder_id}
        dataSpreadsheets={{
          type_load: 'planning',
          params: {
            step: dataPhase.key,
            period_id: periodId,
            concept_id: budgetInstanceDetail?.concept_id,
            planning_id: budgetId,
          },
        }}
      />
    </Row>
  )
}

const mapStateToProps = (state) => ({
  dataNestedTable: planning.selectors.getDataNestedTablePhase(state),
  loggedUser: login.selectors.getWhoAmI(state),
  layoutList: control.selectors.getLayoutList(state),
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
  hasComment: configuration.selectors.getHasComment(state),
})

const mapDispatchToProps = {
  downloadBudgetTableByPhaseXLS: planning.actions.downloadBudgetTableByPhaseXLS,
  updateTransactionNested: planning.actions.updateTransactionNested,
  updatePercentageTransactionNested: planning.actions.updatePercentageTransactionNested,
  createLayout: control.actions.createLayout,
  fetchLayoutList: control.actions.fetchLayoutList,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  deleteTransactionFilter: planning.actions.deleteTransactionFilterByPlanning,
  deleteLayout: control.actions.deleteLayout,
  setHasComment: configuration.actions.setHasComment,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
  downloadBudgetTableByPhaseExcel: planning.actions.downloadBudgetTableByPhaseExcel,
  downloadBudgetTableByPhaseParquet: planning.actions.downloadBudgetTableByPhaseParquet,
}

export default connect(mapStateToProps, mapDispatchToProps)(Nested)
