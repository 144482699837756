import React, { useEffect, useState } from 'react'
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { Col, Dropdown, Menu, message, Row, Space, Spin } from 'antd'
import { CommentOutlined } from '@ant-design/icons'
import { Button, DownloadModal, ProgressBar, SelectType } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { GENERAL, PROJECTION, INTEGRATIONS } from '../../../../../../../../../core/constants'
import { ComparativeSwitch, ComparativeTable } from './components'
import {
  handleSave,
  handleSavePrimary,
} from 'modules/control/containers/ControlDetail/components/Evolution/utils'
import PivotTableUX from '../../../../../../../../../../components/PivotTable/PivotTableUX'
import TableRenderers from '../../../../../../../../../../components/PivotTable/Renderers/Comparative/TableRenderers'
import TableActions from '../TableActions/TableActions'
import reports from 'modules/reports'
import configuration from 'modules/configuration'
import control from 'modules/control'
import login from 'modules/login'
import moment from 'moment'
import _ from 'lodash'
import { handleDelete } from '../../utils'
import { generateMessageError } from 'modules/core/utils'

import PlaneTableUX from 'components/PivotTable/PlaneTableUX'
import PlaneTableRenderers from 'components/PivotTable/Renderers/Comparative/Plane'

const DEFAULT_FORMAT_CREATE = {
  'Suma (Decimales)': 'float',
  'Suma (Enteros)': 'int',
  'Suma (Miles)': 't',
  'Suma (Millones)': 'm',
}

const DEFAULT_FORMAT_SET = {
  float: 'Suma (Decimales)',
  int: 'Suma (Enteros)',
  t: 'Suma (Miles)',
  m: 'Suma (Millones)',
}

const getRowAuxName = (rows, allDimensionsList) => {
  return rows.map((row) => {
    if (row === 'Linea de reporte') {
      return 'Linea de reporte'
    } else {
      const dimension = allDimensionsList.find((el) => parseInt(row) === el.id)
      return dimension ? dimension.name_origin : ''
    }
  })
}

const getColsAuxName = (columns, allDimensionsList) => {
  return columns.map((col) => {
    if (col === 'Fecha') {
      return 'Fecha'
    } else if (col === 'Proyecciones') {
      return 'Proyecciones'
    } else {
      const dimension = allDimensionsList.find((el) => parseInt(col) === el.id)
      return dimension ? dimension.name_origin : ''
    }
  })
}

const getRowAuxId = (settings, allDimensionsList) => {
  return (
    !_.isEmpty(settings) &&
    settings.rows.map((row) => {
      if (row === 'Linea de reporte') {
        return 'Linea de reporte'
      } else {
        const id = allDimensionsList.find((el) => el.name_origin === row).id
        return id.toString()
      }
    })
  )
}

const getColsAuxId = (settings, allDimensionsList) => {
  return (
    !_.isEmpty(settings) &&
    settings.cols.map((col) => {
      if (col === 'Fecha') {
        return 'Fecha'
      } else if (col === 'Proyecciones') {
        return 'Proyecciones'
      } else {
        const id = allDimensionsList.find((el) => el.name_origin === col)
          ? allDimensionsList.find((el) => el.name_origin === col).id
          : null
        return id.toString()
      }
    })
  )
}

const Comparative = ({
  loading,
  selectedFilters,
  selectedDates,
  selectedPeriod,
  groupProjection,
  periodTableSettings,
  setPeriodTableSettings,
  referenceBase,
  setReferenceBase,
  referenceComparison,
  setReferenceComparison,
  keyComparisonTable,
  setKeyComparisonTable,
  onRefetchComparative,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  commentKey,
  dataComparative: { columns, rows, lineId },
  downloadComparativeXLS,
  dataComparativeByReport,
  createLayout,
  fetchAllDimensions,
  fetchLayoutList,
  layoutList,
  allDimensionsList,
  deleteLayout,
  hasComment,
  setHasComment,
  loggedUser,
  createComment,
  removeNewComment,
  isProgressLoading,
  showProgressBar,
  currencyData,
}) => {
  // Feature flag
  const enabledComparativeTable = useFeatureIsOn('feature-react-pivot-comparative')
  const enabledPlaneTable = useFeatureIsOn('feature-plane-table-custom')
  const formatBaseNumber = useFeatureValue('format-number-table', 'Suma (Decimales)')

  // Constant
  const DEFAULT_SELECT = {
    value: PROJECTION.REAL,
    id: null,
  }

  // Hooks
  const { t } = useTranslation()
  let { reportId, periodId } = useParams()

  // State
  const [periodTableColumns, setPeriodTableColumns] = useState([])
  // TODO: FOR REVAMP TABLE
  const [settings, setSettings] = useState({})
  const [localReferenceBase, setLocalReferenceBase] = useState(DEFAULT_SELECT)
  const [localReferenceComparison, setLocalReferenceComparison] = useState(referenceComparison)
  const [valueReferenceBase, setValueReferenceBase] = useState('REAL')
  const [showPopover, setShowPopover] = useState(false)
  const [favouriteName, setFavouriteName] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isLayoutLoading, setIsLayoutLoading] = useState(false)
  const [viewSelect, setViewSelect] = useState({})
  const [viewComment, setViewComment] = useState(false)
  const [showModal, setShowModal] = useState(false)

  // Values
  const { dataSource, linesOrder, linesTotal, linesRatio, linesRatioN } = dataComparativeByReport

  const paramsDownload = {
    period_id: periodId,
    filter_date: selectedDates,
    filter: selectedFilters,
    reference_base: enabledComparativeTable ? localReferenceBase : referenceBase,
    reference_comparative: referenceComparison,
    report_type: keyComparisonTable,
  }

  // Methods
  const handleComparisonSelection = (values) => {
    setReferenceComparison(values)
  }

  useEffect(() => {
    setPeriodTableColumns(columns)
  }, [columns])

  useEffect(() => {
    fetchAllDimensions()
  }, [fetchAllDimensions])

  useEffect(() => {
    setIsLayoutLoading(true)
    fetchLayoutList('reports', `${periodId}-${reportId}-coparations`).then(() => {
      setIsLayoutLoading(false)
      setSettings({})
    })
  }, [fetchLayoutList, periodId, reportId])

  useEffect(() => {
    const defaultLayout = layoutList.values.find((el) => el.primary)
    setViewSelect(defaultLayout)
  }, [layoutList.values])

  useEffect(() => {
    let value = 'REAL'
    if (localReferenceBase.value === PROJECTION.REAL) {
      value = 'REAL'
    } else if (localReferenceBase.value === PROJECTION.BUDGET) {
      const findValue = groupProjection.BUDGET.find(
        (it) => it.id.toString() === localReferenceBase.id,
      )
      if (findValue) {
        value = `Presupuesto - ${findValue.name}`
      }
    } else if (localReferenceBase.value === PROJECTION.FORECAST) {
      const findValue = groupProjection.FORECAST.find(
        (it) => it.id.toString() === localReferenceBase.id,
      )
      if (findValue) {
        value = `Forecast - ${findValue.name}`
      }
    }

    const defaultTableOptions = {
      linesOrder: linesOrder,
      linesTotal: linesTotal,
      linesRatio: linesRatio,
      linesRatioN: linesRatioN,
      referenceBase: valueReferenceBase,
    }

    setValueReferenceBase(value)
    if (!_.isEmpty(settings)) {
      setSettings((prevState) => ({
        ...prevState,
        tableOptions: { ...prevState.tableOptions, ...defaultTableOptions, referenceBase: value },
      }))
    }
    // eslint-disable-next-line
  }, [
    localReferenceBase,
    groupProjection.BUDGET,
    groupProjection.FORECAST,
    linesOrder,
    linesTotal,
    linesRatio,
    linesRatioN,
    valueReferenceBase,
  ])

  useEffect(() => {
    if (layoutList.values.length > 0 && !_.isEmpty(viewSelect) && allDimensionsList.length > 0) {
      setSettings((prevState) => {
        return {
          ...prevState,
          ...viewSelect.config,
          hiddenFromDragDrop: ['amount', 'price', 'quantity'],
          cols:
            getColsAuxName(viewSelect.config.columns, allDimensionsList).length > 0
              ? getColsAuxName(viewSelect.config.columns, allDimensionsList)
              : ['date'],
          rows: getRowAuxName(viewSelect.config.rows, allDimensionsList),
          aggregatorName: DEFAULT_FORMAT_SET[viewSelect.config.default_format],
          vals: [viewSelect.config.default_value],
        }
      })
    }
  }, [allDimensionsList, layoutList.values, viewSelect])

  const onClose = () => {
    setIsSaving(false)
    setShowPopover(false)
    setFavouriteName('')
  }

  const onCreate = () => {
    const data = {
      row_order: 0,
      column_order: 1,
      default_value: settings.vals[0],
      default_format: DEFAULT_FORMAT_CREATE[settings.aggregatorName],
      rows: getRowAuxId(settings, allDimensionsList),
      columns: getColsAuxId(settings, allDimensionsList),
    }

    return createLayout('reports', `${periodId}-${reportId}-coparations`, favouriteName, {
      config: data,
    })
  }

  const defaultData = !_.isEmpty(viewSelect) && {
    ...viewSelect.config,
    rows: getRowAuxName(viewSelect.config.rows, allDimensionsList),
    columns: getColsAuxName(viewSelect.config.columns, allDimensionsList),
    default_format: DEFAULT_FORMAT_SET[viewSelect.config.default_format],
  }
  const defaultSettings = !_.isEmpty(settings) && {
    rows: settings?.rows,
    columns: settings?.cols || ['Fecha', 'Proyecciones'],
    row_order: settings?.row_order,
    column_order: settings?.column_order,
    default_value: settings?.vals ? settings.vals[0] : '',
    default_format: settings?.aggregatorName,
  }

  const onSavePrimary = (data) => {
    const dataAux = layoutList.values.find((el) => el.name === data.name)
    return createLayout('reports', `${periodId}-${reportId}-coparations`, dataAux.name, {
      ...dataAux,
      primary: true,
    })
  }

  const getReferenceBase = () => {
    if (enabledComparativeTable) {
      return localReferenceBase
    }
    return referenceBase
  }

  const setReferenceBase_ = (value) => {
    setLocalReferenceBase(value)
    setReferenceBase(value)
  }

  const setReferenceComparison_ = (value) => {
    setLocalReferenceComparison(value)
  }

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${commentKey}-table-comparison`,
      module: commentKey,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }
  const renderTable = () => {
    if (enabledPlaneTable) {
      return (
        <Spin spinning={loading}>
          <Dropdown
            trigger={['contextMenu']}
            overlay={
              <Menu>
                {GENERAL.OPTION_COMMENT.map((el) => (
                  <Menu.Item
                    key={el.key}
                    icon={<CommentOutlined />}
                    onClick={() => {
                      setViewComment(true)
                      setHasComment({ type: 'table', data: !hasComment.table })
                    }}
                  >
                    {t(el.label)}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <div style={{ width: '100%' }}>
              <PlaneTableUX
                renderers={Object.assign({}, PlaneTableRenderers)}
                data={dataSource}
                onChange={(s) => setSettings(s)}
                vals={settings.default_value ? [settings.default_value] : ['amount']}
                cols={['Fecha', 'Proyecciones']}
                rows={['Linea de reporte']}
                aggregatorName={settings.default_format || formatBaseNumber}
                hiddenFromDragDrop={['amount', 'price', 'quantity']}
                hiddenFromAggregators={
                  dataSource[0] &&
                  dataSource[0].filter((el) => !['amount', 'price', 'quantity'].includes(el))
                }
                tableOptions={{
                  linesOrder: linesOrder,
                  linesTotal: linesTotal,
                  linesRatio: linesRatio,
                  linesRatioN: linesRatioN,
                  referenceBase: valueReferenceBase,
                }}
                tableFilter={{}}
                plotlyConfig={{
                  responsive: true,
                }}
                {...settings}
              />
            </div>
          </Dropdown>
        </Spin>
      )
    }

    if (enabledComparativeTable) {
      return (
        <Spin spinning={loading}>
          <Dropdown
            trigger={['contextMenu']}
            overlay={
              <Menu>
                {GENERAL.OPTION_COMMENT.map((el) => (
                  <Menu.Item
                    key={el.key}
                    icon={<CommentOutlined />}
                    onClick={() => {
                      setViewComment(true)
                      setHasComment({ type: 'table', data: !hasComment.table })
                    }}
                  >
                    {t(el.label)}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <div style={{ width: '100%' }}>
              <PivotTableUX
                renderers={Object.assign({}, TableRenderers)}
                data={dataSource}
                onChange={(s) => setSettings(s)}
                vals={settings.default_value ? [settings.default_value] : ['amount']}
                cols={['Fecha', 'Proyecciones']}
                rows={['Linea de reporte']}
                aggregatorName={settings.default_format || formatBaseNumber}
                hiddenFromDragDrop={['amount', 'price', 'quantity']}
                hiddenFromAggregators={
                  dataSource[0] &&
                  dataSource[0].filter((el) => !['amount', 'price', 'quantity'].includes(el))
                }
                tableOptions={{
                  linesOrder: linesOrder,
                  linesTotal: linesTotal,
                  linesRatio: linesRatio,
                  linesRatioN: linesRatioN,
                  referenceBase: valueReferenceBase,
                }}
                tableFilter={{}}
                plotlyConfig={{
                  responsive: true,
                }}
                {...settings}
              />
            </div>
          </Dropdown>
        </Spin>
      )
    }

    return (
      <ComparativeTable
        loading={loading}
        dataSource={rows}
        periodTableColumns={periodTableColumns}
        setPeriodTableColumns={setPeriodTableColumns}
        periodTableSettings={periodTableSettings}
        filters={selectedFilters}
        selectedDates={selectedDates}
        tableTypeKey={keyComparisonTable}
        referenceBase={referenceBase}
        referenceComparison={referenceComparison}
      />
    )
  }

  const renderCanSave = () => {
    const { data, ...rest } = settings
    return !_.isEmpty(rest) || (!_.isEmpty(rest) && !_.isEqual(defaultData, defaultSettings))
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {!enabledComparativeTable && (
          <ComparativeSwitch
            tableTypeKey={keyComparisonTable}
            onChange={(value) => setKeyComparisonTable(value.target.value)}
          />
        )}
      </Col>
      <Col span={24}>
        <Space direction="horizontal">
          <SelectType.EvolutionSelect
            loading={loading}
            groupProjection={groupProjection}
            activeOptionTable={getReferenceBase()}
            setActiveByPeriodTable={setReferenceBase_}
          />
          <SelectType.ComparisonSelect
            loading={loading}
            groupProjection={groupProjection}
            baseReference={getReferenceBase()}
            selectedOptions={localReferenceComparison}
            setSelectedOptions={setReferenceComparison_}
          />
          <Button.Primary
            onClick={() => handleComparisonSelection(localReferenceComparison)}
            title="ACTION_APPLY"
          />
        </Space>
      </Col>
      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
        {showProgressBar && <ProgressBar isLoading={isProgressLoading} />}
      </Col>
      <Col span={24}>
        <TableActions
          setPeriodTableSettings={setPeriodTableSettings}
          periodTableSettings={periodTableSettings}
          onClick={() =>
            downloadComparativeXLS(
              reportId,
              paramsDownload,
              t('REPORT_COMPARISON_FILE_NAME', {
                periodName: selectedPeriod.name,
                date: moment().format('lll'),
              }),
            )
          }
          onClickDownloadSpreadsheets={() => setShowModal(true)}
          onClickSaveFav={() => setShowPopover(true)}
          visibleFav={showPopover}
          onClickSave={() =>
            handleSave({
              setIsLoading: setIsSaving,
              createLayout: () => onCreate(),
              onRefetch: () => fetchLayoutList('reports', `${periodId}-${reportId}-coparations`),
              onRefresh: onRefetchComparative,
              onClose,
            })
          }
          onClickCancelSaveFav={onClose}
          setFavouriteName={setFavouriteName}
          favouriteName={favouriteName}
          isLoading={isSaving}
          hasFavourite={layoutList.values.length > 0}
          options={layoutList.values.map((el) => {
            return { name: el.name, id: el.name, primary: el.primary }
          })}
          canSave={renderCanSave()}
          viewSelect={viewSelect}
          setViewSelect={(val) => {
            const aux = layoutList.values.find((el) => el.name === val)
            setViewSelect(aux)
          }}
          alreadyExist={_.isEqual(defaultData, defaultSettings)}
          layoutLoading={isLayoutLoading}
          setPrimary={(data) =>
            handleSavePrimary({
              setIsLoading: setIsSaving,
              createLayout: () => onSavePrimary(data),
              onRefetch: () => fetchLayoutList('reports', `${periodId}-${reportId}-coparations`),
              onClose: () => setIsSaving(false),
            })
          }
          onClickDeleteFav={(name) =>
            handleDelete({
              setIsLoading: setIsSaving,
              deleteLayout: () =>
                deleteLayout('reports', `${periodId}-${reportId}-coparations`, name),
              onRefetch: () => fetchLayoutList('reports', `${periodId}-${reportId}-coparations`),
              onClose: () => setIsSaving(false),
            })
          }
          showComments={
            viewComment ||
            dataComments.filter((el) => el.key === `${commentKey}-table-comparison` && !el.resolved)
              .length > 0
          }
          hasComment={hasComment}
          setHasComment={setHasComment}
          setViewComment={setViewComment}
          userDetail={loggedUser}
          settings={settings}
          setDataComments={setDataComments}
          dataComments={dataComments.filter(
            (el) => (el.key === `${commentKey}-table-comparison` && !el.resolved) || !el.text,
          )}
          onRefetchComments={onRefetchComments}
          loadingComments={loadingComments}
          onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
            handleCreate({ text, usersIds, idComment, setIsNewThread })
          }
          removeNewComment={removeNewComment}
          currencyData={currencyData}
        />
      </Col>
      <Col span={24}>
        {/* TODO: NEW FOR REVAMP TABLE */}
        {renderTable()}
      </Col>
      <DownloadModal
        title={INTEGRATIONS.KEYS.SPREADSHEETS_KEY}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        defaultValue={loggedUser.folder_id}
        dataSpreadsheets={{
          type_load: 'report_comparative',
          params: {
            ...paramsDownload,
            report_id: reportId,
          },
        }}
      />
    </Row>
  )
}

const mapStateToProps = (state) => ({
  dataComparative: reports.selectors.getReportComparativeDetail(state),
  // TODO: NEW ENDPOINTS FOR REVAMP TABLE
  dataComparativeByReport: reports.selectors.getDataComparativeByReport(state),
  layoutList: control.selectors.getLayoutList(state),
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
  hasComment: configuration.selectors.getHasComment(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  downloadComparativeXLS: reports.actions.downloadComparativeXLS,
  createLayout: control.actions.createLayout,
  fetchLayoutList: control.actions.fetchLayoutList,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  deleteLayout: control.actions.deleteLayout,
  setHasComment: configuration.actions.setHasComment,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(Comparative)
