import { Col, Form, Row, Space } from 'antd'
import { AddAndDeleteFormItem, Typography } from 'modules/core/components'
import { ContentItem, Empty } from './components'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

const ContentDim = ({
  dataSelected,
  dataDimensions,
  reclassificationData,
  setReclassificationType,
  setReclassificationData,
  setDataDimensions,
  allDimensionsList,
  valuesSelected,
  setShowSelectModal,
  setValueIndex,
}) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()

  return (
    <Form form={form} initialValues={{ dimensions: [''], origin: [''], destination: [''] }}>
      <>
        {dataSelected.adjustment_type && dataSelected.adjustment_type === 'RECLASSIFICATION' && (
          <Space direction="vertical" style={{ width: '100%' }}>
            <div>
              <div className="header-table" style={{ marginTop: 10 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography.Body>
                    {t('CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_LABEL_ORIGIN')}
                  </Typography.Body>
                </div>
              </div>
              <Form.List name="origin">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => {
                        return (
                          <div key={`origin-${field.key}`}>
                            <Row gutter={[4, 4]} justify="end">
                              <Col span={24}>
                                {_.keys(reclassificationData.origin[field.name]).length > 0 ? (
                                  <Form.Item style={{ margin: 10 }}>
                                    <ContentItem
                                      fieldName={field.name}
                                      index={index}
                                      data={reclassificationData.origin}
                                      type={'origin'}
                                      dataSelected={dataSelected}
                                      setReclassificationData={setReclassificationData}
                                      setDataDimensions={setDataDimensions}
                                      allDimensionsList={allDimensionsList}
                                      valuesSelected={valuesSelected}
                                    />
                                  </Form.Item>
                                ) : (
                                  <Form.Item style={{ margin: 10 }}>
                                    <Empty
                                      onClick={() => {
                                        setValueIndex(index)
                                        setShowSelectModal(true)
                                        setReclassificationType('origin')
                                      }}
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                              <AddAndDeleteFormItem
                                fieldsLength={fields.length}
                                index={index}
                                addData={{
                                  onClick: () => {
                                    add()
                                    setValueIndex(index + 1)
                                    setReclassificationData((prevState) => {
                                      return { ...prevState, origin: [...prevState.origin, {}] }
                                    })
                                    setShowSelectModal(true)
                                  },
                                  buttonName: 'PLANNING_GLOBAL_BUDGET_ADD_NEW_COMBINATION_ACTION',
                                }}
                                deleteData={{
                                  onClick: () => {
                                    remove(field.name)
                                    reclassificationData.origin.splice(field.name, 1)
                                  },
                                  buttonName: 'PLANNING_GLOBAL_BUDGET_DELETE_COMBINATION_ACTION',
                                }}
                              />
                            </Row>
                          </div>
                        )
                      })}
                    </div>
                  )
                }}
              </Form.List>
            </div>
            <div>
              <div className="header-table">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography.Body>
                    {t('CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_LABEL_DESTINATION')}
                  </Typography.Body>
                </div>
              </div>
              <Form.List name="destination">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => {
                        return (
                          <div key={`destination-${field.key}`}>
                            <Row gutter={[4, 4]} justify="end">
                              <Col span={24}>
                                {_.keys(reclassificationData.destination[field.name]).length > 0 ? (
                                  <Form.Item style={{ margin: 10 }}>
                                    <ContentItem
                                      fieldName={field.name}
                                      index={index}
                                      data={reclassificationData.destination}
                                      type={'destination'}
                                      dataSelected={dataSelected}
                                      setReclassificationData={setReclassificationData}
                                      setDataDimensions={setDataDimensions}
                                      allDimensionsList={allDimensionsList}
                                      valuesSelected={valuesSelected}
                                    />
                                  </Form.Item>
                                ) : (
                                  <Form.Item style={{ margin: 10 }}>
                                    <Empty
                                      onClick={() => {
                                        setValueIndex(index)
                                        setShowSelectModal(true)
                                        setReclassificationType('destination')
                                      }}
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                              <AddAndDeleteFormItem
                                fieldsLength={fields.length}
                                index={index}
                                addData={{
                                  onClick: () => {
                                    add()
                                    setValueIndex(index + 1)
                                    setReclassificationData((prevState) => {
                                      return {
                                        ...prevState,
                                        destination: [...prevState.destination, {}],
                                      }
                                    })
                                    setShowSelectModal(true)
                                  },
                                  buttonName: 'PLANNING_GLOBAL_BUDGET_ADD_NEW_COMBINATION_ACTION',
                                }}
                                deleteData={{
                                  onClick: () => {
                                    remove(field.name)
                                    reclassificationData.destination.splice(field.name, 1)
                                  },
                                  buttonName: 'PLANNING_GLOBAL_BUDGET_DELETE_COMBINATION_ACTION',
                                }}
                              />
                            </Row>
                          </div>
                        )
                      })}
                    </div>
                  )
                }}
              </Form.List>
            </div>
          </Space>
        )}
        {dataSelected.adjustment_type && dataSelected.adjustment_type !== 'RECLASSIFICATION' && (
          <Form.List name="dimensions">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => {
                    return (
                      <div key={field.key}>
                        <Row gutter={[4, 4]} justify="end">
                          <Col span={24}>
                            {_.keys(dataDimensions[field.name]).length > 0 ? (
                              <Form.Item style={{ margin: 10 }}>
                                <ContentItem
                                  fieldName={field.name}
                                  index={index}
                                  data={dataDimensions}
                                  type={'other'}
                                  dataSelected={dataSelected}
                                  setReclassificationData={setReclassificationData}
                                  setDataDimensions={setDataDimensions}
                                  allDimensionsList={allDimensionsList}
                                  valuesSelected={valuesSelected}
                                />
                              </Form.Item>
                            ) : (
                              <Form.Item style={{ margin: 10 }}>
                                <Empty
                                  onClick={() => {
                                    setValueIndex(index)
                                    setShowSelectModal(true)
                                  }}
                                />
                              </Form.Item>
                            )}
                          </Col>
                          <AddAndDeleteFormItem
                            fieldsLength={fields.length}
                            index={index}
                            addData={{
                              onClick: () => {
                                add()
                                setValueIndex(index + 1)
                                setDataDimensions((prevState) => [...prevState, {}])
                                setShowSelectModal(true)
                              },
                              buttonName: 'PLANNING_GLOBAL_BUDGET_ADD_NEW_COMBINATION_ACTION',
                            }}
                            deleteData={{
                              onClick: () => {
                                remove(field.name)
                                dataDimensions.splice(field.name, 1)
                              },
                              buttonName: 'PLANNING_GLOBAL_BUDGET_DELETE_COMBINATION_ACTION',
                            }}
                          />
                        </Row>
                      </div>
                    )
                  })}
                </div>
              )
            }}
          </Form.List>
        )}
      </>
    </Form>
  )
}

export default ContentDim
