import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const functionSearch = (values, searchValue) => {
  const resultado = []
  values.forEach((value) => {
    if (value.name.toLowerCase().includes(searchValue.toLowerCase())) {
      resultado.push(value)
    }
  })
  return resultado
}

export const handleDeleteSelection = ({
  setIsLoading,
  deleteMassiveReport,
  onRefetch,
  onClose,
}) => {
  setIsLoading(true)
  deleteMassiveReport()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      onClose()
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error),
        duration: 0,
      })
    })
}
