import React, { useState } from 'react'
import { connect } from 'react-redux'
import AdjustmentSkeleton from '../AdjustmentSkeleton'

const FRONT_URL = process.env.REACT_APP_FRONT_URL || 'https://next.stg.getplika.com'

const AdjustmentModule = () => {
  // Revamp configuration
  const [loadingIframe, setLoadingIframe] = useState(true)

  const handleLoad = () => {
    console.log('Iframe loaded')
    setLoadingIframe(false)
  }

  return (
    <div style={{ width: '100%', height: window.innerHeight - 170 }}>
      {loadingIframe && <AdjustmentSkeleton />}
      <iframe
        id="DashboardhHome"
        title="DashboardhHome"
        src={`${FRONT_URL}/follow-up/adjustment/`}
        width="100%"
        height="100%"
        style={{
          border: 'none',
          padding: '0 0',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
        onLoad={handleLoad}
      ></iframe>
    </div>
  )
}

export default connect(null, null)(AdjustmentModule)
