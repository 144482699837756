import _, { isEmpty } from 'lodash'
import React from 'react'
import { aggregatorsTablePxQ } from '../../../Aggregators'
import { flatKey, formatter, PivotData, usFmt } from '../../../Utilities'

class TablePxQ extends React.PureComponent {
  constructor(props) {
    super(props)

    // We need state to record which entries are collapsed and which aren't.
    // This is an object with flat-keys indicating if the corresponding rows
    // should be collapsed.
    this.state = { collapsedRows: {}, collapsedCols: {} }
  }

  getBasePivotSettings() {
    const props = this.props
    const colAttrs = props.cols
    const rowAttrs = props.rows

    const tableOptions = Object.assign(
      {
        rowTotals: true,
        colTotals: true,
      },
      props.tableOptions,
    )

    const rowTotals = tableOptions.rowTotals || colAttrs.length === 0
    const colTotals = tableOptions.colTotals || rowAttrs.length === 0

    const subtotalOptions = Object.assign(
      {
        arrowCollapsed: '⨁',
        arrowExpanded: 'ⴱ',
      },
      props.subtotalOptions,
    )

    const colSubtotalDisplay = Object.assign(
      {
        displayOnTop: false,
        enabled: rowTotals,
        hideOnExpand: false,
      },
      subtotalOptions.colSubtotalDisplay,
    )

    const rowSubtotalDisplay = Object.assign(
      {
        displayOnTop: true,
        enabled: colTotals,
        hideOnExpand: false,
      },
      subtotalOptions.rowSubtotalDisplay,
    )

    const pivotData = new PivotData(
      Object.assign({}, props, {
        aggregators: aggregatorsTablePxQ,
        vals: ['price', 'quantity', 'amount'],
      }),
      {
        rowEnabled: rowSubtotalDisplay.enabled,
        colEnabled: colSubtotalDisplay.enabled,
        rowPartialOnTop: rowSubtotalDisplay.displayOnTop,
        colPartialOnTop: colSubtotalDisplay.displayOnTop,
      },
    )

    const rowKeys = pivotData.getRowKeys()
    const colKeys = pivotData.getColKeys()
    // Also pre-calculate all the callbacks for cells, etc... This is nice to have to
    // avoid re-calculations of the call-backs on cell expansions, etc...
    const cellCallbacks = {}
    const rowTotalCallbacks = {}
    const colTotalCallbacks = {}
    let grandTotalCallback = null

    // Custom lines
    const realMonths = !_.isEmpty(tableOptions?.realMonths) ? tableOptions.realMonths : []
    const visibleTotal = tableOptions?.visibleTotal != null ? tableOptions?.visibleTotal : true

    return Object.assign({
      pivotData,
      colAttrs,
      rowAttrs,
      colKeys,
      rowKeys,
      rowTotals,
      colTotals,
      arrowCollapsed: subtotalOptions.arrowCollapsed,
      arrowExpanded: subtotalOptions.arrowExpanded,
      colSubtotalDisplay,
      rowSubtotalDisplay,
      cellCallbacks,
      rowTotalCallbacks,
      colTotalCallbacks,
      grandTotalCallback,
      realMonths,
      visibleTotal,
    })
  }

  clickHandler(pivotData, rowValues, colValues, attr) {
    const colAttrs = this.props.cols
    const rowAttrs = this.props.rows
    const agg = pivotData.getAggregator(rowValues, colValues)
    const value = agg.multivalued()[attr]

    const filters = {}
    const colLimit = Math.min(colAttrs.length, colValues.length)
    for (let i = 0; i < colLimit; i++) {
      const attr = colAttrs[i]
      if (colValues[i] !== null) {
        filters[attr] = colValues[i]
      }
    }
    const rowLimit = Math.min(rowAttrs.length, rowValues.length)
    for (let i = 0; i < rowLimit; i++) {
      const attr = rowAttrs[i]
      if (rowValues[i] !== null) {
        filters[attr] = rowValues[i]
      }
    }
    return (e) => this.props.tableOptions.clickCallback(e, value, filters, pivotData, attr)
  }

  collapseAttr(rowOrCol, attrIdx, allKeys) {
    return () => {
      // Collapse an entire attribute.

      const keyLen = attrIdx + 1
      const collapsed = allKeys.filter((k) => k.length === keyLen).map(flatKey)

      const updates = {}
      collapsed.forEach((k) => {
        updates[k] = true
      })

      if (rowOrCol) {
        this.setState((state) => ({
          collapsedRows: Object.assign({}, state.collapsedRows, updates),
        }))
      } else {
        this.setState((state) => ({
          collapsedCols: Object.assign({}, state.collapsedCols, updates),
        }))
      }
    }
  }

  expandAttr(rowOrCol, attrIdx, allKeys) {
    return () => {
      // Expand an entire attribute. This implicitly implies expanding all of the
      // parents as well. It's a bit inefficient but ah well...

      const updates = {}
      allKeys.forEach((k) => {
        for (let i = 0; i <= attrIdx; i++) {
          updates[flatKey(k.slice(0, i + 1))] = false
        }
      })

      if (rowOrCol) {
        this.setState((state) => ({
          collapsedRows: Object.assign({}, state.collapsedRows, updates),
        }))
      } else {
        this.setState((state) => ({
          collapsedCols: Object.assign({}, state.collapsedCols, updates),
        }))
      }
    }
  }

  toggleRowKey(flatRowKey) {
    return () => {
      this.setState((state) => ({
        collapsedRows: Object.assign({}, state.collapsedRows, {
          [flatRowKey]: !state.collapsedRows[flatRowKey],
        }),
      }))
    }
  }

  toggleColKey(flatColKey) {
    return () => {
      this.setState((state) => ({
        collapsedCols: Object.assign({}, state.collapsedCols, {
          [flatColKey]: !state.collapsedCols[flatColKey],
        }),
      }))
    }
  }

  calcAttrSpans(attrArr, numAttrs) {
    // Given an array of attribute values (i.e. each element is another array with
    // the value at every level), compute the spans for every attribute value at
    // every level. The return value is a nested array of the same shape. It has
    // -1's for repeated values and the span number otherwise.

    const spans = []
    // Index of the last new value
    const li = Array(numAttrs).map(() => 0)
    let lv = Array(numAttrs).map(() => null)
    for (let i = 0; i < attrArr.length; i++) {
      // Keep increasing span values as long as the last keys are the same. For
      // the rest, record spans of 1. Update the indices too.
      const cv = attrArr[i]
      const ent = []
      let depth = 0
      const limit = Math.min(lv.length, cv.length)
      while (depth < limit && lv[depth] === cv[depth]) {
        ent.push(-1)
        spans[li[depth]][depth]++
        depth++
      }
      while (depth < cv.length) {
        li[depth] = i
        ent.push(1)
        depth++
      }
      spans.push(ent)
      lv = cv
    }
    return spans
  }

  renderColHeaderRow(attrName, attrIdx, pivotSettings) {
    const {
      rowAttrs,
      colAttrs,
      colKeys,
      visibleColKeys,
      colAttrSpans,
      rowTotals,
      arrowExpanded,
      arrowCollapsed,
      colSubtotalDisplay,
      maxColVisible,
      realMonths,
    } = pivotSettings

    const needToggle = colSubtotalDisplay.enabled && attrIdx !== colAttrs.length - 1
    let clickHandle = null
    let subArrow = null
    if (needToggle) {
      clickHandle =
        attrIdx + 1 < maxColVisible
          ? this.collapseAttr(false, attrIdx, colKeys)
          : this.expandAttr(false, attrIdx, colKeys)
      subArrow = (attrIdx + 1 < maxColVisible ? arrowExpanded : arrowCollapsed) + ' '
    }
    const attrNameCell = (
      <th
        key="label"
        className="pvtAxisLabel width-min-250"
        onClick={clickHandle}
        style={{ textAlign: 'right' }}
      >
        {subArrow}
        {attrName}
      </th>
    )

    const attrValueCells = []
    const rowIncrSpan = rowAttrs.length !== 0 ? 1 : 0
    // Iterate through columns. Jump over duplicate values.
    let i = 0
    while (i < visibleColKeys.length) {
      const colKey = visibleColKeys[i]
      const colSpan = attrIdx < colKey.length ? colAttrSpans[i][attrIdx] : 1
      if (attrIdx < colKey.length) {
        const flatColKey = flatKey(colKey.slice(0, attrIdx + 1))
        const onClick = needToggle ? this.toggleColKey(flatColKey) : null

        const hasPxQ = attrIdx + 1 === colKey.length
        let hasRealMonth = false
        if (realMonths.length > 0) {
          hasRealMonth = this.checkDateInReal(colKey.slice(0, attrIdx + 1), realMonths)
        }

        let currentColSpan = colSpan + 1
        if (hasPxQ) {
          currentColSpan = colSpan + 2
        } else if (!hasPxQ) {
          currentColSpan = colSpan * 3
        }

        attrValueCells.push(
          <th
            className="pvtColLabel"
            key={'colKey-' + flatColKey}
            colSpan={currentColSpan}
            onClick={onClick}
            style={{ ...(hasRealMonth ? { backgroundColor: '#eaeaea' } : {}) }}
          >
            {needToggle
              ? (this.state.collapsedCols[flatColKey] ? arrowCollapsed : arrowExpanded) + ' '
              : null}
            {colKey[attrIdx]}
          </th>,
        )
      } else if (attrIdx === colKey.length) {
        const hasRealMonth = this.checkDateInReal(colKey, realMonths)
        attrValueCells.push(
          <th
            className="pvtColLabel"
            key={'colKeyBuffer-' + flatKey(colKey)}
            colSpan={colSpan + 1}
            style={{ ...(hasRealMonth ? { backgroundColor: '#eaeaea' } : {}) }}
          >
            Subtotal
          </th>,
        )
      }
      // The next colSpan columns will have the same value anyway...
      i = i + colSpan
    }

    const totalCell =
      attrIdx === 0 && rowTotals ? (
        <th
          key="total"
          className="pvtTotalLabel"
          colSpan={3}
          rowSpan={colAttrs.length + Math.min(rowAttrs.length, 1) - 1}
        >
          Total
        </th>
      ) : null

    const cells = [attrNameCell, ...attrValueCells, totalCell]
    return <tr key={`colAttr-${attrIdx}`}>{cells}</tr>
  }

  renderColFixedHeaderRow(attrName, attrIdx, pivotSettings) {
    const { visibleColKeys, colAttrSpans, rowTotals, realMonths } = pivotSettings

    const attrNameCell = <th key="label" className="pvtAxisLabel width-min-250"></th>

    const attrValueCells = []
    // Iterate through columns. Jump over duplicate values.
    let i = 0
    while (i < visibleColKeys.length) {
      const colKey = visibleColKeys[i]
      const colSpan = attrIdx < colKey.length ? colAttrSpans[i][attrIdx] : 1
      const flatColKey = flatKey(colKey.slice(0, attrIdx + 1))

      let hasRealMonth = false
      if (realMonths.length > 0) {
        hasRealMonth = this.checkDateInReal(colKey.slice(0, attrIdx + 1), realMonths)
      }

      attrValueCells.push(
        <th
          className="pvtColLabel"
          key={'colKey-p' + flatColKey}
          rowSpan={2}
          style={{ ...(hasRealMonth ? { backgroundColor: '#eaeaea' } : {}) }}
        >
          P
        </th>,
      )
      attrValueCells.push(
        <th
          className="pvtColLabel"
          key={'colKey-q' + flatColKey}
          rowSpan={2}
          style={{ ...(hasRealMonth ? { backgroundColor: '#eaeaea' } : {}) }}
        >
          Q
        </th>,
      )
      attrValueCells.push(
        <th
          className="pvtColLabel"
          key={'colKey-i' + flatColKey}
          rowSpan={2}
          style={{ ...(hasRealMonth ? { backgroundColor: '#eaeaea' } : {}) }}
        >
          Importe
        </th>,
      )
      // The next colSpan columns will have the same value anyway...
      i = i + colSpan
    }

    const totalCell = []
    if (rowTotals) {
      totalCell.push(
        <th key="total" className="pvtTotalLabel" rowSpan={2}>
          P
        </th>,
      )
      totalCell.push(
        <th key="total" className="pvtTotalLabel" rowSpan={2}>
          Q
        </th>,
      )
      totalCell.push(
        <th key="total" className="pvtTotalLabel" rowSpan={2}>
          Importe
        </th>,
      )
    }

    const cells = [attrNameCell, ...attrValueCells, ...totalCell]
    return <tr key={`colAttr-pxq-${attrIdx}`}>{cells}</tr>
  }

  renderRowHeaderRow(pivotSettings) {
    // Render just the attribute names of the rows (the actual attribute values
    // will show up in the individual rows).

    const { rowAttrs, rowKeys, arrowCollapsed, arrowExpanded, rowSubtotalDisplay, maxRowVisible } =
      pivotSettings

    const rowLabel = rowAttrs.map((it) => it).join(' / ')
    const i = 0
    const needLabelToggle = rowSubtotalDisplay.enabled && i !== rowAttrs.length - 1
    let clickHandle = null
    let subArrow = null
    if (needLabelToggle) {
      clickHandle =
        i + 1 < maxRowVisible
          ? this.collapseAttr(true, i, rowKeys)
          : this.expandAttr(true, i, rowKeys)
      subArrow = (i + 1 < maxRowVisible ? arrowExpanded : arrowCollapsed) + ' '
    }

    return (
      <tr key="rowHdr">
        <th className="pvtAxisLabel width-min-250" key={`rowAttr-${i}`} onClick={clickHandle}>
          {subArrow} {rowLabel}
        </th>
      </tr>
    )
  }

  checkDateInReal(colKey, realMonths) {
    const pattern = /^\d{4}-\d{2}$/
    const baseSet = new Set(realMonths)
    const foundItem = _.find(colKey, (it) => pattern.test(it) && baseSet.has(it))
    return !!foundItem
  }

  renderTableRow(rowKey, rowIdx, pivotSettings) {
    // Render a single row in the pivot table.

    const {
      rowAttrs,
      rowAttrSpans,
      visibleColKeys,
      pivotData,
      rowTotals,
      arrowExpanded,
      arrowCollapsed,
      realMonths,
    } = pivotSettings

    const format = this.getFormatter(pivotData)
    const flatRowKey = flatKey(rowKey)
    const attrValueCells = rowKey.map((r, i) => {
      const rowSpan = rowAttrSpans[rowIdx][i]
      if (rowSpan > 0) {
        const flatRowKey = flatKey(rowKey.slice(0, i + 1))
        const needRowToggle = i !== rowAttrs.length - 1
        const onClick = needRowToggle ? this.toggleRowKey(flatRowKey) : null

        return (
          <th
            key={`rowKeyLabel-${i}`}
            className={`pvtRowLabel pl-${i} width-min-250`}
            rowSpan={1}
            onClick={onClick}
          >
            {needRowToggle
              ? (this.state.collapsedRows[flatRowKey] ? arrowCollapsed : arrowExpanded) + ' '
              : null}
            {r}
          </th>
        )
      }
      return null
    })
    const valueCells = []

    visibleColKeys.forEach((colKey) => {
      const flatColKey = flatKey(colKey)
      const values = this.getAggregator(pivotData, rowKey, colKey)

      let hasRealMonth = false
      if (realMonths.length > 0) {
        hasRealMonth = this.checkDateInReal(colKey, realMonths)
      }

      ;['price', 'quantity', 'amount'].forEach((it) => {
        const aggValue = values[it]
        let handleClick = this.clickHandler(pivotData, rowKey, colKey, it)
        if (hasRealMonth) {
          handleClick = () => {}
        }

        valueCells.push(
          <td
            className="pvtVal"
            key={'pvtVal-' + flatRowKey + '-' + flatColKey + '-' + it}
            onClick={handleClick}
            style={{ ...(hasRealMonth ? { backgroundColor: '#eaeaea' } : {}) }}
          >
            {format(aggValue)}
          </td>,
        )
      })
    })

    let totalCell = []
    if (rowTotals) {
      const values = this.getAggregator(pivotData, rowKey, [])

      ;['price', 'quantity', 'amount'].forEach((it) => {
        const aggValue = values[it]

        totalCell.push(
          <td key={'total-' + flatRowKey + '-' + it} className="pvtTotal">
            {format(aggValue)}
          </td>,
        )
      })
    }

    const rowCells = [...attrValueCells, ...valueCells, totalCell]

    return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
  }

  renderTotalsRow(pivotSettings) {
    // Render the final totals rows that has the totals for all the columns.

    const { visibleColKeys, rowTotals, pivotData, realMonths } = pivotSettings
    const format = this.getFormatter(pivotData)

    const totalLabelCell = (
      <th key="label" className="pvtTotalLabel width-min-250">
        TOTAL
      </th>
    )
    const totalValueCells = []
    visibleColKeys.forEach((colKey) => {
      const flatColKey = flatKey(colKey)
      const values = this.getAggregator(pivotData, [], colKey)

      let hasRealMonth = false
      if (realMonths.length > 0) {
        hasRealMonth = this.checkDateInReal(colKey, realMonths)
      }

      ;['price', 'quantity', 'amount'].forEach((it) => {
        const aggValue = values[it]

        totalValueCells.push(
          <td
            className="pvtTotal"
            key={'total-' + flatColKey + '-' + it}
            style={{ ...(hasRealMonth ? { backgroundColor: '#eaeaea' } : {}) }}
          >
            {format(aggValue)}
          </td>,
        )
      })
    })

    let grandTotalCell = []
    if (rowTotals) {
      const values = this.getAggregator(pivotData, [], [])

      ;['price', 'quantity', 'amount'].forEach((it) => {
        const aggValue = values[it]

        grandTotalCell.push(
          <td key={'total-' + it} className="pvtGrandTotal">
            {format(aggValue)}
          </td>,
        )
      })
    }

    const totalCells = [totalLabelCell, ...totalValueCells, grandTotalCell]
    return <tr key="total">{totalCells}</tr>
  }

  visibleKeys(keys, collapsed, numAttrs, subtotalDisplay) {
    return keys.filter(
      (key) =>
        // Is the key hidden by one of its parents?
        !key.some((k, j) => collapsed[flatKey(key.slice(0, j))]) &&
        // Leaf key.
        (key.length === numAttrs ||
          // Children hidden. Must show total.
          flatKey(key) in collapsed ||
          // Don't hide totals.
          !subtotalDisplay.hideOnExpand),
    )
  }

  getAggregator(pivotData, rowKey, colKey) {
    try {
      const agg = pivotData.getAggregator(rowKey, colKey)
      const aggValue = agg.multivalued()

      const amount = aggValue['amount'] ?? 0.0
      const quantity = aggValue['quantity'] ?? 0.0
      const price = quantity !== 0 ? amount / quantity : 0

      return {
        amount: amount,
        quantity: quantity,
        price: price,
      }
    } catch (e) {
      return { amount: 0, price: 0, quantity: 0 }
    }
  }

  getFormatter(pivotData) {
    let formatterFunction = usFmt
    if (formatter.hasOwnProperty(pivotData.props.aggregatorName)) {
      formatterFunction = formatter[pivotData.props.aggregatorName]
    }
    return formatterFunction
  }

  render() {
    if (this.cachedProps !== this.props) {
      this.cachedProps = this.props
      this.cachedBasePivotSettings = this.getBasePivotSettings()
    }

    const {
      colAttrs,
      rowAttrs,
      rowKeys,
      colKeys,
      colTotals,
      rowSubtotalDisplay,
      colSubtotalDisplay,
      visibleTotal,
    } = this.cachedBasePivotSettings

    // Need to account for exclusions to compute the effective row
    // and column keys.
    const visibleRowKeys = this.visibleKeys(
      rowKeys,
      this.state.collapsedRows,
      rowAttrs.length,
      rowSubtotalDisplay,
    )
    const visibleColKeys = this.visibleKeys(
      colKeys,
      this.state.collapsedCols,
      colAttrs.length,
      colSubtotalDisplay,
    )
    const pivotSettings = Object.assign(
      {
        visibleRowKeys,
        maxRowVisible: Math.max(...visibleRowKeys.map((k) => k.length)),
        visibleColKeys,
        maxColVisible: Math.max(...visibleColKeys.map((k) => k.length)),
        rowAttrSpans: this.calcAttrSpans(visibleRowKeys, rowAttrs.length),
        colAttrSpans: this.calcAttrSpans(visibleColKeys, colAttrs.length),
      },
      this.cachedBasePivotSettings,
    )
    const indexLastColAttrs = colAttrs.length > 0 ? colAttrs.length - 1 : 0

    return (
      <table className="pvtTable">
        <thead>
          {colAttrs.map((it, index) => this.renderColHeaderRow(it, index, pivotSettings))}
          {this.renderColFixedHeaderRow('PxQ', indexLastColAttrs, pivotSettings)}
          {rowAttrs.length !== 0 && this.renderRowHeaderRow(pivotSettings)}
        </thead>
        <tbody>
          {visibleRowKeys.map((r, i) => this.renderTableRow(r, i, pivotSettings))}
          {visibleTotal && colTotals && this.renderTotalsRow(pivotSettings)}
        </tbody>
      </table>
    )
  }
}

TablePxQ.defaultProps = {
  aggregators: aggregatorsTablePxQ,
  cols: [],
  rows: [],
  vals: [],
  aggregatorName: 'Suma (Decimales)',
  sorters: {},
  valueFilter: {},
  rowOrder: 'key_a_to_z',
  colOrder: 'key_a_to_z',
  derivedAttributes: {},
}
TablePxQ.propTypes = PivotData.propTypes
TablePxQ.defaultProps.tableOptions = {}

export default TablePxQ
