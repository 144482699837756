import _ from 'lodash'
import React from 'react'
import { aggregatorsTablePxQ } from '../../../Aggregators'
import { flatKey, formatter, normalizeName, PivotData, usFmt, usFmtPct } from '../../../Utilities'

class TablePQI extends React.PureComponent {
  constructor(props) {
    super(props)

    // We need state to record which entries are collapsed and which aren't.
    // This is an object with flat-keys indicating if the corresponding rows
    // should be collapsed.
    this.state = { collapsedRows: {}, collapsedCols: {} }
  }

  getBasePivotSettings() {
    const props = this.props
    const colAttrs = props.cols
    const rowAttrs = props.rows

    const tableOptions = Object.assign(
      {
        rowTotals: true,
        colTotals: true,
      },
      props.tableOptions,
    )

    const rowTotals = tableOptions.rowTotals || colAttrs.length === 0
    const colTotals = tableOptions.colTotals || rowAttrs.length === 0

    const subtotalOptions = Object.assign(
      {
        arrowCollapsed: '⨁',
        arrowExpanded: 'ⴱ',
      },
      props.subtotalOptions,
    )

    const colSubtotalDisplay = Object.assign(
      {
        displayOnTop: false,
        enabled: rowTotals,
        hideOnExpand: false,
      },
      subtotalOptions.colSubtotalDisplay,
    )

    const rowSubtotalDisplay = Object.assign(
      {
        displayOnTop: true,
        enabled: colTotals,
        hideOnExpand: false,
      },
      subtotalOptions.rowSubtotalDisplay,
    )

    const pivotData = new PivotData(
      Object.assign({}, props, {
        aggregators: aggregatorsTablePxQ,
        vals: ['price', 'quantity', 'amount'],
      }),
      {
        rowEnabled: rowSubtotalDisplay.enabled,
        colEnabled: colSubtotalDisplay.enabled,
        rowPartialOnTop: rowSubtotalDisplay.displayOnTop,
        colPartialOnTop: colSubtotalDisplay.displayOnTop,
      },
    )

    const rowKeys = pivotData.getRowKeys()
    const colKeys = pivotData.getColKeys()
    // Also pre-calculate all the callbacks for cells, etc... This is nice to have to
    // avoid re-calculations of the call-backs on cell expansions, etc...
    const cellCallbacks = {}
    const rowTotalCallbacks = {}
    const colTotalCallbacks = {}
    let grandTotalCallback = null

    // Custom lines
    const linesOrder = !_.isEmpty(tableOptions?.linesOrder) ? tableOptions.linesOrder : []
    const linesTotal = !_.isEmpty(tableOptions?.linesTotal) ? tableOptions.linesTotal : []
    const linesRatio = !_.isEmpty(tableOptions?.linesRatio) ? tableOptions.linesRatio : []
    const linesRatioN = !_.isEmpty(tableOptions?.linesRatioN) ? tableOptions.linesRatioN : []
    const linesFinancial = !_.isEmpty(tableOptions?.linesFinancial)
      ? tableOptions.linesFinancial
      : null
    const lineVertical = !_.isEmpty(tableOptions?.lineVertical) ? tableOptions.lineVertical : null

    return Object.assign({
      pivotData,
      colAttrs,
      rowAttrs,
      colKeys,
      rowKeys,
      rowTotals,
      colTotals,
      arrowCollapsed: subtotalOptions.arrowCollapsed,
      arrowExpanded: subtotalOptions.arrowExpanded,
      colSubtotalDisplay,
      rowSubtotalDisplay,
      cellCallbacks,
      rowTotalCallbacks,
      colTotalCallbacks,
      grandTotalCallback,
      linesOrder,
      linesTotal,
      linesRatio,
      linesRatioN,
      linesFinancial,
      lineVertical,
    })
  }

  collapseAttr(rowOrCol, attrIdx, allKeys) {
    return () => {
      // Collapse an entire attribute.

      const keyLen = attrIdx + 1
      const collapsed = allKeys.filter((k) => k.length === keyLen).map(flatKey)

      const updates = {}
      collapsed.forEach((k) => {
        updates[k] = true
      })

      if (rowOrCol) {
        this.setState((state) => ({
          collapsedRows: Object.assign({}, state.collapsedRows, updates),
        }))
      } else {
        this.setState((state) => ({
          collapsedCols: Object.assign({}, state.collapsedCols, updates),
        }))
      }
    }
  }

  expandAttr(rowOrCol, attrIdx, allKeys) {
    return () => {
      // Expand an entire attribute. This implicitly implies expanding all of the
      // parents as well. It's a bit inefficient but ah well...

      const updates = {}
      allKeys.forEach((k) => {
        for (let i = 0; i <= attrIdx; i++) {
          updates[flatKey(k.slice(0, i + 1))] = false
        }
      })

      if (rowOrCol) {
        this.setState((state) => ({
          collapsedRows: Object.assign({}, state.collapsedRows, updates),
        }))
      } else {
        this.setState((state) => ({
          collapsedCols: Object.assign({}, state.collapsedCols, updates),
        }))
      }
    }
  }

  toggleRowKey(flatRowKey) {
    return () => {
      this.setState((state) => ({
        collapsedRows: Object.assign({}, state.collapsedRows, {
          [flatRowKey]: !state.collapsedRows[flatRowKey],
        }),
      }))
    }
  }

  toggleColKey(flatColKey) {
    return () => {
      this.setState((state) => ({
        collapsedCols: Object.assign({}, state.collapsedCols, {
          [flatColKey]: !state.collapsedCols[flatColKey],
        }),
      }))
    }
  }

  calcAttrSpans(attrArr, numAttrs) {
    // Given an array of attribute values (i.e. each element is another array with
    // the value at every level), compute the spans for every attribute value at
    // every level. The return value is a nested array of the same shape. It has
    // -1's for repeated values and the span number otherwise.

    const spans = []
    // Index of the last new value
    const li = Array(numAttrs).map(() => 0)
    let lv = Array(numAttrs).map(() => null)
    for (let i = 0; i < attrArr.length; i++) {
      // Keep increasing span values as long as the last keys are the same. For
      // the rest, record spans of 1. Update the indices too.
      const cv = attrArr[i]
      const ent = []
      let depth = 0
      const limit = Math.min(lv.length, cv.length)
      while (depth < limit && lv[depth] === cv[depth]) {
        ent.push(-1)
        spans[li[depth]][depth]++
        depth++
      }
      while (depth < cv.length) {
        li[depth] = i
        ent.push(1)
        depth++
      }
      spans.push(ent)
      lv = cv
    }
    return spans
  }

  renderColHeaderRow(attrName, attrIdx, pivotSettings) {
    const {
      rowAttrs,
      colAttrs,
      colKeys,
      visibleColKeys,
      colAttrSpans,
      rowTotals,
      arrowExpanded,
      arrowCollapsed,
      colSubtotalDisplay,
      maxColVisible,
    } = pivotSettings

    const needToggle = colSubtotalDisplay.enabled && attrIdx !== colAttrs.length - 1
    let clickHandle = null
    let subArrow = null
    if (needToggle) {
      clickHandle =
        attrIdx + 1 < maxColVisible
          ? this.collapseAttr(false, attrIdx, colKeys)
          : this.expandAttr(false, attrIdx, colKeys)
      subArrow = (attrIdx + 1 < maxColVisible ? arrowExpanded : arrowCollapsed) + ' '
    }
    const attrNameCell = (
      <th
        key="label"
        className="pvtAxisLabel width-min-250"
        onClick={clickHandle}
        style={{ textAlign: 'right' }}
      >
        {subArrow}
        {attrName}
      </th>
    )

    const attrValueCells = []
    // Iterate through columns. Jump over duplicate values.
    let i = 0
    while (i < visibleColKeys.length) {
      const colKey = visibleColKeys[i]
      const colSpan = attrIdx < colKey.length ? colAttrSpans[i][attrIdx] : 1
      if (attrIdx < colKey.length) {
        const flatColKey = flatKey(colKey.slice(0, attrIdx + 1))
        const onClick = needToggle ? this.toggleColKey(flatColKey) : null

        const hasPxQ = attrIdx + 1 === colKey.length

        let currentColSpan = colSpan + 1
        if (hasPxQ) {
          currentColSpan = colSpan + 2
        } else if (!hasPxQ) {
          currentColSpan = colSpan * 3
        }

        attrValueCells.push(
          <th
            className="pvtColLabel"
            key={'colKey-' + flatColKey}
            colSpan={currentColSpan}
            onClick={onClick}
          >
            {needToggle
              ? (this.state.collapsedCols[flatColKey] ? arrowCollapsed : arrowExpanded) + ' '
              : null}
            {colKey[attrIdx]}
          </th>,
        )
      } else if (attrIdx === colKey.length) {
        attrValueCells.push(
          <th className="pvtColLabel" key={'colKeyBuffer-' + flatKey(colKey)} colSpan={colSpan + 1}>
            Subtotal
          </th>,
        )
      }
      // The next colSpan columns will have the same value anyway...
      i = i + colSpan
    }

    const totalCell =
      attrIdx === 0 && rowTotals ? (
        <th
          key="total"
          className="pvtTotalLabel"
          colSpan={2}
          rowSpan={colAttrs.length + Math.min(rowAttrs.length, 1) - 1}
        >
          Total
        </th>
      ) : null

    const cells = [attrNameCell, ...attrValueCells, totalCell]
    return <tr key={`colAttr-${attrIdx}`}>{cells}</tr>
  }

  renderColFixedHeaderRow(attrName, attrIdx, pivotSettings) {
    const { visibleColKeys, colAttrSpans, rowTotals } = pivotSettings

    const attrNameCell = <th key="label" className="pvtAxisLabel width-min-250"></th>

    const attrValueCells = []
    // Iterate through columns. Jump over duplicate values.
    let i = 0
    while (i < visibleColKeys.length) {
      const colKey = visibleColKeys[i]
      const colSpan = attrIdx < colKey.length ? colAttrSpans[i][attrIdx] : 1
      const flatColKey = flatKey(colKey.slice(0, attrIdx + 1))

      attrValueCells.push(
        <th className="pvtColLabel" key={'colKey-p' + flatColKey} rowSpan={2}>
          P
        </th>,
      )
      attrValueCells.push(
        <th className="pvtColLabel" key={'colKey-q' + flatColKey} rowSpan={2}>
          Q
        </th>,
      )
      attrValueCells.push(
        <th className="pvtColLabel" key={'colKey-i' + flatColKey} rowSpan={2}>
          Importe
        </th>,
      )
      // The next colSpan columns will have the same value anyway...
      i = i + colSpan
    }

    const totalCell = []
    if (rowTotals) {
      totalCell.push(
        <th key="total-p" className="pvtTotalLabel" rowSpan={2}>
          P
        </th>,
      )
      totalCell.push(
        <th key="total-q" className="pvtTotalLabel" rowSpan={2}>
          Q
        </th>,
      )
      totalCell.push(
        <th key="total-i" className="pvtTotalLabel" rowSpan={2}>
          Importe
        </th>,
      )
    }

    const cells = [attrNameCell, ...attrValueCells, ...totalCell]
    return <tr key={`colAttr-pxq-${attrIdx}`}>{cells}</tr>
  }

  renderRowHeaderRow(pivotSettings) {
    // Render just the attribute names of the rows (the actual attribute values
    // will show up in the individual rows).

    const { rowAttrs, rowKeys, arrowCollapsed, arrowExpanded, rowSubtotalDisplay, maxRowVisible } =
      pivotSettings

    const rowLabel = rowAttrs.map((it) => it).join(' / ')
    const i = 0
    const needLabelToggle = rowSubtotalDisplay.enabled && i !== rowAttrs.length - 1
    let clickHandle = null
    let subArrow = null
    if (needLabelToggle) {
      clickHandle =
        i + 1 < maxRowVisible
          ? this.collapseAttr(true, i, rowKeys)
          : this.expandAttr(true, i, rowKeys)
      subArrow = (i + 1 < maxRowVisible ? arrowExpanded : arrowCollapsed) + ' '
    }

    return (
      <tr key="rowHdr">
        <th className="pvtAxisLabel  width-min-250" key={`rowAttr-${i}`} onClick={clickHandle}>
          {subArrow} {rowLabel}
        </th>
      </tr>
    )
  }

  renderTableRow(rowKey, rowIdx, pivotSettings) {
    // Render a single row in the pivot table.
    const {
      rowAttrs,
      rowAttrSpans,
      visibleColKeys,
      pivotData,
      rowTotals,
      arrowExpanded,
      arrowCollapsed,
    } = pivotSettings

    const format = this.getFormatter(pivotData)

    const flatRowKey = flatKey(rowKey)
    const slugifyRowKey = this.slugify(flatRowKey)
    const attrValueCells = rowKey.map((r, i) => {
      const rowSpan = rowAttrSpans[rowIdx][i]
      if (rowSpan > 0) {
        const flatRowKey = flatKey(rowKey.slice(0, i + 1))
        const needRowToggle = i !== rowAttrs.length - 1
        const onClick = needRowToggle ? this.toggleRowKey(flatRowKey) : null

        return (
          <th
            key={`rowKeyLabel-${slugifyRowKey}-${i}`}
            className={`pvtRowLabel pl-${i} width-min-250`}
            rowSpan={1}
            onClick={onClick}
          >
            {needRowToggle
              ? (this.state.collapsedRows[flatRowKey] ? arrowCollapsed : arrowExpanded) + ' '
              : null}
            {r}
          </th>
        )
      }
      return null
    })
    const valueCells = []

    visibleColKeys.forEach((colKey) => {
      const flatColKey = flatKey(colKey)
      const slugifyColKey = this.slugify(flatColKey)
      const values = this.getAggregator(pivotData, rowKey, colKey)

      ;['price', 'quantity', 'amount'].forEach((it) => {
        const aggValue = values[it]

        valueCells.push(
          <td className="pvtVal" key={`pvtVal-${it}-${slugifyRowKey}-${slugifyColKey}`}>
            {format(aggValue)}
          </td>,
        )
      })
    })

    let totalCell = []
    if (rowTotals) {
      const values = this.getAggregator(pivotData, rowKey, [])

      ;['price', 'quantity', 'amount'].forEach((it) => {
        const aggValue = values[it]
        totalCell.push(
          <td key={`pvTotal-${it}-${slugifyRowKey}`} className="pvtTotal">
            {format(aggValue)}
          </td>,
        )
      })
    }

    const rowCells = [...attrValueCells, ...valueCells, ...totalCell]

    return <tr key={`keyRow-${slugifyRowKey}`}>{rowCells}</tr>
  }

  visibleKeys(keys, collapsed, numAttrs, subtotalDisplay) {
    return keys.filter(
      (key) =>
        // Is the key hidden by one of its parents?
        !key.some((k, j) => collapsed[flatKey(key.slice(0, j))]) &&
        // Leaf key.
        (key.length === numAttrs ||
          // Children hidden. Must show total.
          flatKey(key) in collapsed ||
          // Don't hide totals.
          !subtotalDisplay.hideOnExpand),
    )
  }

  // Custom Methods
  getFormatter(pivotData) {
    let formatterFunction = usFmt
    if (formatter.hasOwnProperty(pivotData.props.aggregatorName)) {
      formatterFunction = formatter[pivotData.props.aggregatorName]
    }
    return formatterFunction
  }

  getAggregator(pivotData, rowKey, colKey) {
    try {
      const agg = pivotData.getAggregator(rowKey, colKey)
      const aggValue = agg.multivalued()

      const amount = aggValue['amount'] ?? 0.0
      const quantity = aggValue['quantity'] ?? 0.0
      const price = quantity !== 0 ? amount / quantity : 0

      return {
        amount: amount,
        quantity: quantity,
        price: price,
      }
    } catch (e) {
      return { amount: 0, price: 0, quantity: 0 }
    }
  }

  getAggregatorBuild(pivotData, flatRowKey, colKey) {
    const rowKey = [normalizeName(flatRowKey)]
    return this.getAggregator(pivotData, rowKey, colKey)
  }

  slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
  }

  renderLinesByType(rowKey, rowIdx, pivotSettings, visibleRowKeys) {
    const { linesOrder, linesTotal, linesRatio, linesRatioN, lineVertical } = pivotSettings

    let isTree = false
    let flatRowKey = flatKey(rowKey)

    if (rowKey.length > 1) {
      flatRowKey = rowKey[0]
      isTree = true
    }
    const line = linesOrder?.find((it) => normalizeName(it.name) === flatRowKey)
    const lineTotal = linesTotal?.find((it) => normalizeName(it.name) === flatRowKey)
    const lineRatio = linesRatio?.find((it) => normalizeName(it.name) === flatRowKey)
    const lineRatioN = linesRatioN?.find((it) => normalizeName(it.name) === flatRowKey)
    const originalRowIdx = visibleRowKeys.findIndex((entry) => flatKey(entry) === flatKey(rowKey))

    if (lineTotal) {
      if (!isTree) {
        return this.renderTotalRow(rowKey, rowIdx, pivotSettings, lineTotal, line)
      }
    } else if (lineRatio) {
      if (!isTree) {
        return this.renderRatioRow(rowKey, rowIdx, pivotSettings, lineRatio, usFmtPct, line)
      }
    } else if (lineRatioN) {
      if (!isTree) {
        return this.renderRatioRow(rowKey, rowIdx, pivotSettings, lineRatioN, usFmt, line)
      }
    } else if (originalRowIdx < 0) {
      return this.renderZeroRow(rowKey, rowIdx, pivotSettings, line)
    } else {
      return this.renderTableRow(rowKey, originalRowIdx, pivotSettings, lineVertical, line)
    }
  }

  renderTotalRow(rowKey, rowIdx, pivotSettings, lineTotal, line) {
    const { visibleColKeys, pivotData, rowTotals } = pivotSettings

    const flatRowKey = flatKey(rowKey)
    const slugifyRowKey = this.slugify(flatRowKey)
    const formatterFunction = this.getFormatter(pivotData)

    const lines = lineTotal.lines.reduce((mergedLines, it) => {
      return mergedLines.concat(it.lines)
    }, [])

    const attrValueCells = rowKey.map((r, i) => {
      return (
        <th
          rowSpan={1}
          key={`rowKeyLabel-${slugifyRowKey}-${i}`}
          className={`pvtRowLabel width-min-250 ${!line?.color ? 'pvtTotalizer' : ''}  pl-${i}`}
          style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {r}
        </th>
      )
    })

    const valueCells = []
    visibleColKeys.forEach((colKey) => {
      const flatColKey = flatKey(colKey)
      const slugifyColKey = this.slugify(flatColKey)

      const values = lines.reduce(
        (acc, flatRowKey) => {
          const agg = this.getAggregatorBuild(pivotData, flatRowKey, colKey)
          const a = acc.amount + agg.amount
          const q = acc.quantity + agg.quantity
          const p = a / q

          return { amount: a, price: p, quantity: q }
        },
        { amount: 0, price: 0, quantity: 0 },
      )

      ;['price', 'quantity', 'amount'].forEach((flatColKey) => {
        let aggValue = values[flatColKey]

        valueCells.push(
          <td
            key={`pvtVal-${flatColKey}-${slugifyRowKey}-${slugifyColKey}`}
            className={`pvtVal ${!line?.color ? 'pvtTotalizer' : ''}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatterFunction(aggValue)}
          </td>,
        )
      })
    })

    let totalCell = []
    if (rowTotals) {
      const values = lines.reduce(
        (acc, flatRowKey) => {
          const agg = this.getAggregatorBuild(pivotData, flatRowKey, [])
          const a = acc.amount + agg.amount
          const q = acc.quantity + agg.quantity
          const p = a / q

          return { amount: a, price: p, quantity: q }
        },
        { amount: 0, price: 0, quantity: 0 },
      )

      ;['price', 'quantity', 'amount'].forEach((flatColKey) => {
        let aggValue = values[flatColKey]

        totalCell.push(
          <td
            key={`pvTotal-${flatColKey}-${slugifyRowKey}`}
            className={`pvtTotal ${!line?.color ? 'pvtTotalizer' : ''}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatterFunction(aggValue)}
          </td>,
        )
      })
    }

    const rowCells = [...attrValueCells, ...valueCells, ...totalCell]

    return <tr key={`keyRow-${slugifyRowKey}`}>{rowCells}</tr>
  }

  renderRatioRow(rowKey, rowIdx, pivotSettings, lineRatio, formatter = usFmt, line) {
    const { visibleColKeys, pivotData, rowTotals } = pivotSettings
    const flatRowKey = flatKey(rowKey)
    const slugifyRowKey = this.slugify(flatRowKey)

    let lines1 = [],
      lines2 = []
    if (!_.isEmpty(lineRatio.lines)) {
      lines1 = lineRatio.lines[0]
      lines2 = lineRatio.lines[1]
    }

    const attrValueCells = rowKey.map((r, i) => {
      return (
        <th
          rowSpan={1}
          key={`rowKeyLabel-${slugifyRowKey}-${i}`}
          className={`pvtRowLabel width-min-250 ${!line?.color ? 'pvtRatio' : ''} pl-${i}`}
          style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {r}
        </th>
      )
    })

    const valueCells = []
    visibleColKeys.forEach((colKey) => {
      const flatColKey = flatKey(colKey)
      const slugifyColKey = this.slugify(flatColKey)

      const values1 = lines1.lines.reduce(
        (acc, flatRowKey) => {
          const agg = this.getAggregatorBuild(pivotData, flatRowKey, colKey)
          const a = acc.amount + agg.amount
          const q = acc.quantity + agg.quantity
          const p = a / q

          return { amount: a, price: p, quantity: q }
        },
        { amount: 0, price: 0, quantity: 0 },
      )

      const values2 = lines2.lines.reduce(
        (acc, flatRowKey) => {
          const agg = this.getAggregatorBuild(pivotData, flatRowKey, colKey)
          const a = acc.amount + agg.amount
          const q = acc.quantity + agg.quantity
          const p = a / q

          return { amount: a, price: p, quantity: q }
        },
        { amount: 0, price: 0, quantity: 0 },
      )

      const values = {
        amount: values2['amount'] !== 0.0 ? values1['amount'] / values2['amount'] : 0.0,
        price: values2['price'] !== 0.0 ? values1['price'] / values2['price'] : 0.0,
        quantity: values2['quantity'] !== 0.0 ? values1['quantity'] / values2['quantity'] : 0.0,
      }

      ;['price', 'quantity', 'amount'].forEach((flatColKey) => {
        let aggValue = values[flatColKey]

        valueCells.push(
          <td
            key={`pvtVal-${flatColKey}-${slugifyRowKey}-${slugifyColKey}`}
            className={`pvtVal ${!line?.color ? 'pvtRatio' : ''}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatter(aggValue)}
          </td>,
        )
      })
    })

    let totalCell = []
    if (rowTotals) {
      const values1 = lines1.lines.reduce(
        (acc, flatRowKey) => {
          const agg = this.getAggregatorBuild(pivotData, flatRowKey, [])
          const a = acc.amount + agg.amount
          const q = acc.quantity + agg.quantity
          const p = a / q

          return { amount: a, price: p, quantity: q }
        },
        { amount: 0, price: 0, quantity: 0 },
      )

      const values2 = lines2.lines.reduce(
        (acc, flatRowKey) => {
          const agg = this.getAggregatorBuild(pivotData, flatRowKey, [])
          const a = acc.amount + agg.amount
          const q = acc.quantity + agg.quantity
          const p = a / q

          return { amount: a, price: p, quantity: q }
        },
        { amount: 0, price: 0, quantity: 0 },
      )

      const values = {
        amount: values2['amount'] !== 0.0 ? values1['amount'] / values2['amount'] : 0.0,
        price: values2['price'] !== 0.0 ? values1['price'] / values2['price'] : 0.0,
        quantity: values2['quantity'] !== 0.0 ? values1['quantity'] / values2['quantity'] : 0.0,
      }

      ;['price', 'quantity', 'amount'].forEach((flatColKey) => {
        let aggValue = values[flatColKey]

        totalCell.push(
          <td
            key={`pvTotal-${flatColKey}-${slugifyRowKey}`}
            className={`pvtTotal ${!line?.color ? 'pvtRatio' : ''}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatter(aggValue)}
          </td>,
        )
      })
    }

    const rowCells = [...attrValueCells, ...valueCells, ...totalCell]

    return <tr key={`keyRow-${slugifyRowKey}`}>{rowCells}</tr>
  }

  renderZeroRow(rowKey, rowIdx, pivotSettings, line) {
    const { visibleColKeys, pivotData, rowTotals } = pivotSettings
    const flatRowKey = flatKey(rowKey)
    const slugifyRowKey = this.slugify(flatRowKey)
    const format = this.getFormatter(pivotData)

    const attrValueCells = rowKey.map((r, i) => {
      return (
        <th
          rowSpan={1}
          key={`rowKeyLabel-${slugifyRowKey}-${i}`}
          className={`pvtRowLabel width-min-250 pl-${i}`}
          style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {r}
        </th>
      )
    })

    const valueCells = []
    visibleColKeys.forEach((colKey) => {
      const flatColKey = flatKey(colKey)
      const slugifyColKey = this.slugify(flatColKey)

      ;['price', 'quantity', 'amount'].forEach((it) => {
        valueCells.push(
          <td
            className="pvtVal"
            key={`pvtVal-${it}-${slugifyRowKey}-${slugifyColKey}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {format(0)}
          </td>,
        )
      })
    })

    let totalCell = []
    if (rowTotals) {
      ;['price', 'quantity', 'amount'].forEach((it) => {
        totalCell.push(
          <td
            className="pvtTotal"
            key={`pvTotal-${it}-${slugifyRowKey}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {format(0)}
          </td>,
        )
      })
    }

    const rowCells = [...attrValueCells, ...valueCells, ...totalCell]
    return <tr key={`keyRow-${slugifyRowKey}`}>{rowCells}</tr>
  }

  render() {
    if (this.cachedProps !== this.props) {
      this.cachedProps = this.props
      this.cachedBasePivotSettings = this.getBasePivotSettings()
    }

    const {
      colAttrs,
      rowAttrs,
      rowKeys,
      colKeys,
      rowSubtotalDisplay,
      colSubtotalDisplay,
      linesOrder,
    } = this.cachedBasePivotSettings

    // Need to account for exclusions to compute the effective row
    // and column keys.
    const visibleRowKeys = this.visibleKeys(
      rowKeys,
      this.state.collapsedRows,
      rowAttrs.length,
      rowSubtotalDisplay,
    )
    const visibleColKeys = this.visibleKeys(
      colKeys,
      this.state.collapsedCols,
      colAttrs.length,
      colSubtotalDisplay,
    )
    const pivotSettings = Object.assign(
      {
        visibleRowKeys,
        maxRowVisible: Math.max(...visibleRowKeys.map((k) => k.length)),
        visibleColKeys,
        maxColVisible: Math.max(...visibleColKeys.map((k) => k.length)),
        rowAttrSpans: this.calcAttrSpans(visibleRowKeys, rowAttrs.length),
        colAttrSpans: this.calcAttrSpans(visibleColKeys, colAttrs.length),
      },
      this.cachedBasePivotSettings,
    )

    let visibleRowKeysByTemplate = []
    linesOrder.forEach((it) => {
      let hasRow = false
      if (it.type === 'grouper') {
        const listFilter = visibleRowKeys.filter((entry) => it.name === entry[0])
        if (listFilter.length > 0) {
          hasRow = true
          visibleRowKeysByTemplate.push(...listFilter)
        }
      }
      if (!hasRow) {
        visibleRowKeysByTemplate.push([it.name])
      }
    })

    const indexLastColAttrs = colAttrs.length > 0 ? colAttrs.length - 1 : 0

    return (
      <table className="pvtTable">
        <thead>
          {colAttrs.map((it, index) => this.renderColHeaderRow(it, index, pivotSettings))}
          {this.renderColFixedHeaderRow('PxQ', indexLastColAttrs, pivotSettings)}
          {rowAttrs.length !== 0 && this.renderRowHeaderRow(pivotSettings)}
        </thead>
        <tbody>
          {visibleRowKeysByTemplate.map((r, i) =>
            this.renderLinesByType(r, i, pivotSettings, visibleRowKeys),
          )}
        </tbody>
      </table>
    )
  }
}

TablePQI.defaultProps = {
  aggregators: aggregatorsTablePxQ,
  cols: [],
  rows: [],
  vals: [],
  aggregatorName: 'Suma (Decimales)',
  sorters: {},
  valueFilter: {},
  rowOrder: 'key_a_to_z',
  colOrder: 'key_a_to_z',
  derivedAttributes: {},
}
TablePQI.propTypes = PivotData.propTypes
TablePQI.defaultProps.tableOptions = {}

export default TablePQI
