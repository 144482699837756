import { Checkbox, Col, DatePicker, Form, Input, Modal, Row, Select, Space } from 'antd'
import _ from 'lodash'
import { PROJECTION } from 'modules/core/constants'
import { generateSelectOptions } from 'modules/core/utils'
import forecast from 'modules/forecast'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { onCreate } from './utils'
import { disabledDate } from 'modules/planning/components/CreateBudgetModal/utils'
import planning from 'modules/planning'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { AddAndDeleteFormItem, Button, Typography } from 'modules/core/components'
import { FilterDataCreateModal } from 'modules/planning/components/CreateBudgetModal/components'

const CreateForecastInstanceModal = ({
  visible,
  onCancel,
  optionsSelect,
  onRefetch,
  conceptId,
  dataPeriod,
  createForecastInstance,
  periodList,
  fetchForecastFilterDates,
  forecastFilterDates,
}) => {
  const enableDatesSamePeriod = useFeatureIsOn('dates-same-period')
  const [isLoading, setIsLoading] = useState(false)
  const [filterDates, setFilterDates] = useState({})
  const [index, setIndex] = useState(null)
  const [filterData, setfilterData] = useState({})
  const [showFilterData, setShowFilterData] = useState(false)
  const [projectionId, setProjectionId] = useState(null)
  const [type, setType] = useState('')
  const [form] = Form.useForm()
  const { t } = useTranslation()
  let { forecastId } = useParams()

  useEffect(() => {
    if (!_.isEmpty(filterDates)) {
      fetchForecastFilterDates({ ...filterDates, forecast_id: forecastId })
    }
  }, [fetchForecastFilterDates, filterDates, forecastId])

  const onclose = () => {
    form.resetFields()
    setIsLoading(false)
    setFilterDates({})
    onCancel()
    setIndex(null)
    setfilterData({})
  }

  const generateOptionsValue = (index) => {
    const OPTIONS_VALUE = [
      {
        id: 'amount',
        name: 'FIELD_AMOUNT',
        disabled:
          form.getFieldValue('historical_list')[index] &&
          form.getFieldValue('historical_list')[index]?.type_value
            ? form.getFieldValue('historical_list')[index]?.type_value.includes('price') ||
              form.getFieldValue('historical_list')[index]?.type_value.includes('quantity')
            : false,
      },
      {
        id: 'price',
        name: 'FIELD_PRICE',
        disabled:
          form.getFieldValue('historical_list')[index] &&
          form.getFieldValue('historical_list')[index]?.type_value
            ? form.getFieldValue('historical_list')[index]?.type_value.includes('amount')
            : false,
      },
      {
        id: 'quantity',
        name: 'FIELD_COUNT',
        disabled:
          form.getFieldValue('historical_list')[index] &&
          form.getFieldValue('historical_list')[index]?.type_value
            ? form.getFieldValue('historical_list')[index]?.type_value.includes('amount')
            : false,
      },
    ]

    return OPTIONS_VALUE
  }

  const handleCreate = (values) => {
    const { type_budget, dates, projections, name, historical_list } = values

    const splitterProjections = projections
      ? projections.map((it) => {
          const splitter = it.split('_')
          return { id: parseInt(splitter[0]), type: splitter[1] }
        })
      : []

    const auxDataHistorical = historical_list
      ? historical_list.map((el, index) => {
          const id = el.budget_id.split('_')[0]
          const type = el.budget_id.split('_')[1]
          return {
            ...el,
            budget_id: id,
            type_base: type === 'forecast' ? 'FORECAST_GLOBAL' : 'PRESUPUESTO_GLOBAL',
            ...(filterData[index] && {
              filter_data: filterData[index].reduce((acum, data) => {
                acum = { ...acum, [data.dimension_id]: data.values }
                return acum
              }, {}),
            }),
          }
        })
      : []

    const data = {
      name,
      type_budget,
      concept: conceptId,
      forecast_id: forecastId,
      ...(type_budget === 'VALOR_CERO' && {
        overall_budget_ids: splitterProjections
          .filter((it) => it.type === 'budget')
          .map((it) => it.id),
        forecast_overall_budget_ids: splitterProjections
          .filter((it) => it.type === 'forecast')
          .map((it) => it.id),
      }),
      ...(dates && {
        date_start: moment(dates[0]).startOf('month').format('YYYY-MM-DD'),
        date_end: moment(dates[1]).startOf('month').format('YYYY-MM-DD'),
      }),
      ...(type_budget === 'HISTORICAL_PREVIOUS_PERIOD' && {
        projections: auxDataHistorical,
      }),
    }

    return createForecastInstance(data)
  }

  const disabledDateZeroValue = (date) => {
    const pickerDates = moment(date._d).utc().format('YYYY-MM')
    return !(pickerDates >= dataPeriod.start_date && pickerDates <= dataPeriod.end_date)
  }

  const renderDisabledDatePicker = (date) => {
    if (enableDatesSamePeriod) {
      return disabledDateZeroValue(date)
    }
    return disabledDate(date, periodList, dataPeriod)
  }

  const hasZeroValue = (value) => {
    return value && value.toString() === 'VALOR_CERO'
  }

  const hasHistoricalValue = (value) => value && value.toString() === 'HISTORICAL_PREVIOUS_PERIOD'

  const options = !_.isEmpty(forecastFilterDates)
    ? [
        ...forecastFilterDates.forecast_overall_budget.map((el) => {
          return {
            ...el,
            type: 'forecast',
          }
        }),
        ...forecastFilterDates.overall_budget.map((el) => {
          return {
            ...el,
            type: 'budget',
          }
        }),
      ]
    : []

  return (
    <Modal
      title={t('FORECAST_CREATE_MODAL_TITLE')}
      okText={t('ACTION_CREATE')}
      cancelText={t('ACTION_CANCEL')}
      centered
      forceRender
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      visible={visible}
      onCancel={onclose}
      onOk={() =>
        onCreate({
          form,
          setIsLoading,
          createForecastInstance: (values) => handleCreate(values),
          onRefetch,
          onclose,
        })
      }
      destroyOnClose={true}
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <Form form={form} layout="vertical" initialValues={{ historical_list: [''] }}>
        {() => (
          <>
            <Form.Item
              label={t('FIELD_NAME')}
              name="name"
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Input placeholder={t('ENTER_NAME_PLACEHOLDER')} />
            </Form.Item>

            <Form.Item
              name="type_budget"
              label={t('FORECAST_CREATE_NEW_BUDGET_BASE_TYPE_FORM_LABEL')}
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Select
                placeholder={t('ACTION_SELECT')}
                options={generateSelectOptions({
                  options: [
                    {
                      id: 'VALOR_CERO',
                      name: 'PLANNING_CREATE_NEW_BUDGET_BASE_OPTION_FORM_SELECT',
                      type: 'cero',
                    },
                    {
                      id: 'HISTORICAL_PREVIOUS_PERIOD',
                      name: 'PLANNING_CREATE_NEW_BUDGET_PREVIUS_PERIOD_OPTION_FORM_SELECT',
                      type: 'historical',
                    },
                  ].map((op) => {
                    return { ...op, name: t(op.name) }
                  }),
                  dataTooltipLabel: 'PLANNING_UPLOAD_VALUE_CERO_INFORMATIVE_TOOLTIP_TITLE',
                })}
              />
            </Form.Item>

            {(hasZeroValue(form.getFieldsValue().type_budget) ||
              hasHistoricalValue(form.getFieldsValue().type_budget)) && (
              <Form.Item
                name="dates"
                label={t('PLANNING_CREATE_NEW_BUDGET_BASE_DATES_FORM_LABEL')}
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
              >
                <DatePicker.RangePicker
                  format="MMM YYYY"
                  picker="month"
                  style={{ width: '100%' }}
                  onChange={(val) => {
                    if (!_.isEmpty(val)) {
                      setFilterDates({
                        date_start: moment(val[0]).startOf('month').format('YYYY-MM-DD'),
                        date_end: moment(val[1]).startOf('month').format('YYYY-MM-DD'),
                      })
                    } else {
                      setFilterDates({})
                    }
                  }}
                  disabledDate={(date) => renderDisabledDatePicker(date)}
                  defaultPickerValue={
                    !_.isEmpty(dataPeriod)
                      ? [moment(dataPeriod?.start_date), moment(dataPeriod?.end_date)]
                      : null
                  }
                />
              </Form.Item>
            )}
            {!_.isEmpty(filterDates) && hasZeroValue(form.getFieldsValue().type_budget) && (
              <Form.Item
                name="projections"
                label={t('PLANNING_PROJECTION_LIST_FORM_LABEL')}
                rules={[
                  {
                    required: true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
              >
                <Select
                  placeholder={t('ACTION_SELECT')}
                  showArrow
                  allowClear
                  showSearch
                  mode="multiple"
                  maxTagCount={3}
                  maxTagTextLength={1}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={generateSelectOptions({
                    options: options.map((el) => {
                      return {
                        id: `${el.id}_${el.type}`,
                        name: `${el.name}(${t(el.type)})-(${t(
                          PROJECTION.STATUS_COLORS[el.status].name,
                        )})`,
                      }
                    }),
                  })}
                />
              </Form.Item>
            )}
            {!_.isEmpty(filterDates) && hasHistoricalValue(form.getFieldsValue().type_budget) && (
              <Form.List name="historical_list">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index2) => {
                        return (
                          <Row gutter={[24, 24]} key={field.key} type="flex" justify="end">
                            <Col span={24}>
                              <Form.Item
                                name={[field.name, 'budget_id']}
                                label={[field.label, t('PLANNING_PROJECTION_LIST_FORM_LABEL')]}
                                rules={[
                                  {
                                    required: true,
                                    message: t('REQUIRED_FIELD'),
                                  },
                                ]}
                              >
                                <Select
                                  placeholder={t('ACTION_SELECT')}
                                  showArrow
                                  allowClear
                                  showSearch
                                  onChange={(val) => {
                                    const auxType =
                                      val && val.split('_')[1] === 'forecast'
                                        ? 'forecast_overall_budget'
                                        : 'planning_overall_budget'
                                    val && setProjectionId(val.split('_')[0])
                                    setType(auxType)
                                  }}
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                  options={generateSelectOptions({
                                    options: options.map((el) => {
                                      return {
                                        id: `${el.id}_${el.type}`,
                                        name: `${el.name} (${t(el.type)})-(${t(
                                          PROJECTION.STATUS_COLORS[el.status].name,
                                        )})`,
                                      }
                                    }),
                                  })}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                name={[field.name, 'type_value']}
                                label={[field.label, t('FIELD_VALUE')]}
                                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                              >
                                <Checkbox.Group>
                                  <Space direction="horizontal">
                                    {generateOptionsValue(field.name).map((el) => (
                                      <Space key={el.id} direction="horizontal">
                                        <Checkbox disabled={el.disabled} value={el.id} />
                                        <Typography.Body>{t(el.name)}</Typography.Body>
                                      </Space>
                                    ))}
                                  </Space>
                                </Checkbox.Group>
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                name={[field.name, 'filter_data']}
                                label={[
                                  field.label,
                                  t('PLANNING_CREATE_NEW_BUSGET_BASE_DATA_FILTER_FORM_LABEL'),
                                ]}
                                initialValue={[]}
                              >
                                <Button.Default
                                  title={t('ACTION_ADD_SEE_FILTER')}
                                  block
                                  disabled={
                                    form.getFieldValue('historical_list') &&
                                    !form.getFieldValue('historical_list')[field.name]?.budget_id
                                  }
                                  onClick={() => {
                                    setShowFilterData(true)
                                    setIndex(index2)
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <AddAndDeleteFormItem
                              fieldsLength={fields.length}
                              index={index2}
                              addData={{
                                // disabled: isLoading,
                                onClick: () => {
                                  add()
                                },
                                buttonName: 'ACTION_ADD_NEW',
                              }}
                              deleteData={{
                                // disabled: isLoading,
                                onClick: () => {
                                  remove(field.name)
                                },
                                buttonName: 'ACTION_DELETE',
                              }}
                            />
                          </Row>
                        )
                      })}
                    </div>
                  )
                }}
              </Form.List>
            )}
          </>
          // )}
        )}
      </Form>
      <FilterDataCreateModal
        visible={showFilterData}
        onClose={() => setShowFilterData(false)}
        setfilterData={setfilterData}
        index={index}
        filterData={filterData}
        type={type}
        projectionId={projectionId}
      />
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  periodList: planning.selectors.getPeriodList(state),
  forecastFilterDates: forecast.selectors.getForecastFilterDates(state),
})

const mapDispatchToProps = {
  createForecastInstance: forecast.actions.createForecastInstance,
  fetchForecastFilterDates: forecast.actions.fetchForecastFilterDates,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateForecastInstanceModal)
