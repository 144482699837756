import { Space } from 'antd'
import { Typography } from 'modules/core/components'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const Empty = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <div className="container-empty" onClick={onClick}>
      <Space direction="horizontal">
        <Typography.Icon icon={PlusCircleOutlined} />
        <Typography.Body>{t('PLANNING_SAC_ADD_NEW_COMBINATION_ACTION_AREA_TITLE')}</Typography.Body>
      </Space>
    </div>
  )
}

export default Empty
