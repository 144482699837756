import _, { isEmpty } from 'lodash'
import React from 'react'
import { aggregatorsTablePxQ } from '../../../Aggregators'
import { formatter, PivotData, redColorScaleGenerator, spanSize, usFmt } from '../../../Utilities'

class TablePxQ extends React.PureComponent {
  getBasePivotSettings() {
    const props = this.props
    const colAttrs = props.cols
    const rowAttrs = props.rows

    const pivotData = new PivotData(
      Object.assign({}, this.props, {
        aggregators: aggregatorsTablePxQ,
        vals: ['price', 'quantity', 'amount'],
      }),
    )
    const colKeys = pivotData.getColKeys()
    const rowKeys = pivotData.getRowKeys()

    const tableOptions = props.tableOptions
    const realMonths = !isEmpty(tableOptions?.realMonths) ? tableOptions.realMonths : []
    const visibleTotal = tableOptions?.visibleTotal != null ? tableOptions?.visibleTotal : true

    return Object.assign({
      pivotData,
      colAttrs,
      rowAttrs,
      colKeys,
      rowKeys,
      realMonths,
      visibleTotal,
    })
  }

  clickHandler(pivotData, rowValues, colValues, attr) {
    const colAttrs = this.props.cols
    const rowAttrs = this.props.rows
    const agg = pivotData.getAggregator(rowValues, colValues)
    const value = agg.multivalued()[attr]
    const filters = {}
    const colLimit = Math.min(colAttrs.length, colValues.length)
    for (let i = 0; i < colLimit; i++) {
      const attr = colAttrs[i]
      if (colValues[i] !== null) {
        filters[attr] = colValues[i]
      }
    }
    const rowLimit = Math.min(rowAttrs.length, rowValues.length)
    for (let i = 0; i < rowLimit; i++) {
      const attr = rowAttrs[i]
      if (rowValues[i] !== null) {
        filters[attr] = rowValues[i]
      }
    }
    return (e) => this.props.tableOptions.clickCallback(e, value, filters, pivotData, attr)
  }

  checkDateInReal(colKey, realMonths) {
    const pattern = /^\d{4}-\d{2}$/
    const baseSet = new Set(realMonths)
    const foundItem = _.find(colKey, (it) => pattern.test(it) && baseSet.has(it))
    return !!foundItem
  }

  renderColHeaderRow(attrName, attrIdx, pivotSettings) {
    const { colAttrs, rowAttrs, colKeys } = pivotSettings

    const spanCell =
      attrIdx === 0 && rowAttrs.length !== 0 ? (
        <th className={`header_0`} colSpan={rowAttrs.length} rowSpan={colAttrs.length + 1} />
      ) : null

    const attrNameCell = (
      <th
        key="label"
        className={`pvtAxisLabel header_${rowAttrs.length} header_tot`}
        style={{ textAlign: 'right' }}
      >
        {attrName}
      </th>
    )

    const attrValueCells = colKeys.map((colKey, i) => {
      const x = spanSize(colKeys, i, attrIdx)
      if (x === -1) {
        return null
      }
      return (
        <th
          className="pvtColLabel"
          key={`colKey-${i}`}
          colSpan={x * 3}
          rowSpan={attrIdx === colAttrs.length - 1 && rowAttrs.length !== 0 ? 1 : 1}
        >
          {colKey[attrIdx]}
        </th>
      )
    })

    const totalCell =
      attrIdx === 0 ? (
        <th
          key="total"
          className="pvtTotalLabel"
          colSpan={3}
          rowSpan={colAttrs.length + (rowAttrs.length === 0 ? 0 : 0)}
        >
          TOTAL
        </th>
      ) : null

    const cells = [spanCell, attrNameCell, ...attrValueCells, totalCell]
    return <tr key={`colAttr-${attrIdx}`}>{cells}</tr>
  }

  renderColFixedHeaderRow(attrName, attrIdx, pivotSettings) {
    const { rowAttrs, colKeys } = pivotSettings

    const attrNameCell = (
      <th
        key="label"
        className={`pvtAxisLabel header_${rowAttrs.length} header_tot`}
        style={{ textAlign: 'right' }}
      >
        {attrName}
      </th>
    )

    const attrValueCells = []
    colKeys.forEach((colKey, i) => {
      const x = spanSize(colKeys, i, attrIdx)
      if (x === -1) {
        return null
      }
      attrValueCells.push(
        ...[
          <th className="pvtColLabel" key={`colKey-${i}`} rowSpan={2}>
            P
          </th>,
          <th className="pvtColLabel" key={`colKey-${i}`} rowSpan={2}>
            Q
          </th>,
          <th className="pvtColLabel" key={`colKey-${i}`} rowSpan={2}>
            Importe
          </th>,
        ],
      )
    })

    const totalCell = [
      <th key="total" className="pvtTotalLabel" rowSpan={2}>
        P
      </th>,
      <th key="total" className="pvtTotalLabel" rowSpan={2}>
        Q
      </th>,
      <th key="total" className="pvtTotalLabel" rowSpan={2}>
        Importe
      </th>,
    ]

    const cells = [attrNameCell, ...attrValueCells, ...totalCell]
    return <tr key={`colAttr-${attrIdx}`}>{cells}</tr>
  }

  renderRowHeaderRow(pivotSettings) {
    const { rowAttrs, colAttrs } = pivotSettings
    return rowAttrs.length !== 0 ? (
      <tr key="rowHdr">
        {rowAttrs.map(function (r, i) {
          return (
            <th className={`pvtAxisLabel header_${i}`} key={`rowAttr${i}`}>
              {r}
            </th>
          )
        })}
        <th className={`pvtTotalLabel header_${rowAttrs.length} header_tot`}>
          {colAttrs.length === 0 ? 'Totals' : null}
        </th>
      </tr>
    ) : null
  }

  renderTableRow(rowKey, rowIdx, pivotSettings) {
    const { rowKeys, colKeys, pivotData, rowAttrs, colAttrs, realMonths } = pivotSettings
    const format = this.getFormatter(pivotData)

    const attrValueCells = rowKey.map((txt, j) => {
      const x = spanSize(rowKeys, rowIdx, j)
      if (x === -1) {
        return null
      }

      return (
        <th
          key={`rowKeyLabel-${rowIdx}-${j}`}
          className={`pvtRowLabel header_${j}`}
          rowSpan={x}
          colSpan={j === rowAttrs.length - 1 && colAttrs.length !== 0 ? 2 : 1}
        >
          {txt}
        </th>
      )
    })

    const valueCells = []
    colKeys.forEach((colKey, j) => {
      const values = this.getAggregator(pivotData, rowKey, colKey)

      let hasRealMonth = false
      if (realMonths.length > 0) {
        hasRealMonth = this.checkDateInReal(colKey, realMonths)
      }

      ;['price', 'quantity', 'amount'].forEach((flatColKey) => {
        let aggValue = values[flatColKey]
        let handleClick = this.clickHandler(pivotData, rowKey, colKey, flatColKey)
        if (hasRealMonth) {
          handleClick = () => {}
        }

        valueCells.push(
          <td
            className="pvtVal"
            key={`pvtVal-${rowIdx}-${j}-${flatColKey}`}
            onClick={handleClick}
            style={{ ...(hasRealMonth ? { backgroundColor: '#eaeaea' } : {}) }}
          >
            {format(aggValue)}
          </td>,
        )
      })
    })

    const totalCell = []
    const values = this.getAggregator(pivotData, rowKey, [])

    ;['price', 'quantity', 'amount'].forEach((flatColKey) => {
      let aggValue = values[flatColKey]

      totalCell.push(
        <td key={`total-${flatColKey}-${rowIdx}-row`} className="pvtTotal">
          {format(aggValue)}
        </td>,
      )
    })

    const rowCells = [...attrValueCells, ...valueCells, ...totalCell]
    return <tr key={`keyRow-${rowIdx}`}>{rowCells}</tr>
  }

  renderTotalsRow(pivotSettings) {
    // Render the final totals rows that has the totals for all the columns.
    const { pivotData, colKeys, colAttrs, rowAttrs, realMonths } = pivotSettings
    const format = this.getFormatter(pivotData)

    const totalLabelCell = (
      <th className="pvtTotalLabel" colSpan={rowAttrs.length + (colAttrs.length === 0 ? 0 : 1)}>
        TOTAL
      </th>
    )

    const totalValueCells = []
    colKeys.forEach((colKey, i) => {
      const values = this.getAggregator(pivotData, [], colKey)

      let hasRealMonth = false
      if (realMonths.length > 0) {
        hasRealMonth = this.checkDateInReal(colKey, realMonths)
      }

      ;['price', 'quantity', 'amount'].forEach((flatColKey) => {
        const aggValue = values[flatColKey]

        totalValueCells.push(
          <td
            key={`total-${i}-${flatColKey}-total-row`}
            className="pvtTotal"
            style={{ ...(hasRealMonth ? { backgroundColor: '#eaeaea' } : {}) }}
          >
            {format(aggValue)}
          </td>,
        )
      })
    })

    const grandTotalCell = []
    const values = this.getAggregator(pivotData, [], [])

    ;['price', 'quantity', 'amount'].forEach((it) => {
      const aggValue = values[it]

      grandTotalCell.push(
        <td key={`total-${it}-total-row`} className="pvtGrandTotal">
          {format(aggValue)}
        </td>,
      )
    })

    const totalCells = [totalLabelCell, ...totalValueCells, ...grandTotalCell]

    return <tr key="total">{totalCells}</tr>
  }

  getAggregator(pivotData, rowKey, colKey) {
    try {
      const agg = pivotData.getAggregator(rowKey, colKey)
      const aggValue = agg.multivalued()

      const amount = aggValue['amount'] ?? 0.0
      const quantity = aggValue['quantity'] ?? 0.0
      const price = quantity !== 0 ? amount / quantity : 0
      return {
        amount: amount,
        quantity: quantity,
        price: price,
      }
    } catch (e) {
      return { amount: 0, price: 0, quantity: 0 }
    }
  }

  getFormatter(pivotData) {
    let formatterFunction = usFmt
    if (formatter.hasOwnProperty(pivotData.props.aggregatorName)) {
      formatterFunction = formatter[pivotData.props.aggregatorName]
    }
    return formatterFunction
  }

  render() {
    if (this.cachedProps !== this.props) {
      this.cachedProps = this.props
      this.cachedBasePivotSettings = this.getBasePivotSettings()
    }
    const { colAttrs, rowAttrs, rowKeys, visibleTotal } = this.cachedBasePivotSettings
    const pivotSettings = this.cachedBasePivotSettings

    const indexLastColAttrs = colAttrs.length > 0 ? colAttrs.length - 1 : 0

    return (
      <table className="pvtTable">
        <thead>
          {colAttrs.map((it, index) => this.renderColHeaderRow(it, index, pivotSettings))}
          {this.renderColFixedHeaderRow('PxQ', indexLastColAttrs, pivotSettings)}
          {rowAttrs.length !== 0 && this.renderRowHeaderRow(pivotSettings)}
        </thead>
        <tbody>
          {rowKeys.map((r, i) => this.renderTableRow(r, i, pivotSettings))}
          {visibleTotal && this.renderTotalsRow(pivotSettings)}
        </tbody>
      </table>
    )
  }
}

TablePxQ.defaultProps = PivotData.defaultProps
TablePxQ.propTypes = PivotData.propTypes
TablePxQ.defaultProps.aggregators = aggregatorsTablePxQ
TablePxQ.defaultProps.tableColorScaleGenerator = redColorScaleGenerator
TablePxQ.defaultProps.tableOptions = {}

export default TablePxQ
