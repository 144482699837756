import numeral from 'numeral'

const numberFormat = function (opts_in) {
  const defaults = {
    format: '0,0',
  }
  const opts = Object.assign({}, defaults, opts_in)
  return function (x) {
    if (isNaN(x) || !isFinite(x)) {
      return ''
    }
    return numeral(x).format(opts.format)
  }
}
// TODO: temporary fix for MMT format
const numberFormatMMT = function (opts_in) {
  return function (x) {
    if (isNaN(x) || !isFinite(x)) {
      return ''
    }

    if (opts_in.type === 'miles') return numeral(x / 1000).format('0,0.[0000000]')
    if (opts_in.type === 'millones') return numeral(x / 1000000).format('0,0.[0000000]')

    return x
  }
}

const usFmt = numberFormat({ format: '0,0.00' })
const usFmtInt = numberFormat({ format: '0,0' })
const usFmtMMT = numberFormat({ format: '0[.]0 a' })
const usFmtPct = numberFormat({ format: '0.00 %' })
const usFmtT = numberFormatMMT({ type: 'miles' })
const usFmtMM = numberFormatMMT({ type: 'millones' })

const aggregatorTemplates = {
  count(formatter = usFmtInt) {
    return () =>
      function () {
        return {
          count: 0,
          push() {
            this.count++
          },
          value() {
            return this.count
          },
          format: formatter,
        }
      }
  },
  sum(formatter = usFmt) {
    return function ([attr]) {
      return function () {
        return {
          sum: 0,
          push(record) {
            if (!isNaN(parseFloat(record[attr]))) {
              this.sum += parseFloat(record[attr])
            }
          },
          value() {
            return this.sum
          },
          format: formatter,
          numInputs: typeof attr !== 'undefined' ? 0 : 1,
        }
      }
    }
  },
  quantile(q, formatter = usFmt) {
    return function ([attr]) {
      return function () {
        return {
          vals: [],
          push(record) {
            const x = parseFloat(record[attr])
            if (!isNaN(x)) {
              this.vals.push(x)
            }
          },
          value() {
            if (this.vals.length === 0) {
              return null
            }
            this.vals.sort((a, b) => a - b)
            const i = (this.vals.length - 1) * q

            return (this.vals[Math.floor(i)] + this.vals[Math.ceil(i)]) / 2.0
          },
          format: formatter,
          numInputs: typeof attr !== 'undefined' ? 0 : 1,
        }
      }
    }
  },
  runningStat(mode = 'mean', ddof = 1, formatter = usFmt) {
    return function ([attr]) {
      return function () {
        return {
          n: 0.0,
          m: 0.0,
          s: 0.0,
          push(record) {
            const x = parseFloat(record[attr])
            if (isNaN(x)) {
              return
            }
            this.n += 1.0
            if (this.n === 1.0) {
              this.m = x
            }
            const m_new = this.m + (x - this.m) / this.n
            this.s = this.s + (x - this.m) * (x - m_new)
            this.m = m_new
          },
          value() {
            if (mode === 'mean') {
              if (this.n === 0) {
                return 0 / 0
              }
              return this.m
            }
            if (this.n <= ddof) {
              return 0
            }
            switch (mode) {
              case 'var':
                return this.s / (this.n - ddof)
              case 'stdev':
                return Math.sqrt(this.s / (this.n - ddof))
              default:
                throw new Error('unknown mode for runningStat')
            }
          },
          format: formatter,
          numInputs: typeof attr !== 'undefined' ? 0 : 1,
        }
      }
    }
  },

  // Multiple attributes
  multiCount(formatter = usFmtInt) {
    return function (attrs) {
      return function () {
        let countedFacts = {}
        let i = 0
        let attr = attrs[i]

        // Inicializamos el conteo de cada atributo en 0
        while (i < attrs.length) {
          countedFacts[attrs[i]] = 0
          i++
        }

        return {
          push(record) {
            i = 0
            while (i < attrs.length) {
              if (!isNaN(parseFloat(record[attrs[i]]))) {
                countedFacts[attrs[i]]++
              }
              i++
            }
            return countedFacts
          },
          value() {
            return countedFacts[attrs[0]]
          },
          multivalued() {
            return countedFacts
          },
          format: formatter,
          numInputs: attr != null ? 0 : 2,
        }
      }
    }
  },
  multiSum(formatter = usFmt) {
    return function (attrs) {
      return function () {
        let summedFacts = {}
        let i = 0
        let attr = attrs[i]
        while (i < attrs.length) {
          summedFacts[attrs[i]] = 0
          i++
        }
        return {
          push(record) {
            i = 0
            while (i < attrs.length) {
              if (!isNaN(parseFloat(record[attrs[i]]))) {
                summedFacts[attrs[i]] += parseFloat(record[attrs[i]])
              }
              i++
            }
            return summedFacts
          },
          value() {
            return parseFloat(summedFacts[attrs[0]])
          },
          multivalued() {
            return summedFacts
          },
          format: formatter,
          numInputs: attr != null ? 0 : 2,
        }
      }
    }
  },
  multiQuantile(q, formatter = usFmt) {
    return function (attrs) {
      if (!Array.isArray(attrs)) {
        attrs = [attrs] // Asegura que sea un array
      }
      return function () {
        const valsMap = {} // Contendrá los valores de cada atributo
        attrs.forEach((attr) => {
          valsMap[attr] = []
        })

        return {
          push(record) {
            attrs.forEach((attr) => {
              const x = parseFloat(record[attr])
              if (!isNaN(x)) {
                valsMap[attr].push(x)
              }
            })
          },
          value(attr) {
            // Si no se pasa un atributo, toma el primero como predeterminado
            attr = attr || attrs[0]
            const vals = valsMap[attr]
            if (vals.length === 0) {
              return null
            }
            vals.sort((a, b) => a - b)
            const i = (vals.length - 1) * q
            return (vals[Math.floor(i)] + vals[Math.ceil(i)]) / 2.0
          },
          multivalued() {
            // Devuelve los quantiles para todos los atributos
            const results = {}
            attrs.forEach((attr) => {
              const vals = valsMap[attr]
              if (vals.length === 0) {
                results[attr] = null
              } else {
                vals.sort((a, b) => a - b)
                const i = (vals.length - 1) * q
                results[attr] = (vals[Math.floor(i)] + vals[Math.ceil(i)]) / 2.0
              }
            })
            return results
          },
          format: formatter,
          numInputs: attrs.length,
        }
      }
    }
  },
  multiRunningStat(mode = 'mean', ddof = 1, formatter = usFmt) {
    return function (attrs) {
      if (!Array.isArray(attrs)) {
        attrs = [attrs] // Asegura que sea un array
      }
      return function () {
        const statsMap = {} // Contendrá las estadísticas para cada atributo
        attrs.forEach((attr) => {
          statsMap[attr] = { n: 0.0, m: 0.0, s: 0.0 }
        })

        return {
          push(record) {
            attrs.forEach((attr) => {
              const x = parseFloat(record[attr])
              if (isNaN(x)) {
                return
              }
              const stats = statsMap[attr]
              stats.n += 1.0
              if (stats.n === 1.0) {
                stats.m = x
              }
              const m_new = stats.m + (x - stats.m) / stats.n
              stats.s = stats.s + (x - stats.m) * (x - m_new)
              stats.m = m_new
            })
          },
          value(attr) {
            // Si no se pasa un atributo, toma el primero como predeterminado
            attr = attr || attrs[0]
            const stats = statsMap[attr]
            if (stats.n === 0) {
              return NaN // No hay datos
            }
            if (mode === 'mean') {
              return stats.m
            }
            if (stats.n <= ddof) {
              return 0 // No hay suficientes datos
            }
            switch (mode) {
              case 'var':
                return stats.s / (stats.n - ddof)
              case 'stdev':
                return Math.sqrt(stats.s / (stats.n - ddof))
              default:
                throw new Error('unknown mode for runningStat')
            }
          },
          multivalued() {
            // Calcula el valor según el modo para todos los atributos
            const results = {}
            attrs.forEach((attr) => {
              const stats = statsMap[attr]
              if (stats.n === 0) {
                results[attr] = NaN // No hay datos
              } else if (stats.n <= ddof) {
                results[attr] = 0 // No hay suficientes datos
              } else {
                switch (mode) {
                  case 'mean':
                    results[attr] = stats.m
                    break
                  case 'var':
                    results[attr] = stats.s / (stats.n - ddof)
                    break
                  case 'stdev':
                    results[attr] = Math.sqrt(stats.s / (stats.n - ddof))
                    break
                  default:
                    throw new Error('unknown mode for runningStat')
                }
              }
            })
            return results
          },
          format: formatter,
          numInputs: attrs.length,
        }
      }
    }
  },
}

aggregatorTemplates.average = (f) => aggregatorTemplates.runningStat('mean', 1, f)
aggregatorTemplates.multiAverage = (f) => aggregatorTemplates.multiRunningStat('mean', 1, f)
aggregatorTemplates.multiMedian = (f) => aggregatorTemplates.multiQuantile(0.5, f)

const aggregatorsTableBase = ((tpl) => ({
  'Suma (Decimales)': tpl.multiSum(usFmt),
  'Suma (Enteros)': tpl.multiSum(usFmtInt),
  'Suma (Miles)': tpl.multiSum(usFmtT),
  'Suma (Millones)': tpl.multiSum(usFmtMM),
  'Count (Enteros)': tpl.multiCount(usFmtInt),
  'Promedio (Decimales)': tpl.multiAverage(usFmt),
  'Promedio (Enteros)': tpl.multiAverage(usFmtInt),
  'Promedio (Miles)': tpl.multiAverage(usFmtT),
  'Promedio (Millones)': tpl.multiAverage(usFmtMM),
  'Mediana (Decimales)': tpl.multiMedian(usFmt),
  'Mediana (Enteros)': tpl.multiMedian(usFmtInt),
  'Mediana (Miles)': tpl.multiMedian(usFmtT),
  'Mediana (Millones)': tpl.multiMedian(usFmtMM),
}))(aggregatorTemplates)

const aggregatorsTablePercentage = ((tpl) => ({
  Average: tpl.average(usFmt),
}))(aggregatorTemplates)

const aggregatorsTablePxQ = ((tpl) => ({
  'Suma (Decimales)': tpl.multiSum(usFmt),
  'Suma (Enteros)': tpl.multiSum(usFmtInt),
  'Suma (Miles)': tpl.multiSum(usFmtT),
  'Suma (Millones)': tpl.multiSum(usFmtMM),
  'Count (Enteros)': tpl.multiCount(usFmtInt),
  'Promedio (Decimales)': tpl.multiAverage(usFmt),
  'Promedio (Enteros)': tpl.multiAverage(usFmtInt),
  'Promedio (Miles)': tpl.multiAverage(usFmtT),
  'Promedio (Millones)': tpl.multiAverage(usFmtMM),
  'Mediana (Decimales)': tpl.multiMedian(usFmt),
  'Mediana (Enteros)': tpl.multiMedian(usFmtInt),
  'Mediana (Miles)': tpl.multiMedian(usFmtT),
  'Mediana (Millones)': tpl.multiMedian(usFmtMM),
}))(aggregatorTemplates)

export {
  usFmt,
  usFmtInt,
  usFmtMMT,
  usFmtPct,
  aggregatorTemplates,
  aggregatorsTableBase,
  aggregatorsTablePercentage,
  aggregatorsTablePxQ,
}
