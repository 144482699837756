import _ from 'lodash'
import React from 'react'
import { Col, Row } from 'antd'
import { ListKPIs, LoadingKPIs } from './components'

import './KPIsGlobalDetail.scss'

const KPIsGlobalDetail = ({
  globalBudgetKpi,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  currencyData,
  isKpiLoading,
}) => {
  return (
    <Row gutter={24}>
      {isKpiLoading && <LoadingKPIs />}
      {!isKpiLoading && !_.isEmpty(globalBudgetKpi[0]) && (
        <Col span={24}>
          <ListKPIs
            globalBudgetKPIS={globalBudgetKpi}
            setDataComments={setDataComments}
            dataComments={dataComments}
            onRefetchComments={onRefetchComments}
            loadingComments={loadingComments}
            currencyData={currencyData}
          />
        </Col>
      )}
    </Row>
  )
}

export default KPIsGlobalDetail
