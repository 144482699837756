import { Space, Steps } from 'antd'
import { Button } from 'modules/core/components'
import { useState } from 'react'
import { ContentA, ContentDim } from './components'
import { useTranslation } from 'react-i18next'
import DimensionDetailDrawer from '../DimensionDetailDrawer/DimensionDetailDrawer'
import SelectDimensionModal from '../SelectDimensionModal/SelectDimensionModal'
import _ from 'lodash'

const StepsCreate = ({
  dataDimensions,
  dataSelected,
  reclassificationAmounts,
  reclassificationData,
  amounts,
  handleConfirm,
  setDataDimensions,
  setReclassificationAmounts,
  setReclassificationData,
  setReclassificationType,
  valuesSelected,
  allDimensionsList,
  reclassificationType,
  setAmounts,
  setDimensionId,
  setShowExitModal,
  setStep,
  current,
  setCurrent,
}) => {
  const [valueIndex, setValueIndex] = useState(null)
  const [showDetailDrawer, setShowDetailDrawer] = useState(false)
  const [showSelectModal, setShowSelectModal] = useState(false)
  const { t } = useTranslation()

  const renderDefaultValue = () => {
    if (valueIndex !== null) {
      if (dataSelected.adjustment_type && dataSelected.adjustment_type !== 'RECLASSIFICATION') {
        return _.keys(dataDimensions[valueIndex]).map((el) => {
          return {
            dimension: parseInt(el),
            dimensionValue: dataDimensions[valueIndex][el],
          }
        })
      } else {
        return _.keys(reclassificationData[reclassificationType][valueIndex]).map((el) => {
          return {
            dimension: parseInt(el),
            dimensionValue: reclassificationData[reclassificationType][valueIndex][el],
          }
        })
      }
    } else {
      return []
    }
  }

  const renderDisabled = () => {
    const amountOrigin = reclassificationAmounts.origin.reduce((acum, data) => {
      _.forEach(data, (el, key) => {
        acum = { ...acum, [key]: !_.isEmpty(acum) && acum[key] ? acum[key] + el : el }
      })
      return acum
    }, {})

    const amountDestination = reclassificationAmounts.destination.reduce((acum, data) => {
      _.forEach(data, (el, key) => {
        acum = { ...acum, [key]: !_.isEmpty(acum) && acum[key] ? acum[key] + el : el }
      })
      return acum
    }, {})

    return _.keys(amountOrigin).some((el) => amountOrigin[el] + amountDestination[el] !== 0)
  }

  const steps = [
    {
      title: 'LABEL_STEP_ONE',
      description: 'PLANNING_SAC_COMBINATION_DIMENSIONS_STEP_DESCRIPTION',
      content: (
        <ContentDim
          dataSelected={dataSelected}
          dataDimensions={dataDimensions}
          reclassificationData={reclassificationData}
          setReclassificationType={setReclassificationType}
          setReclassificationData={setReclassificationData}
          setDataDimensions={setDataDimensions}
          allDimensionsList={allDimensionsList}
          valuesSelected={valuesSelected}
          setShowSelectModal={setShowSelectModal}
          setValueIndex={setValueIndex}
        />
      ),
    },
    {
      title: 'LABEL_STEP_TWO',
      description: 'PLANNING_SAC_CHOICE_AMOUNTS_STEP_DESCRIPTION',
      content: (
        <ContentA
          dataSelected={dataSelected}
          dataDimensions={dataDimensions}
          amounts={amounts}
          setAmounts={setAmounts}
          reclassificationData={reclassificationData}
          reclassificationAmounts={reclassificationAmounts}
          setReclassificationAmounts={setReclassificationAmounts}
          onClickDetail={(index, type) => {
            setShowDetailDrawer(true)
            setValueIndex(index)
            setReclassificationType(type)
          }}
          compareValueDates={renderDisabled()}
        />
      ),
    },
  ]

  return (
    <>
      <Steps size="small" current={current}>
        {steps.map((step) => (
          <Steps.Step key={step.key} title={t(step.title)} description={t(step.description)} />
        ))}
      </Steps>
      <div>{steps[current].content}</div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <Space direction="horizontal">
          {current < steps.length - 1 && (
            <Button.Primary
              disabled={
                (_.compact(dataDimensions.map((el) => _.isEmpty(el))).length > 0 &&
                  (_.compact(reclassificationData.origin.map((el) => _.isEmpty(el))).length > 0 ||
                    _.compact(reclassificationData.destination.map((el) => _.isEmpty(el))).length >
                      0)) ||
                !dataSelected.area ||
                !dataSelected.currency ||
                !dataSelected.adjustment_type
              }
              title="ACTION_NEXT"
              onClick={() => setCurrent((prevState) => prevState + 1)}
            />
          )}
          {current > 0 && (
            <Button.Default
              title="ACTION_PREVIOUS"
              onClick={() => {
                if (
                  reclassificationAmounts.destination.some(
                    (el) => _.compact(_.keys(el).map((date) => el[date] !== 0)).length > 0,
                  ) ||
                  reclassificationAmounts.origin.some(
                    (el) => _.compact(_.keys(el).map((date) => el[date] !== 0)).length > 0,
                  ) ||
                  amounts.some(
                    (el) => _.compact(_.keys(el).map((date) => el[date] !== 0)).length > 0,
                  )
                ) {
                  setShowExitModal(true)
                  setStep('anterior')
                } else {
                  setCurrent((prevState) => prevState - 1)
                }
              }}
            />
          )}
          {current === steps.length - 1 && (
            <Button.Primary
              title="ACTION_REQUEST"
              disabled={
                !dataSelected.area ||
                !dataSelected.currency ||
                !dataSelected.adjustment_type ||
                reclassificationAmounts.destination.some(
                  (el) =>
                    _.compact(_.keys(el).map((date) => Math.sign(el[date]) !== 1 && el[date] !== 0))
                      .length > 0,
                ) ||
                reclassificationAmounts.origin.some(
                  (el) =>
                    _.compact(
                      _.keys(el).map((date) => Math.sign(el[date]) !== -1 && el[date] !== 0),
                    ).length > 0,
                ) ||
                ((reclassificationAmounts.destination.some(
                  (el) => _.compact(_.keys(el).map((date) => el[date] !== 0)).length === 0,
                ) ||
                  reclassificationAmounts.origin.some(
                    (el) => _.compact(_.keys(el).map((date) => el[date] !== 0)).length === 0,
                  )) &&
                  amounts.some(
                    (el) => _.compact(_.keys(el).map((date) => el[date] !== 0)).length === 0,
                  )) ||
                (dataSelected.adjustment_type === 'REDUCTION' &&
                  amounts.some(
                    (el) =>
                      _.compact(
                        _.keys(el).map((date) => Math.sign(el[date]) !== -1 && el[date] !== 0),
                      ).length > 0,
                  )) ||
                (dataSelected.adjustment_type === 'EXPANSION' &&
                  amounts.some(
                    (el) =>
                      _.compact(
                        _.keys(el).map((date) => Math.sign(el[date]) !== 1 && el[date] !== 0),
                      ).length > 0,
                  )) ||
                (dataSelected.adjustment_type === 'RECLASSIFICATION' && renderDisabled())
              }
              onClick={handleConfirm}
            />
          )}
        </Space>
      </div>
      <SelectDimensionModal
        visible={showSelectModal}
        onClose={() => {
          setShowSelectModal(false)
          setValueIndex(null)
        }}
        setDataDimensions={(data) => {
          dataSelected.adjustment_type && dataSelected.adjustment_type === 'RECLASSIFICATION'
            ? setReclassificationData({
                ...reclassificationData,
                [reclassificationType]: reclassificationData[reclassificationType].map(
                  (state, indexState) => {
                    if (valueIndex === indexState) {
                      return {
                        ...state,
                        ...data,
                      }
                    }
                    return state
                  },
                ),
              })
            : setDataDimensions((prevState) =>
                prevState.map((state, indexState) => {
                  if (valueIndex === indexState) {
                    return {
                      ...state,
                      ...data,
                    }
                  }
                  return state
                }),
              )
        }}
        setDimensionId={setDimensionId}
        valuesSelected={valuesSelected}
        allDimensionsList={allDimensionsList}
        defaultValue={renderDefaultValue()}
      />
      <DimensionDetailDrawer
        visible={showDetailDrawer}
        onClose={() => {
          setShowDetailDrawer(false)
          setValueIndex(null)
          setReclassificationType('origin')
        }}
        dataDimensions={
          dataSelected.adjustment_type && dataSelected.adjustment_type === 'RECLASSIFICATION'
            ? reclassificationData[reclassificationType][valueIndex]
            : dataDimensions[valueIndex]
        }
        allDimensionsList={allDimensionsList}
        valuesSelected={valuesSelected}
      />
    </>
  )
}

export default StepsCreate
