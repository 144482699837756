import { Col, Form, Input, message, Modal, notification, Row } from 'antd'
import configuration from 'modules/configuration'
import { generateMessageError } from 'modules/core/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

const AreaCreateModal = ({ visible, onClose, onRefetch, createArea }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onCancel = () => {
    form.resetFields()
    onClose()
  }

  const onConfirm = () => {
    form.validateFields().then((values) => {
      setIsSaving(true)
      createArea(values)
        .then(() =>
          onRefetch().then(() => {
            message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
            setIsSaving(false)
            onCancel()
          }),
        )
        .catch((error) => {
          setIsSaving(false)
          onCancel()
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: generateMessageError(error),
            duration: 0,
          })
        })
    })
  }

  return (
    <Modal
      title={t('PLANNING_SAC_CREATE_AREA_ACTION_MODAL_TITLE')}
      visible={visible}
      onClose={onCancel}
      onOk={onConfirm}
      okText={t('ACTION_CREATE')}
      okButtonProps={{ loading: isSaving }}
      cancelButtonProps={{ disabled: isSaving }}
      centered
      forceRender
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item
              label={t('FIELD_NAME')}
              name="name"
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Input placeholder={t('ENTER_NAME_PLACEHOLDER')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('FIELD_CODE')}
              name="code"
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Input placeholder={t('ENTER_CODE_PLACEHOLDER')} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = {
  createArea: configuration.actions.createArea,
}

export default connect(null, mapDispatchToProps)(AreaCreateModal)
