import { Col, Space, Table } from 'antd'
import { Button, Typography } from 'modules/core/components'
import { LeftCircleOutlined } from '@ant-design/icons'
import { calculateScrollX } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

const GlobalBudgetSACDetail = ({ applicationDetail, detailLoading, onReturn }) => {
  const { t } = useTranslation()

  const columnsDetail = () => {
    let column = []
    applicationDetail.forEach((col) => {
      column = _.compact(
        _.keys(col).map((el) => {
          return {
            dataIndex: el,
            title: el,
            width: 100,
          }
        }),
      )
    })
    return column
  }

  return (
    <>
      <Col span={24}>
        <Space direction="horizontal">
          <Button.Icon
            title="ACTION_RETURN"
            icon={<LeftCircleOutlined style={{ fontSize: 20, color: '#0047ba' }} />}
            onClick={() => onReturn()}
          />
          <Typography.Body>{t('PLANNING_SAC_RETURN_APPLICATIONS_LIST_TYPOGRAPHY')}</Typography.Body>
        </Space>
      </Col>
      <Col span={24}>
        <Table
          size="small"
          columns={columnsDetail()}
          loading={detailLoading}
          dataSource={applicationDetail}
          scroll={{ x: calculateScrollX(columnsDetail()) }}
        />
      </Col>
    </>
  )
}

export default GlobalBudgetSACDetail
