import { DIMENSIONS } from 'modules/core/constants'
import Immutable from 'seamless-immutable'
import * as types from './constants'
import _ from 'lodash'

const setDimensionURL = (id) => `/dimensions/filter/dimensions_value_available/?id_dimension=${id}`

const initialDisplaySettings = {
  id: null,
  type_view_data: null,
}

const defaultModifiersValues = [
  { name: 'LABEL_BUSINESS_RULES', key: 'rules' },
  { name: 'LABEL_CURRENCY', key: 'currency' },
]

const initialDataRuleSteps = [
  {
    key: 'origin',
    title: 'CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_LABEL_ORIGIN',
  },
  {
    key: 'formula',
    title: 'LABEL_FORMULA',
  },
  {
    key: 'destination',
    title: 'CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_LABEL_DESTINATION',
  },
]

const initialRoleDimensions = {
  [DIMENSIONS.REPORTS.id]: {
    label: DIMENSIONS.REPORTS.label,
    url: DIMENSIONS.REPORTS.url,
    values: [],
  },
  // [DIMENSIONS.REPORTS_CUSTOM.id]: {
  //   label: DIMENSIONS.REPORTS_CUSTOM.label,
  //   url: DIMENSIONS.REPORTS_CUSTOM.url,
  //   values: [],
  // },
}

const initialColumnsRows = [
  {
    title: 'Filas',
    id: 'rows',
    extraOptios: [],
    options: [],
  },
  {
    title: 'Columnas',
    id: 'columns',
    extraOptios: [
      {
        id: 'date',
        name: 'Fecha',
      },
      {
        id: 'pxq',
        name: 'PxQ',
      },
      {
        id: 'p',
        name: 'P',
      },
      {
        id: 'q',
        name: 'Q',
      },
    ],
    options: [],
  },
]

const initialState = Immutable({
  dimensionPlikaList: [],
  dimensionsList: {
    count: 0,
    values: [],
  },
  dimensionDetail: {},
  dimensionValues: {
    count: 0,
    values: [],
  },
  valuesDimension: {
    count: 0,
    values: [],
  },
  dimensionValuesParents: {
    count: 0,
    valuesParents: [],
  },
  BudgetTypeList: {
    count: 0,
    values: [],
  },
  usersList: {
    count: 0,
    values: [],
  },
  userRoles: [],
  userDetail: {},
  variablesList: {
    count: 0,
    values: [],
  },
  rulesList: { count: 0, values: [] },
  dimensionsSelection: { origin_dimensions: [{}], target_dimensions: [{}], default: [{}] },
  dataFormula: { formula_type: 'individual', formula: null, variablesIds: {}, status: false },
  rowsDimensions: { origin_dimensions: [], target_dimensions: [], default: [] },
  dataRuleSteps: initialDataRuleSteps,
  formulaDetail: {},
  modifiersList: {
    count: 0,
    values: defaultModifiersValues,
  },
  factoresList: {},
  displaySettings: initialDisplaySettings,
  otherReportsList: {
    count: 0,
    data: [],
  },
  otherReportsStructure: {},
  rolesList: {
    count: 0,
    data: [],
  },
  roleOperationsKeys: [],
  roleDimensions: initialRoleDimensions,
  factoresBehaviours: [],
  currencyList: {
    count: 0,
    data: [],
  },
  onboardingSteps: {},
  dataColumnsRows: initialColumnsRows,
  columnsRowsSelections: {
    columns: [],
    rows: [],
  },
  allDimensionsList: [],
  allDimensionsValues: [],
  exchangeRateList: [],
  notificationsList: {
    count: 0,
    values: [],
    next: null,
    unread_count: 0,
  },
  showAllComments: false,
  elementRefId: null,
  hasComment: { card: {}, table: false, chart: {} },
  commentsList: [],
  allDimensionsValuesBudget: [],
  areaList: [],
  areaDetail: {},
  levelsList: [],
})

const createRowsDimensions = (dimensions, index = 0) => {
  return dimensions.reduce((acum, data) => {
    return {
      ...acum,
      id: index,
      [data.name_destination]: { id: data.id, name: data.name_destination, dataValueSelect: null },
    }
  }, {})
}

const setOriginAndDestinationDimension = (keys, dimState) => {
  return [
    keys.reduce((acum, key) => {
      return {
        ...acum,
        id: dimState[0]['id'],
        ...(key !== 'id' && {
          [key]: { ...dimState[0][key], dataValueSelect: null },
        }),
      }
    }, {}),
  ]
}

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_DIMENSION_PLIKA_SUCCESS:
      return state.merge({
        dimensionPlikaList: action.payload.data,
      })

    case types.FETCH_DIMENSIONS_LIST_SUCCESS:
      return state.merge({
        dimensionsList: {
          count: action.payload.data.count,
          values: action.payload.data.results,
        },
      })

    case types.FETCH_ALL_DIMENSIONS_SUCCESS:
      return state.merge({
        allDimensionsList: action.payload.data,
      })

    case types.FETCH_ALL_DIMENSIONS_VALUES_SUCCESS:
      return state.merge({
        allDimensionsValues: action.payload.data,
      })

    case types.FETCH_DIMENSION_VALUES_SUCCESS:
      return state.merge({
        dimensionValues: {
          count: action.payload.data.count,
          values: action.payload.data.results,
        },
      })

    case types.FETCH_VALUES_DIMENSION_SUCCESS:
      return state.merge({
        valuesDimension: {
          count: action.payload.data.count,
          values: action.payload.data.results,
        },
      })

    case types.FETCH_DIMENSION_VALUES_PARENTS_SUCCESS:
      return state.merge({
        dimensionValuesParents: {
          count: action.payload.data.count,
          valuesParents: action.payload.data.results,
        },
      })

    case types.FETCH_DIMENSION_DETAIL_SUCCESS:
      return state.merge({
        dimensionDetail: action.payload.data,
      })

    case types.FETCH_BUDGET_TYPE_LIST_SUCCESS:
      return state.merge({
        budgetTypeList: {
          count: action.payload.data.concepts.length,
          values: action.payload.data.concepts,
        },
      })

    case types.FETCH_USERS_LIST_SUCCESS:
      return state.merge({
        usersList: {
          count: action.payload.data.length,
          values: action.payload.data,
        },
      })

    case types.FETCH_USER_DETAIL_SUCCESS:
      return state.merge({
        userDetail: action.payload.data,
      })

    case types.FETCH_USER_ROLES_SUCCESS:
      return state.merge({
        userRoles: action.payload.data,
      })

    case types.FETCH_DISPLAY_SETTINGS_SUCCESS:
      return state.merge({
        displaySettings: action.payload.data ? action.payload.data : initialDisplaySettings,
      })

    case types.FETCH_VARIABLES_LIST_SUCCESS:
      return state.merge({
        variablesList: {
          count: action.payload.data.length,
          values: action.payload.data,
        },
      })

    case types.FETCH_VARIABLE_DETAIL_SUCCESS:
      return state.merge({
        variableDetail: action.payload.data,
      })

    case types.FETCH_VARIABLES_KPI_SUCCESS:
      return state.merge({
        variablesKpis: action.payload.data.kpi,
      })

    case types.SET_VARIABLES_VALUES:
      const { ruleId, variableId, dataVariable } = action.payload
      return state.merge({
        rulesList: {
          ...state.rulesList,
          values: state.rulesList.values.map((rule) => {
            if (ruleId === rule.id) {
              return {
                ...rule,
                variables: rule.variables.map((v) => {
                  if (variableId === v.id) {
                    return {
                      ...v,
                      ...dataVariable,
                    }
                  }
                  return v
                }),
              }
            }
            return rule
          }),
        },
      })

    case types.FETCH_BEHAVIOUR_LIST_SUCCESS:
      return state.merge({
        behaviourList: action.payload.data.results,
      })
    case types.FETCH_RULES_LIST_SUCCESS:
      return state.merge({
        rulesList: {
          count: action.payload.data.count,
          values: action.payload.data.results.map((data) => {
            return {
              ...data,
              variables: data.variables.map((variable) => {
                return {
                  ...variable,
                  originValues: variable.values,
                }
              }),
            }
          }),
        },
      })

    case types.SET_ROWS_DIMENSIONS:
      return state.merge({
        rowsDimensions: {
          origin_dimensions: [createRowsDimensions(state.allDimensionsList)],
          target_dimensions: [createRowsDimensions(state.allDimensionsList)],
          default: [createRowsDimensions(state.allDimensionsList)],
        },
      })

    case types.SET_DIMENSIONS_SELECTION:
      let { key, index, data, dimensionName, dataValueSelect, dimensionValues } = action.payload
      let aux = state.dimensionsSelection[key].asMutable()
      let rowAux = state.rowsDimensions[key].asMutable()

      aux[index] = { ...aux[index], ...data }
      rowAux[index] = {
        ...rowAux[index],
        [dimensionName]: { ...rowAux[index][dimensionName], dataValueSelect, dimensionValues },
      }
      return state.merge({
        dimensionsSelection: {
          ...state.dimensionsSelection,
          [key]: [...aux],
        },
        rowsDimensions: {
          ...state.rowsDimensions,
          [key]: [...rowAux],
        },
      })

    case types.DELETE_DIMENSIONS_SELECTION:
      const auxSelections = state.dimensionsSelection[action.payload.key].asMutable()
      const auxRows = state.rowsDimensions[action.payload.key].asMutable()
      auxSelections.splice(action.payload.index, 1)
      auxRows.splice(action.payload.index, 1)

      return state.merge({
        dimensionsSelection: {
          ...state.dimensionsSelection,
          [action.payload.key]: auxSelections,
        },
        rowsDimensions: {
          ...state.rowsDimensions,
          [action.payload.key]: auxRows,
        },
      })

    case types.ADD_DIMENSIONS_SELECTION:
      return state.merge({
        dimensionsSelection: {
          ...state.dimensionsSelection,
          [action.payload.key]: [...state.dimensionsSelection[action.payload.key], {}],
        },
        rowsDimensions: {
          ...state.rowsDimensions,
          [action.payload.key]: [
            ...state.rowsDimensions[action.payload.key],
            createRowsDimensions(
              state.allDimensionsList,
              state.rowsDimensions[action.payload.key][
                state.rowsDimensions[action.payload.key].length - 1
              ].id + 1,
            ),
          ],
        },
      })

    case types.SET_DATA_FORMULA:
      return state.merge({
        dataFormula: { ...state.dataFormula, ...action.payload },
      })

    case types.SET_CLEAR_DATA:
      const keysOrigin = _.keys(state.rowsDimensions.origin_dimensions[0])
      const keysDestination = _.keys(state.rowsDimensions.target_dimensions[0])
      return state.merge({
        dimensionsSelection: { origin_dimensions: [{}], target_dimensions: [{}] },
        dataFormula: { formula_type: 'individual', formula: null, variablesIds: {}, status: false },
        rowsDimensions: {
          ...state.rowsDimensions,
          origin_dimensions: [
            ...setOriginAndDestinationDimension(keysOrigin, state.rowsDimensions.origin_dimensions),
          ],
          target_dimensions: [
            ...setOriginAndDestinationDimension(
              keysDestination,
              state.rowsDimensions.target_dimensions,
            ),
          ],
        },
      })

    case types.FETCH_FORMULA_DETAIL_SUCCESS:
      return state.merge({
        formulaDetail: action.payload.data,
      })
    case types.FETCH_FACTORES_LIST_SUCCESS:
      return state.merge({
        factoresList: action.payload.data,
      })

    case types.FETCH_OTHER_REPORT_LIST_SUCCESS:
      return state.merge({
        otherReportsList: {
          count: action.payload.data.count,
          data: action.payload.data.results,
        },
      })

    case types.FETCH_OTHER_REPORT_STRUCTURE_SUCCESS:
      return state.merge({
        otherReportsStructure: action.payload.data,
      })

    case types.FETCH_ROLES_LIST_SUCCESS:
      return state.merge({
        rolesList: {
          count: action.payload.data.length,
          data: action.payload.data,
        },
      })

    case types.EDIT_ROLE_OPERATIONS:
      const dataDimensions = action.payload.dimensions.filter((el) => el.type === 'dimension')
      const dataReports = action.payload.dimensions.filter((el) => el.type === 'report')
      return state.merge({
        roleOperationsKeys: action.payload.checked
          ? _.union(state.roleOperationsKeys, action.payload.operationsKeys)
          : _.difference(state.roleOperationsKeys, action.payload.operationsKeys),
        roleDimensions: {
          ...state.roleDimensions,
          ...dataDimensions.reduce((roleDims, el) => {
            const auxValues =
              !el.all_selected && el.dimension_values.length > 0
                ? el.dimension_values.map((dim) => dim.dim_value_id.toString())
                : []
            return {
              ...roleDims,
              [el.table_id]: {
                ...state.roleDimensions[el.table_id],
                values: auxValues,
                all_selected: el.all_selected,
              },
            }
          }, {}),
          [DIMENSIONS.REPORTS.id]: {
            ...state.roleDimensions[DIMENSIONS.REPORTS.id],
            values: dataReports.map((el) => el.table_id.toString()),
          },
        },
      })

    case types.FETCH_ROLE_DIMENSIONS_LIST_SUCCESS:
      return state.merge({
        roleDimensions: action.payload.data.reduce((allDimensions, el) => {
          const existingDimension = state.roleDimensions[el.id]
          return {
            ...state.roleDimensions,
            ...allDimensions,
            [el.id]: {
              ...existingDimension,
              label: el.name_destination,
              url: setDimensionURL(el.id),
              values:
                existingDimension && existingDimension.values.length > 0
                  ? existingDimension.values
                  : [],
            },
          }
        }, {}),
      })

    case types.SELECT_ROLE_DIMENSION:
      return state.merge({
        roleDimensions: {
          ...state.roleDimensions,
          [action.payload.data.dimensionId]: {
            ...state.roleDimensions[action.payload.data.dimensionId],
            values: action.payload.data.values,
            all_selected: action.payload.data.all_selected,
          },
        },
      })

    case types.CLEAN_ROLE_DIMENSION:
      return state.merge({
        roleDimensions: _.reduce(
          state.roleDimensions,
          (allDimensions, el, key) => {
            return {
              ...allDimensions,
              [key]: {
                ...el,
                values: [],
              },
            }
          },
          {},
        ),
      })

    case types.FETCH_FACTORES_BEHAVIOURS_SUCCESS:
      return state.merge({
        factoresBehaviours: action.payload.data.results,
      })

    case types.FETCH_CURRENCY_LIST_SUCCESS:
      return state.merge({
        currencyList: {
          count: action.payload.data.length,
          data: action.payload.data,
        },
      })

    case types.FETCH_ONBOARDING_STEPS_SUCCESS:
      return state.merge({
        onboardingSteps: {
          config: action.payload.data.config.onboarding.config,
          dash: action.payload.data.config.onboarding.dashboard,
        },
      })

    case types.SET_ONBOARDING_STEP: {
      const { paso, key } = action.payload.request.data
      return state.merge({
        onboardingSteps: {
          ...state.onboardingSteps,
          [key]: {
            ...state.onboardingSteps.key,
            paso,
          },
        },
      })
    }

    case types.FETCH_COLUMNS_AND_ROWS_SELECTIONS_SUCCESS:
      return state.merge({
        columnsRowsSelections: action.payload.data,
        dataColumnsRows: state.dataColumnsRows.map((data) => {
          return {
            ...data,
            options: action.payload.data[data.id],
          }
        }),
      })

    case types.ADD_NEW_COLUMNS_ROWS:
      return state.merge({
        dataColumnsRows: state.dataColumnsRows.map((data) => {
          if (data.id === action.payload.id) {
            return {
              ...data,
              options: [
                ...data.options,
                { id: action.payload.row.value, name: action.payload.row.label },
              ],
            }
          }
          return data
        }),
      })

    case types.SET_DATA_COLUMNS_ROWS:
      const auxDataColumnsRows = state.dataColumnsRows.asMutable()
      return state.merge({
        dataColumnsRows: auxDataColumnsRows.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              options: action.payload.options,
            }
          }
          return item
        }),
      })

    case types.DELETE_COLUMNS_ROWS:
      return state.merge({
        dataColumnsRows: state.dataColumnsRows.map((data) => {
          if (data.id === action.payload.id) {
            const indexItem = data.options.findIndex((el) => el.id === action.payload.tagId)
            const auxOptions = data.options.asMutable()
            auxOptions.splice(indexItem, 1)
            return {
              ...data,
              options: auxOptions,
            }
          }
          return data
        }),
      })

    case types.FETCH_EXCHANGE_RATE_LIST_SUCCESS:
      return state.merge({
        exchangeRateList: action.payload.data.map((data) => {
          return {
            ...data,
            originValues: data.date_rate_period,
          }
        }),
      })

    case types.SET_EXCHANGE_RATE_VALUES:
      return state.merge({
        exchangeRateList: state.exchangeRateList.map((data) => {
          if (data.destination_currency.id === action.payload.id)
            return {
              ...data,
              date_rate_period: action.payload.data,
            }
          return data
        }),
      })
    case types.SET_PIN:
      return state.merge({
        currencyList: {
          ...state.currencyList,
          data: state.currencyList.data.map((data) => {
            if (data.id === action.payload.id) {
              return {
                ...data,
                is_default: action.payload.is_default,
              }
            }
            return {
              ...data,
              is_default: false,
            }
          }),
        },
      })

    case types.FETCH_NOTIFICATIONS_LIST_SUCCESS:
      const page = action.payload.config.params.page
      const values =
        page === 1
          ? action.payload.data.results
          : [...state.notificationsList.values, ...action.payload.data.results]
      return state.merge({
        notificationsList: {
          count: action.payload.data.count,
          values,
          next: action.payload.data.next,
          unread_count: action.payload.data.unread_count,
        },
      })

    case types.SET_SHOW_ALL_COMMENTS:
      return state.merge({
        showAllComments: action.payload,
      })

    case types.SET_HAS_COMMENT:
      return state.merge({
        hasComment: {
          ...state.hasComment,
          ...(action.payload.type === 'card' || action.payload.type === 'chart'
            ? {
                [action.payload.type]: {
                  ...state.hasComment[action.payload.type],
                  [action.payload.key]: {
                    ...state.hasComment[action.payload.type][action.payload.key],
                    [action.payload.id]: action.payload.data,
                  },
                },
              }
            : {
                [action.payload.type]: action.payload.data,
              }),
        },
      })

    case types.FETCH_COMMENTS_LIST_SUCCESS:
      return state.merge({
        commentsList: action.payload.data.results,
      })

    case types.SET_NEW_COMMENT:
      return state.merge({
        commentsList: [
          {
            text: '',
            key: '0',
            parent: null,
            replies: [],
          },
          ...state.commentsList,
        ],
      })

    case types.REMOVE_NEW_COMMENT:
      const list = state.commentsList.filter((el) => el.text)
      return state.merge({
        commentsList: list,
      })

    case types.SET_NEW_NOTIFICATION:
      return state.merge({
        notificationsList: {
          ...state.notificationsList,
          unread_count: state.notificationsList.unread_count + 1,
          values: [action.payload, ...state.notificationsList.values],
        },
      })

    case types.SET_COUNTER:
      return state.merge({
        notificationsList: {
          ...state.notificationsList,
          unread_count: state.notificationsList.unread_count - 1,
        },
      })

    case types.FETCH_ALL_DIMENSIONS_VALUES_BUDGET_SUCCESS:
      return state.merge({
        allDimensionsValuesBudget: action.payload.data,
      })

    case types.FETCH_AREAS_LIST_SUCCESS:
      return state.merge({
        areaList: action.payload.data,
      })
    case types.FETCH_AREA_DETAIL_SUCCESS:
      return state.merge({
        areaDetail: action.payload.data,
      })

    case types.FETCH_LEVELS_SUCCESS:
      return state.merge({
        levelsList: action.payload.data,
      })

    default:
      return state
  }
}
