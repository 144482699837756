import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Configuration from './Configuration/Configuration'
import { DimensionsList, DimensionDetail } from './Dimensions'
import UserDetail from './Security/UserDetail/UserDetail'
import { ModificadoresHome, RuleDetail } from './Modificadores'
import SecurityHome from './Security/SecurityHome/SecurityHome'
import { BudgetTypeList } from './BudgetType'
import { ReportDetail, ReportsList } from './Reports'
import IntegrationsHome from './Integrations/IntegrationsHome/IntegrationsHome'
import { ProjectionTrackingScreen } from 'modules/core/components'
import AreaDetail from './Security/AreaDetail/AreaDetail'

const Home = () => (
  <Switch>
    <Route exact path="/configuracion" component={Configuration} />
    <Route exact path="/configuracion/seguridad" component={SecurityHome} />
    <Route exact path="/configuracion/dimensiones" component={DimensionsList} />
    <Route exact path="/configuracion/dimensiones/:dimensionId" component={DimensionDetail} />
    <Route exact path="/configuracion/tipodepresupuesto" component={BudgetTypeList} />
    <Route exact path="/configuracion/usuarios/:userId/" component={UserDetail} />
    <Route
      exact
      path="/configuracion/usuarios/:userId/:myProfile"
      component={UserDetail} // TODO:  Reemplazar por componente Perfil cuando se desarolle
    />
    <Route exact path="/configuracion/reportes" component={ReportsList} />
    <Route exact path="/configuracion/reportes/:reportId" component={ReportDetail} />
    <Route exact path="/configuracion/herramientas/" component={ModificadoresHome} />
    <Route
      exact
      path="/configuracion/herramientas/reglasdenegocio/:ruleId"
      component={RuleDetail}
    />
    <Route exact path="/configuracion/integraciones/" component={IntegrationsHome} />
    <Route exact path="/configuracion/actividad" component={ProjectionTrackingScreen} />
    <Route
      exact
      path="/configuracion/reportes/:reportId/actividad"
      component={ProjectionTrackingScreen}
    />
    <Route
      exact
      path="/configuracion/dimensiones/:dimensionId/actividad"
      component={ProjectionTrackingScreen}
    />
    <Route exact path="/configuracion/areas/:areaId" component={AreaDetail} />
    <Redirect to="/configuracion" />
  </Switch>
)

export default Home
