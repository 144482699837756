import { NAME } from './constants'

export const getSocietiesList = (state) => state[NAME].societiesList
export const getSocietiesDetail = (state) => state[NAME].societiesDetail

export const getFunctionsButtonsList = (state) => state[NAME].functionsButtons
export const getFunctionsButtonsTenant = (state) => state[NAME].societyFunctions
export const getButtonsStandar = (state) => state[NAME].buttonsStandar
export const getSocietiesDimensions = (state) => state[NAME].societiesDimensions
export const getQuicksightList = (state) => state[NAME].quicksightList
