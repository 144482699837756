import { Col, Form, Modal, Row, Select } from 'antd'
import { AddAndDeleteFormItem } from 'modules/core/components'
import { generateSelectOptions, handleSelectAll } from 'modules/core/utils'
import { handleSetFormListValues } from '../../utils'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import configuration from 'modules/configuration'
import _ from 'lodash'

const FilterDataCreateModal = ({
  visible,
  onClose,
  fetchAllDimensions,
  allDimensionsList,
  setfilterData,
  index,
  filterData,
  type,
  projectionId,
  params = {},
  fetchAllDimensionsValuesBudget,
}) => {
  const [dimensionId, setDimensionId] = useState(null)
  const [selectedDimensions, setSelectedDimensions] = useState([])
  const [areAllSelected, setAreAllSelected] = useState(false)
  const [valuesSelected, setValuesSelected] = useState({})
  const [form] = Form.useForm()
  const { t } = useTranslation()

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        dimension_list: !_.isEmpty(filterData) && filterData[index] ? filterData[index] : [''],
      })
    }
  }, [filterData, form, index, visible])

  useEffect(() => {
    if (visible) {
      fetchAllDimensions()
    }
  }, [fetchAllDimensions, visible])

  useEffect(() => {
    if (dimensionId && type && projectionId) {
      fetchAllDimensionsValuesBudget({
        id_dimension: dimensionId,
        type_budget: type,
        id_budget: projectionId,
        ...params,
      }).then((response) => {
        setValuesSelected((prevState) => {
          return { ...prevState, [dimensionId]: response.payload.data }
        })
      })
    }
  }, [fetchAllDimensionsValuesBudget, dimensionId, type, projectionId])

  const generateOptions = (index) => {
    if (
      dimensionId &&
      _.keys(valuesSelected).length > 0 &&
      form.getFieldValue('dimension_list')[index] &&
      valuesSelected[form.getFieldValue('dimension_list')[index].dimension_id]
    ) {
      return valuesSelected[form.getFieldValue('dimension_list')[index].dimension_id]
    }

    return []
  }

  const onCancel = () => {
    form.resetFields()
    onClose()
  }

  const handleConfirm = () => {
    form.validateFields().then((values) => {
      setfilterData((prevState) => {
        return { ...prevState, ...{ [index]: values.dimension_list } }
      })
      onCancel()
    })
  }

  return (
    <Modal
      title={t('ACTION_ADD_SEE_FILTER')}
      visible={visible}
      onCancel={onCancel}
      okText={t('ACTION_SAVE')}
      onOk={handleConfirm}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      centered
      destroyOnClose
      forceRender
    >
      <Form form={form} layout="vertical" initialValues={{ dimension_list: [''] }}>
        <Form.List name="dimension_list">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index2) => {
                  return (
                    <Row gutter={24} key={field.key} justify="end">
                      <Col span={12}>
                        <Form.Item name={[field.name, 'dimension_id']}>
                          <Select
                            placeholder={t('FIELD_DIMENSION')}
                            onChange={(id) => {
                              setDimensionId(id)
                              // Se limpia el campo de dimensionValue
                              const fields = form.getFieldsValue()
                              const { dimension_list } = fields
                              Object.assign(dimension_list[field.name], {
                                values: undefined,
                              })
                              form.setFieldsValue({ dimension_list })
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={generateSelectOptions({
                              options: allDimensionsList.map((dim) => {
                                return { id: dim.id, name: dim.name_destination }
                              }),
                              selection: _.compact(
                                form
                                  .getFieldValue('dimension_list')
                                  .map((el) =>
                                    el && el.dimension_id ? el?.dimension_id.toString() : null,
                                  ),
                              ),
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name={[field.name, 'values']}>
                          <Select
                            placeholder={t('FIELD_DIMENSION_VALUES')}
                            mode="multiple"
                            showArrow
                            allowClear
                            showSearch
                            maxTagCount={1}
                            maxTagTextLength={10}
                            onChange={(values) => {
                              handleSelectAll({
                                values,
                                allValues: generateOptions(field.name).map(
                                  (dimValue) => `${dimValue.id}_${dimValue.name_destination}`,
                                ),
                                areAllSelected,
                                onSetValues: (values) =>
                                  handleSetFormListValues(form, values, index2),
                                onSetAreAllSelected: (state) => setAreAllSelected(state),
                              })
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={
                              !dimensionId
                              // ||
                              // (form.getFieldValue('dimension_list')[field.name] &&
                              //   !form.getFieldValue('dimension_list')[field.name]['values'])
                            }
                            options={generateSelectOptions({
                              options: generateOptions(field.name).map((valDim) => {
                                return {
                                  id: valDim.id,
                                  code: valDim.code_destination,
                                  name: valDim.name_destination,
                                }
                              }),
                              includeOptionAll: true,
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <AddAndDeleteFormItem
                        fieldsLength={fields.length}
                        index={index2}
                        addData={{
                          // disabled: isLoading,
                          onClick: () => {
                            add()
                            if (dimensionId) {
                              setSelectedDimensions([...selectedDimensions, dimensionId.toString()])
                            }
                            setDimensionId(null)
                          },
                          buttonName: 'CONFIG_DIMENSIONS_ADD_NEW_ACTION',
                        }}
                        deleteData={{
                          // disabled: isLoading,
                          onClick: () => {
                            if (form.getFieldValue('dimension_list')[field.name]) {
                              const selectedID =
                                form.getFieldValue('dimension_list')[field.name]['dimension_id']
                              setSelectedDimensions(
                                selectedDimensions.filter((id) => id !== selectedID.toString()),
                              )
                            }
                            remove(field.name)
                          },
                          buttonName: 'CONFIG_DIMENSIONS_DELETE_ACTION',
                        }}
                      />
                    </Row>
                  )
                })}
              </div>
            )
          }}
        </Form.List>
      </Form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
  allDimensionsValues: configuration.selectors.getAllDimensionsValues(state),
})

const mapDispatchToProps = {
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  fetchAllDimensionsValuesBudget: configuration.actions.fetchAllDimensionsValuesBudget,
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterDataCreateModal)
