import { ButtonActionBar, DeleteSelectionButton } from 'modules/core/components'
// import { DownloadOutlined } from '@ant-design/icons';

const TableTopActions = ({ dataSelection, onConfirmDelete, isDeletingAll, onCancel }) => {
  return (
    <ButtonActionBar position={dataSelection.length > 0 ? 'space-between' : 'end'}>
      {dataSelection.length > 0 && (
        <DeleteSelectionButton
          onConfirm={onConfirmDelete}
          disabled={false}
          isDeletingAll={isDeletingAll}
          onCancel={onCancel}
        />
      )}
      {/* <Button.Icon 
                title='ACTION_DOWNLOAD_TABLE'
                icon={<Typography.Icon icon={DownloadOutlined} />}
                onClick={() => console.log('descargar tabla')}
            /> */}
    </ButtonActionBar>
  )
}

export default TableTopActions
