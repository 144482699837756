import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'

const ActionConfirmModal = ({ visible, onClose, onConfirm, isSaving, dataRow, usersList }) => {
  const { t } = useTranslation()

  const ADJUSTMENTS_TYPE = {
    EXPANSION: 'LABEL_EXPANSION',
    REDUCTION: 'LABEL_REDUCTION',
    RECLASSIFICATION: 'LABEL_RECLASSIFICATION',
  }

  return (
    <Modal
      title={t('PLANNING_SAC_ACTION_CONFIRM_MODAL_TITLE')}
      visible={visible}
      onCancel={onClose}
      okText={t('ACTION_CONFIRM')}
      onOk={onConfirm}
      okButtonProps={{ loading: isSaving }}
      cancelButtonProps={{ disabled: isSaving }}
      centered
      destroyOnClose
      forceRender
    >
      {t('PLANNING_SAC_ACTION_CONFIRM_MODAL_BODY_TEXT', {
        adjustmentType: t(ADJUSTMENTS_TYPE[dataRow.adjustment_type]),
        userName: usersList.values.find((el) => el.id === dataRow.user)?.full_name,
        amount: dataRow.total_amount,
      })}
    </Modal>
  )
}

export default ActionConfirmModal
