import { Col, Row, Skeleton, Table } from 'antd'
import React from 'react'

const AdjustmentSkeleton = () => {
  // Define las columnas de la tabla como en la imagen
  const columns = [
    { title: 'Nombre', dataIndex: 'nombre', key: 'nombre' },
    { title: 'Fecha de Inicio', dataIndex: 'fechaInicio', key: 'fechaInicio' },
    { title: 'Fecha de Finalización', dataIndex: 'fechaFin', key: 'fechaFin' },
    { title: 'Acciones', dataIndex: 'acciones', key: 'acciones' },
  ]

  // Datos vacíos simulando la estructura del esqueleto
  const data = Array.from({ length: 4 }).map((_, index) => ({
    key: index,
    nombre: <Skeleton.Input style={{ width: 100 }} active={true} size="small" />,
    fechaInicio: <Skeleton.Input style={{ width: 80 }} active={true} size="small" />,
    fechaFin: <Skeleton.Input style={{ width: 80 }} active={true} size="small" />,
    acciones: (
      <>
        <Skeleton.Button active={true} size="small" style={{ marginRight: 8 }} />
        <Skeleton.Button active={true} size="small" />
      </>
    ),
  }))

  return (
    <Row gutter={[24, 24]} style={{ padding: 40 }}>
      <Col span={24}>
        {/* Skeleton para el mensaje de información */}
        <div
          style={{
            backgroundColor: '#e6f7ff',
            padding: '8px 16px',
            borderRadius: '4px',
            marginBottom: '16px',
          }}
        >
          <Skeleton.Input style={{ width: '80%' }} active={true} size="small" />
        </div>

        {/* Skeleton para el botón de "Crear nuevo" */}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
          <Skeleton.Button style={{ width: 120 }} active={true} />
        </div>
      </Col>

      <Col span={24}>
        <Table columns={columns} dataSource={data} pagination={false} bordered />
      </Col>

      <Col span={24}>
        <div style={{ height: window.innerHeight - 350 }}></div>
      </Col>
    </Row>
  )
}

export default AdjustmentSkeleton
