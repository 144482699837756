import _ from 'lodash'
import React from 'react'
import { aggregatorsTablePxQ } from '../../Aggregators'
import {
  flatKey,
  formatter,
  normalizeName,
  PivotData,
  sortedColHeader,
  usFmt,
  usFmtPct,
} from '../../Utilities'

class TSVExportRenderer extends React.PureComponent {
  hasAccumulative = (arr1) => {
    let subArr1 = _.slice(arr1, 0, 1)
    let subArr2 = ['Proyecciones']
    return _.isEqual(subArr1, subArr2)
  }

  hasMonthly = (arr1) => {
    let subArr1 = _.slice(arr1, 0, 2)
    let subArr2 = ['Fecha', 'Proyecciones']
    return _.isEqual(subArr1, subArr2)
  }

  getBasePivotSettings() {
    const props = this.props
    const colAttrs = props.cols
    const rowAttrs = props.rows

    const tableOptions = Object.assign(
      {
        rowTotals: false,
        colTotals: true,
      },
      props.tableOptions,
    )

    const rowTotals = tableOptions.rowTotals || colAttrs.length === 0
    const colTotals = tableOptions.colTotals || rowAttrs.length === 0

    const subtotalOptions = Object.assign(
      {
        arrowCollapsed: '⨁',
        arrowExpanded: 'ⴱ',
      },
      props.subtotalOptions,
    )

    const colSubtotalDisplay = Object.assign(
      {
        displayOnTop: false,
        enabled: rowTotals,
        hideOnExpand: false,
      },
      subtotalOptions.colSubtotalDisplay,
    )

    const rowSubtotalDisplay = Object.assign(
      {
        displayOnTop: true,
        enabled: colTotals,
        hideOnExpand: false,
      },
      subtotalOptions.rowSubtotalDisplay,
    )

    const pivotData = new PivotData(
      Object.assign({}, props, {
        aggregators: aggregatorsTablePxQ,
        vals: ['amount', 'price', 'quantity'],
      }),
      {
        rowEnabled: rowSubtotalDisplay.enabled,
        colEnabled: colSubtotalDisplay.enabled,
        rowPartialOnTop: rowSubtotalDisplay.displayOnTop,
        colPartialOnTop: colSubtotalDisplay.displayOnTop,
      },
    )

    const rowKeys = pivotData.getRowKeys()
    const colKeys = pivotData.getColKeys()
    // Also pre-calculate all the callbacks for cells, etc... This is nice to have to
    // avoid re-calculations of the call-backs on cell expansions, etc...
    const cellCallbacks = {}
    const rowTotalCallbacks = {}
    const colTotalCallbacks = {}
    let grandTotalCallback = null
    // Custom lines
    const linesOrder = !_.isEmpty(tableOptions?.linesOrder) ? tableOptions.linesOrder : []
    const linesTotal = !_.isEmpty(tableOptions?.linesTotal) ? tableOptions.linesTotal : []
    const linesRatio = !_.isEmpty(tableOptions?.linesRatio) ? tableOptions.linesRatio : []
    const linesRatioN = !_.isEmpty(tableOptions?.linesRatioN) ? tableOptions.linesRatioN : []
    const referenceBase = !_.isEmpty(tableOptions?.referenceBase)
      ? tableOptions.referenceBase
      : null
    const referenceBasePosition = this.hasAccumulative(colAttrs)
      ? 0
      : this.hasMonthly(colAttrs)
      ? 1
      : 0

    return Object.assign({
      pivotData,
      colAttrs,
      rowAttrs,
      colKeys,
      rowKeys,
      rowTotals,
      colTotals,
      arrowCollapsed: subtotalOptions.arrowCollapsed,
      arrowExpanded: subtotalOptions.arrowExpanded,
      colSubtotalDisplay,
      rowSubtotalDisplay,
      cellCallbacks,
      rowTotalCallbacks,
      colTotalCallbacks,
      grandTotalCallback,
      linesOrder,
      linesTotal,
      linesRatio,
      linesRatioN,
      referenceBase,
      referenceBasePosition,
    })
  }

  esInfinity(num) {
    return num === Infinity || num === -Infinity
  }

  getAggregator(pivotData, rowKey, colKey) {
    try {
      const agg = pivotData.getAggregator(rowKey, colKey)
      const aggValue = agg.multivalued()

      const amount = aggValue['amount'] ?? 0
      const quantity = aggValue['quantity'] ?? 0

      return {
        amount: amount,
        price: amount / quantity,
        quantity: quantity,
      }
    } catch (e) {
      return { amount: 0, price: 0, quantity: 0 }
    }
  }

  getAggregatorBuild(pivotData, flatRowKey, colKey) {
    const rowKey = [normalizeName(flatRowKey)]
    return this.getAggregator(pivotData, rowKey, colKey)
  }

  getFormatter(pivotData) {
    let formatterFunction = usFmt
    if (formatter.hasOwnProperty(pivotData.props.aggregatorName)) {
      formatterFunction = formatter[pivotData.props.aggregatorName]
    }
    return formatterFunction
  }

  calculateVariation(valueBase, valueReference, varitaion = 'positive') {
    let percentage_variation = 0.0
    let absolute_variation = this.normalizeNumber(valueBase, varitaion)

    if (valueReference !== 0.0) {
      absolute_variation = this.format(
        this.normalizeNumber(valueBase, varitaion) -
          this.normalizeNumber(valueReference, varitaion),
      )
      if (isNaN(absolute_variation) || this.esInfinity(absolute_variation)) {
        absolute_variation = 0.0
      }

      percentage_variation = this.format(
        this.normalizeNumber(absolute_variation, varitaion) /
          this.normalizeNumber(valueReference, varitaion),
      )

      if (isNaN(percentage_variation) || this.esInfinity(percentage_variation)) {
        percentage_variation = 0.0
      }
    }
    return [Number(absolute_variation), Number(percentage_variation)]
  }

  calculateVariationPXQ(
    valueBase,
    valueReference,
    variant = 'positive',
    valueVariant = 0,
    valueTotal = 0,
  ) {
    let percentage_variation = 0.0
    let absolute_variation = this.normalizeNumber(valueBase, variant)

    if (isNaN(absolute_variation) || this.esInfinity(absolute_variation)) {
      absolute_variation = 0.0
    }

    if (valueReference !== 0.0) {
      absolute_variation =
        this.format(
          this.normalizeNumber(valueBase, variant) - this.normalizeNumber(valueReference, variant),
        ) * this.format(valueVariant)

      if (isNaN(absolute_variation) || this.esInfinity(absolute_variation)) {
        absolute_variation = 0.0
      }

      percentage_variation = this.format(
        this.normalizeNumber(absolute_variation, variant) / this.format(valueTotal),
      )
      if (isNaN(percentage_variation) || this.esInfinity(percentage_variation)) {
        percentage_variation = 0.0
      }
    }

    return [Number(absolute_variation), Number(percentage_variation)]
  }

  format(value) {
    return parseFloat(value.toFixed(8))
  }

  normalizeNumber(value, variation = 'positive') {
    if (!value) {
      return 0
    }
    if (variation === 'negative') {
      value = Math.abs(value)
    }

    return this.format(value)
  }

  renderLinesRow(rowKey, rowIdx, pivotSettings, visibleRowKeys) {
    const { linesOrder, linesTotal, linesRatio, linesRatioN } = pivotSettings
    let isTree = false
    let flatRowKey = flatKey(rowKey)

    if (rowKey.length > 1) {
      flatRowKey = rowKey[0]
      isTree = true
    }
    const line = linesOrder?.find((it) => normalizeName(it.name) === flatRowKey)
    const lineTotal = linesTotal?.find((it) => normalizeName(it.name) === flatRowKey)
    const lineRatio = linesRatio?.find((it) => normalizeName(it.name) === flatRowKey)
    const lineRatioN = linesRatioN?.find((it) => normalizeName(it.name) === flatRowKey)
    const originalRowIdx = visibleRowKeys.findIndex((entry) => flatKey(entry) === flatKey(rowKey))

    if (lineTotal) {
      if (!isTree) {
        return this.renderTableTotalRow(rowKey, rowIdx, pivotSettings, lineTotal, line)
      }
    } else if (lineRatio) {
      if (!isTree) {
        return this.renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatio, usFmtPct, line)
      }
    } else if (lineRatioN) {
      if (!isTree) {
        return this.renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatioN, usFmt, line)
      }
    } else if (originalRowIdx < 0) {
      return this.renderTableLineZeroRow(rowKey, rowIdx, pivotSettings, line)
    } else {
      return this.renderTableRow(rowKey, originalRowIdx, pivotSettings, line)
    }
  }

  renderTableTotalRow(rowKey, rowIdx, pivotSettings, lineTotal, line) {
    const { visibleColKeys, pivotData, referenceBasePosition, referenceBase } = pivotSettings
    const formatter = this.getFormatter(pivotData)
    const variation = line?.calculation ?? 'positive'

    const lines = lineTotal.lines.reduce((mergedLines, it) => {
      return mergedLines.concat(it.lines)
    }, [])

    const attrValueCells = rowKey.map((r) => r)
    const rowLength = pivotData.props.rows.length - 1
    for (let i = 0; i < rowLength; i++) {
      attrValueCells.push('')
    }

    const valueCells = []
    visibleColKeys.forEach((colKey) => {
      const valuesReference = lines.reduce(
        (acc, flatRowKey) => {
          const agg = this.getAggregatorBuild(pivotData, flatRowKey, colKey)
          const a = acc.amount + agg.amount
          const q = acc.quantity + agg.quantity
          const p = a / q

          return { amount: a, price: p, quantity: q }
        },
        { amount: 0, price: 0, quantity: 0 },
      )

      const hasReferenceBase = colKey[referenceBasePosition] === referenceBase

      ;['price', 'quantity'].forEach((flatColKey) => {
        let aggValue = 0
        if (Object.keys(valuesReference).length > 0) {
          aggValue = valuesReference[flatColKey]
        }

        valueCells.push(formatter(aggValue))
      })

      if (!hasReferenceBase) {
        const colKeyReferenceBase = _.cloneDeep(colKey)
        colKeyReferenceBase[referenceBasePosition] = referenceBase

        const valuesBase = lines.reduce(
          (acc, flatRowKey) => {
            const agg = this.getAggregatorBuild(pivotData, flatRowKey, colKeyReferenceBase)
            const a = acc.amount + agg.amount
            const q = acc.quantity + agg.quantity
            const p = a / q

            return { amount: a, price: p, quantity: q }
          },
          { amount: 0, price: 0, quantity: 0 },
        )

        // Values
        const valueBaseA = valuesBase['amount'] ?? 0.0
        const valueBaseP = valuesBase['price'] ?? 0.0
        const valueBaseQ = valuesBase['quantity'] ?? 0.0

        const valueReferenceA = valuesReference['amount'] ?? 0.0
        const valueReferenceP = valuesReference['price'] ?? 0.0
        const valueReferenceQ = valuesReference['quantity'] ?? 0.0

        // Calculate amount
        const [absolute_variation, percentage_variation] = this.calculateVariation(
          valueBaseA,
          valueReferenceA,
          variation,
        )
        valueCells.push(...[formatter(absolute_variation), usFmtPct(percentage_variation)])

        const totalVariant = absolute_variation

        //  Price
        const [absolute_variation_p, percentage_variation_p] = this.calculateVariationPXQ(
          valueBaseP,
          valueReferenceP,
          variation,
          valueReferenceQ,
          totalVariant,
        )

        valueCells.push(...[formatter(absolute_variation_p), usFmtPct(percentage_variation_p)])

        // Calculate quantity
        const [absolute_variation_q, percentage_variation_q] = this.calculateVariationPXQ(
          valueBaseQ,
          valueReferenceQ,
          variation,
          valueBaseP,
          totalVariant,
        )

        valueCells.push(...[formatter(absolute_variation_q), usFmtPct(percentage_variation_q)])
      }
    })

    return [...attrValueCells, ...valueCells]
  }

  renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatio, formatter = usFmt, line) {
    const { visibleColKeys, pivotData, referenceBase, referenceBasePosition } = pivotSettings
    let lines1 = []
    let lines2 = []
    if (!_.isEmpty(lineRatio.lines)) {
      lines1 = lineRatio.lines[0]
      lines2 = lineRatio.lines[1]
    }

    const variation = line?.calculation ?? 'positive'

    const attrValueCells = rowKey.map((r) => r)
    const rowLength = pivotData.props.rows.length - 1
    for (let i = 0; i < rowLength; i++) {
      attrValueCells.push('')
    }

    const valueCells = []
    visibleColKeys.forEach((colKey) => {
      const valuesReference1 = lines1.lines.reduce(
        (acc, flatRowKey) => {
          const agg = this.getAggregatorBuild(pivotData, flatRowKey, colKey)
          const a = acc.amount + agg.amount
          const q = acc.quantity + agg.quantity
          const p = a / q

          return { amount: a, price: p, quantity: q }
        },
        { amount: 0, price: 0, quantity: 0 },
      )

      const valuesReference2 = lines2.lines.reduce(
        (acc, flatRowKey) => {
          const agg = this.getAggregatorBuild(pivotData, flatRowKey, colKey)
          const a = acc.amount + agg.amount
          const q = acc.quantity + agg.quantity
          const p = a / q

          return { amount: a, price: p, quantity: q }
        },
        { amount: 0, price: 0, quantity: 0 },
      )

      const valuesReference = {
        amount:
          valuesReference2['amount'] !== 0.0
            ? valuesReference1['amount'] / valuesReference2['amount']
            : 0.0,
        price:
          valuesReference2['price'] !== 0.0
            ? valuesReference1['price'] / valuesReference2['price']
            : 0.0,
        quantity:
          valuesReference2['quantity'] !== 0.0
            ? valuesReference1['quantity'] / valuesReference2['quantity']
            : 0.0,
      }

      const hasReferenceBase = colKey[referenceBasePosition] === referenceBase

      ;['price', 'quantity'].forEach((flatColKey) => {
        let aggValue = 0
        if (Object.keys(valuesReference).length > 0) {
          aggValue = valuesReference[flatColKey]
        }

        valueCells.push(formatter(aggValue))
      })

      if (!hasReferenceBase) {
        const colKeyReferenceBase = _.cloneDeep(colKey)
        colKeyReferenceBase[referenceBasePosition] = referenceBase

        const valuesBase1 = lines1.lines.reduce(
          (acc, flatRowKey) => {
            const agg = this.getAggregatorBuild(pivotData, flatRowKey, colKeyReferenceBase)
            const a = acc.amount + agg.amount
            const q = acc.quantity + agg.quantity
            const p = a / q

            return { amount: a, price: p, quantity: q }
          },
          { amount: 0.0, price: 0.0, quantity: 0.0 },
        )

        const valuesBase2 = lines2.lines.reduce(
          (acc, flatRowKey) => {
            const agg = this.getAggregatorBuild(pivotData, flatRowKey, colKeyReferenceBase)
            const a = acc.amount + agg.amount
            const q = acc.quantity + agg.quantity
            const p = a / q

            return { amount: a, price: p, quantity: q }
          },
          { amount: 0.0, price: 0.0, quantity: 0.0 },
        )

        const valuesBase = {
          amount:
            valuesBase2['amount'] !== 0.0 ? valuesBase1['amount'] / valuesBase2['amount'] : 0.0,
          price: valuesBase2['price'] !== 0.0 ? valuesBase1['price'] / valuesBase2['price'] : 0.0,
          quantity:
            valuesBase2['quantity'] !== 0.0
              ? valuesBase1['quantity'] / valuesBase2['quantity']
              : 0.0,
        }

        // Values
        const valueBaseA = valuesBase['amount'] ?? 0.0
        const valueBaseP = valuesBase['price'] ?? 0.0
        const valueBaseQ = valuesBase['quantity'] ?? 0.0

        const valueReferenceA = valuesReference['amount'] ?? 0.0
        const valueReferenceP = valuesReference['price'] ?? 0.0
        const valueReferenceQ = valuesReference['quantity'] ?? 0.0

        // Calculate amount
        const [absolute_variation, percentage_variation] = this.calculateVariation(
          valueBaseA,
          valueReferenceA,
          variation,
        )
        valueCells.push(...[formatter(absolute_variation), usFmtPct(percentage_variation)])

        const totalVariant = absolute_variation

        const [absolute_variation_p, percentage_variation_p] = this.calculateVariationPXQ(
          valueBaseP,
          valueReferenceP,
          variation,
          valueReferenceQ,
          totalVariant,
        )

        valueCells.push(...[formatter(absolute_variation_p), usFmtPct(percentage_variation_p)])

        // Calculate quantity
        const [absolute_variation_q, percentage_variation_q] = this.calculateVariationPXQ(
          valueBaseQ,
          valueReferenceQ,
          variation,
          valueBaseP,
          totalVariant,
        )

        valueCells.push(...[formatter(absolute_variation_q), usFmtPct(percentage_variation_q)])
      }
    })

    return [...attrValueCells, ...valueCells]
  }

  renderTableLineZeroRow(rowKey, rowIdx, pivotSettings, line) {
    const { visibleColKeys, pivotData, referenceBasePosition, referenceBase } = pivotSettings
    const formatter = this.getFormatter(pivotData)

    const attrValueCells = rowKey.map((r) => r)
    const rowLength = pivotData.props.rows.length - 1
    for (let i = 0; i < rowLength; i++) {
      attrValueCells.push('')
    }

    const valueCells = []
    visibleColKeys.forEach((colKey) => {
      const hasReferenceBase = colKey[referenceBasePosition] === referenceBase

      ;['price', 'quantity'].forEach((flatColKey) => {
        valueCells.push(formatter(0))
      })

      if (!hasReferenceBase) {
        // Amount
        valueCells.push(...[formatter(0), usFmtPct(0)])

        // Price
        valueCells.push(...[formatter(0), usFmtPct(0)])

        // Quantity
        valueCells.push(...[formatter(0), usFmtPct(0)])
      }
    })

    return [...attrValueCells, ...valueCells]
  }

  renderTableRow(rowKey, rowIdx, pivotSettings, line) {
    const { visibleColKeys, pivotData, referenceBase, referenceBasePosition } = pivotSettings
    const formatter = this.getFormatter(pivotData)
    const variation = line?.calculation ?? 'positive'

    const attrValueCells = rowKey.map((r) => r)

    let valueCells = []
    visibleColKeys.forEach((colKey, j) => {
      const valuesReference = this.getAggregator(pivotData, rowKey, colKey)
      const hasReferenceBase = colKey[referenceBasePosition] === referenceBase

      ;['price', 'quantity'].forEach((flatColKey) => {
        let aggValue = 0
        if (Object.keys(valuesReference).length > 0) {
          aggValue = valuesReference[flatColKey]
        }
        valueCells.push(formatter(aggValue))
      })

      if (!hasReferenceBase) {
        const colKeyReferenceBase = _.cloneDeep(colKey)
        colKeyReferenceBase[referenceBasePosition] = referenceBase
        const valuesBase = this.getAggregator(pivotData, rowKey, colKeyReferenceBase)

        // Values
        const valueBaseA = valuesBase['amount'] ?? 0.0
        const valueBaseP = valuesBase['price'] ?? 0.0
        const valueBaseQ = valuesBase['quantity'] ?? 0.0

        const valueReferenceA = valuesReference['amount'] ?? 0.0
        const valueReferenceP = valuesReference['price'] ?? 0.0
        const valueReferenceQ = valuesReference['quantity'] ?? 0.0

        // Calculate amount
        const [absolute_variation, percentage_variation] = this.calculateVariation(
          valueBaseA,
          valueReferenceA,
          variation,
        )
        valueCells.push(...[formatter(absolute_variation), usFmtPct(percentage_variation)])

        const totalVariant = absolute_variation

        // Calculate price
        const [absolute_variation_p, percentage_variation_p] = this.calculateVariationPXQ(
          valueBaseP,
          valueReferenceP,
          variation,
          valueReferenceQ,
          totalVariant,
        )
        valueCells.push(...[formatter(absolute_variation_p), usFmtPct(percentage_variation_p)])

        // Calculate quantity
        const [absolute_variation_q, percentage_variation_q] = this.calculateVariationPXQ(
          valueBaseQ,
          valueReferenceQ,
          variation,
          valueBaseP,
          totalVariant,
        )
        valueCells.push(...[formatter(absolute_variation_q), usFmtPct(percentage_variation_q)])
      }
    })

    return [...attrValueCells, ...valueCells]
  }

  render() {
    if (this.cachedProps !== this.props) {
      this.cachedProps = this.props
      this.cachedBasePivotSettings = this.getBasePivotSettings()
    }
    const { rowKeys, colKeys, linesOrder, pivotData, referenceBase, referenceBasePosition } =
      this.cachedBasePivotSettings

    const sortedVisibleColKeys = sortedColHeader(referenceBase, colKeys, referenceBasePosition)

    const pivotSettings = Object.assign(
      {
        visibleRowKeys: rowKeys,
        visibleColKeys: sortedVisibleColKeys,
      },
      this.cachedBasePivotSettings,
    )

    if (rowKeys.length === 0) {
      rowKeys.push([])
    }
    if (colKeys.length === 0) {
      colKeys.push([])
    }

    const baseRow = _.cloneDeep(pivotData.props.rows)
    const headerRow = baseRow.map((r) => r)
    if (colKeys.length === 1 && colKeys[0].length === 0) {
      headerRow.push(this.props.aggregatorName)
    } else {
      sortedVisibleColKeys.forEach((c) => {
        const hasReferenceBase = c[referenceBasePosition] === referenceBase
        headerRow.push(`${c.join('-')} P`)
        headerRow.push(`${c.join('-')} Q`)
        if (!hasReferenceBase) {
          headerRow.push('VAR $')
          headerRow.push('VAR %')
          headerRow.push('VAR P $')
          headerRow.push('VAR P %')
          headerRow.push('VAR Q $')
          headerRow.push('VAR Q %')
        }
      })
    }

    let visibleRowKeys = []
    linesOrder.forEach((it) => {
      if (it.type === 'grouper') {
        const listFilter = rowKeys.filter((entry) => it.name === entry[0])
        if (listFilter.length > 0) {
          const lastIndex = listFilter.length - 1
          const firstItem = listFilter[lastIndex].length
          const sortedFilter = listFilter.filter((entry) => entry.length === firstItem)
          visibleRowKeys.push(...sortedFilter)
        } else {
          visibleRowKeys.push([it.name])
        }
      } else {
        visibleRowKeys.push([it.name])
      }
    })

    const result = visibleRowKeys.map((r, i) => {
      return this.renderLinesRow(r, i, pivotSettings, rowKeys)
    })

    result.unshift(headerRow)

    return (
      <textarea
        value={result.map((r) => r.join('\t')).join('\n')}
        style={{ width: '100%', height: window.innerHeight / 2 }}
        readOnly={true}
      />
    )
  }
}

TSVExportRenderer.defaultProps = PivotData.defaultProps
TSVExportRenderer.propTypes = PivotData.propTypes

export default TSVExportRenderer
