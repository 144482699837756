import React, { useState } from 'react'
import { Col, Popconfirm, Row, Select, Space } from 'antd'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { handleBudgetChange } from './utils'
import { generateSelectOptions } from 'modules/core/utils'
import configuration from 'modules/configuration'
import _ from 'lodash'

const ProjectionSelects = ({
  loading,
  idsBudgetsSelected,
  budgetTypeList,
  disabled,
  options,
  globalProjectionId,
  onRefetchGobalBudgetDetail,
  changeProjection,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [showPopconfirm, setShowPopconfirm] = useState(false)
  const [ids, setIds] = useState(idsBudgetsSelected)

  const { t } = useTranslation()

  const renderSelectValue = (concept) => {
    if (!loading && ids?.length > 0) {
      return ids?.find((object) => object[concept.id])
    }
  }

  const onClose = () => {
    setIsTableLoading(false)
    setShowPopconfirm(false)
  }

  const getIsVisible = (conceptId) => {
    const dataAux = _.compact(
      idsBudgetsSelected.map((id) => {
        if (_.keys(id).includes(conceptId.toString())) {
          return id[conceptId]
        }
        return null
      }),
    )

    const auxIds = _.compact(
      ids.map((id) => {
        if (_.keys(id).includes(conceptId.toString())) {
          return id[conceptId]
        }
        return null
      }),
    )

    const originData = dataAux.length > 0 ? dataAux[0] : dataAux
    const newData = auxIds.length > 0 ? auxIds[0] : auxIds

    return !_.isEqual(originData, newData)
  }

  const defaultValue = (concept) => {
    if (renderSelectValue(concept)) {
      return renderSelectValue(concept)[concept.id]
    } else {
      return undefined
    }
  }

  const onChange = (value, conceptId) => {
    setShowPopconfirm(true)

    const data = [...ids]
    const has_concept = data.find((el) => _.keys(el).includes(conceptId.toString()))
    const index_data = data.findIndex((el) => _.keys(el).includes(conceptId.toString()))
    let new_data = {}
    if (has_concept) {
      data[index_data] = { [conceptId]: value }
    } else {
      new_data = { [conceptId]: value }
    }

    setIds(_.compact([...data, !_.isEmpty(new_data) && new_data]))
  }

  const onCancelPopConfirm = (conceptId) => {
    setShowPopconfirm(false)
    const dataAux = _.compact(
      idsBudgetsSelected.map((id) => {
        if (_.keys(id).includes(conceptId.toString())) {
          return id[conceptId]
        }
        return null
      }),
    )
    const aux = ids.map((el) => {
      if (_.keys(el).includes(conceptId.toString())) {
        return {
          ...el,
          [conceptId]: dataAux.length > 0 ? dataAux[0] : dataAux,
        }
      }
      return el
    })

    setIds(aux)
  }

  return (
    <Row gutter={[4, 4]}>
      {budgetTypeList?.values.map((concept) => (
        <Col span={4} key={concept.id}>
          <Popconfirm
            key={concept.id}
            placement="topLeft"
            title={t('PLANNING_GLOBAL_BUDGET_MODIFY_BUDGET_POPCONFIRM_TITLE')}
            okText={t('ACTION_MODIFY')}
            onConfirm={() =>
              handleBudgetChange({
                setIsLoading: setIsTableLoading,
                changeBudget: () => changeProjection(globalProjectionId, { budgets_ids: ids }),
                onRefetch: onRefetchGobalBudgetDetail,
                onClose,
              })
            }
            okButtonProps={{ loading: isTableLoading }}
            cancelText={t('ACTION_CANCEL')}
            onCancel={() => onCancelPopConfirm(concept.id)}
            visible={showPopconfirm && getIsVisible(concept.id)}
          >
            <Space direction="vertical">
              <Typography.Body level={2}>{concept.name}</Typography.Body>
              <Select
                style={{ width: 190 }}
                placeholder={t('ACTION_SELECT')}
                key={defaultValue(concept)}
                loading={loading}
                mode="multiple"
                maxTagCount={1}
                maxTagTextLength={8}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                onChange={(value) => onChange(value, concept.id)}
                defaultValue={defaultValue(concept)}
                disabled={disabled}
                options={generateSelectOptions({
                  options: options.filter((el) => el.conceptId === concept.id),
                })}
              />
            </Space>
          </Popconfirm>
        </Col>
      ))}
    </Row>
  )
}

const mapStateToProps = (state) => ({
  budgetTypeList: configuration.selectors.getBudgetTypeList(state),
})

export default connect(mapStateToProps, null)(ProjectionSelects)
