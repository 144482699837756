import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'

export const handleBudgetChange = ({ setIsLoading, changeBudget, onRefetch, onClose }) => {
  setIsLoading(true)
  changeBudget()
    .then((response) => {
      onRefetch()
      message.success(response.payload.data.message, 8)
      onClose()
    })
    .catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'), 8)
      onClose()
    })
}
