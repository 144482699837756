import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'
import _ from 'lodash'

export const handleSave = ({
  dataCellEdit,
  setIsLoading,
  id,
  assignRole,
  editUser,
  associateUserQuicksught,
  restrictAreas,
  onRefetch,
  onClose,
  society_id,
  isQuicksight,
}) => {
  if (!_.isEmpty(dataCellEdit)) {
    const { role_ids, quicksight_user_id, approval_areas, ...restProps } = dataCellEdit
    setIsLoading(true)
    Promise.all([
      editUser(id, { society: society_id, ...restProps }),
      isQuicksight &&
        associateUserQuicksught(id, { ...(quicksight_user_id && { quicksight_user_id }) }),
      role_ids && assignRole(id, { role_ids }),
      approval_areas && restrictAreas(id, { approval_areas }),
    ])
      .then(() => {
        onRefetch()
        message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
        onClose()
      })
      .catch((error) => {
        notification.error({
          message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
          duration: 0,
        })
        onClose()
      })
  }
}

export const handleActivate = ({ setIsLoading, activateUser, onRefetch, onClose }) => {
  setIsLoading(true)
  activateUser()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
        duration: 0,
      })
      onClose()
    })
}

export const onConfirmReset = ({ setIsLoading, resetPasswordUser, onRefetch, onClose }) => {
  setIsLoading(true)
  resetPasswordUser()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
        duration: 0,
      })
      onClose()
    })
}
