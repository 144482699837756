import { Col, Form, Input, message, Modal, notification, Row, Select } from 'antd'
import { generateMessageError, generateSelectOptions } from 'modules/core/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import configuration from 'modules/configuration'

const LevelCreateModal = ({ visible, onClose, areaId, onRefetch, createLevel, usersList }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onCancel = () => {
    form.resetFields()
    onClose()
  }

  const onConfirm = () => {
    form.validateFields().then((values) => {
      setIsSaving(true)
      createLevel(areaId, values)
        .then(() =>
          onRefetch().then(() => {
            setIsSaving(false)
            onCancel()
            message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
          }),
        )
        .catch((error) => {
          setIsSaving(false)
          onCancel()
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: generateMessageError(error),
            duration: 0,
          })
        })
    })
  }

  return (
    <Modal
      title={t('PLANNING_SAC_CREATE_LEVEL_ACTION_MODAL_TITLE')}
      visible={visible}
      onCancel={onCancel}
      okText={t('ACTION_CREATE')}
      onOk={onConfirm}
      okButtonProps={{ loading: isSaving }}
      cancelButtonProps={{ disabled: isSaving }}
      centered
      forceRender
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item
              label={t('FIELD_NAME')}
              name="name"
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Input placeholder={t('ENTER_NAME_PLACEHOLDER')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('LABEL_USERS')}
              name="users"
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Select
                placeholder={t('ACTION_SELECT')}
                options={generateSelectOptions({
                  options: usersList.values.map((el) => {
                    return { id: el.id, name: el.full_name }
                  }),
                })}
                mode="multiple"
                showArrow
                showSearch
                allowClear
                maxTagCount={1}
                maxTagTextLength={12}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = {
  createLevel: configuration.actions.createLevel,
}

export default connect(null, mapDispatchToProps)(LevelCreateModal)
