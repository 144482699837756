const planning = {
  //Files names
  PLANNING_TEMPLATE_NAME_DEFAULT: 'Plika Template- Planning',
  PLANNING_TEMPLATE_NAME: 'Plika Template - Planning {{name}} - {{periodName}} ({{date}})',
  PLANNING_GLOBAL_BUDGET_EVOLUTION_TABLE_FILE_NAME: `Plika - Pllaning {{name}}({{reportName}}) - {{periodName}} ({{date}}) (Global budget - monthly evolution)`,
  PLANNING_GLOBAL_BUDGET_COMPARISON_TABLE_FILE_NAME: `Plika - Planning {{name}}({{reportName}}) - {{periodName}} ({{date}}) (Global budget - comparisons)`,
  PLANNING_EVOLTION_FILE_NAME: 'Plika - Planning (monthly evolution)',
  PLANNING_TEMPLATE_UPLOAD_VARIABLES_VALUES:
    'Plika Template - Upload variable values {{periodName}} ({{date}})',
  PLANNING_COMPARISON_FILE_NAME: 'Plika - Planning (Comparisons)',
  PLANNING_PHASE_TABLE_FILE_NAME:
    'Plika - Planning {{name}} phase {{phase}} ({{conceptName}}) - {{periodName}} ({{date}})',

  PLANNING_DISABLED_CREATION_TOOLTIP:
    'You cannot create new budgets because this period already has approved budgets',

  //Text modal create budget
  PLANNING_CREATE_FORM_BUDGET_TITLE: 'Create new budget',
  PLANNING_CREATE_BUDGET_SUCCESS_FEEDBACK: 'The budget was created successfully.',
  PLANNING_CREATE_BUDGET_ERROR_FEEDBACK: "We couldn't create the budget. Please try again.",
  PLANNING_CREATE_FORM_BUDGET_PLACEHOLDER: 'For example:{{name}} new',

  PLANNING_CREATE_NEW_BUDGET_BASE_TYPE_FORM_LABEL: 'The basis on which to create your budget',
  PLANNING_CREATE_NEW_BUDGET_BASE_DATES_FORM_LABEL: 'Range of months',
  PLANNING_CREATE_NEW_BUDGET_BASE_OPTION_FORM_SELECT: 'Zero base',
  PLANNING_CREATE_NEW_BUDGET_BASE_MODAL_TITLE: 'Load new base budget',
  PLANNING_CREATE_NEW_BUSGET_BASE_CHOOSE_TYPE_BASE_FORM_LABEL: 'Base type',
  PLANNING_CREATE_NEW_BUSGET_BASE_DATA_FILTER_FORM_LABEL: 'Data filter',
  PLANNING_CREATE_NEW_BUDGET_AVERAGE_OPTION_FORM_SELECT: 'Average historical base',
  PLANNING_CREATE_NEW_BUDGET_PREVIUS_PERIOD_OPTION_FORM_SELECT: 'Historical Base',
  PLANNING_CREATE_NEW_BUDGET_UPLOAD_FILE_OPTION_FORM_SELECT: 'File load base',
  PLANNING_CREATE_NEW_BUDGET_ARIMA_ALGORITHM_OPTION_FORM_SELECT: 'Arima algorithm base',
  PLANNING_CREATE_NEW_BUDGET_REGRESION_LINEAL_ALGORITHM_OPTION_FORM_SELECT:
    'The basis by algorithm: Linear Regression',
  PLANNING_CREATE_NEW_BUDGET_SARIMA_ALGORITHM_OPTION_FORM_SELECT: 'The basis by algorithm: Sarima',
  PLANNING_CREATE_NEW_BUDGET_PROPHET_ALGORITHM_OPTION_FORM_SELECT:
    'The basis by algorithm: Prophet',

  //Texts modal deadline
  PLANNING_CREATE_FORM_DEADLINE_DATE_PLACEHOLDER: 'Select day',
  PLANNING_CREATE_FORM_DEADLINE_TIME_PLACEHOLDER: 'Select time',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_LABEL: 'Create reminder',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_NONE: 'None',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_IN_DEADLINE: 'In the deadline',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_NONE_ONE_DAY_BEFORE: '1 day before',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_NONE_TWO_DAY_NEFORE: ' 2 days before',
  PLANNING_CREATE_DEADLINE_SUCCESS_FEEDBACK: 'The upload deadline has been set.',
  PLANNING_CREATE_DEADLINE_ERROR_FEEDBACK:
    'We were unable to set the upload deadline. Please try again.',
  PLANNING_UPDATE_DEADLINE_SUCCESS_FEEDBACK:
    'The deadline for loading has been updated successfully',
  PLANNING_UPDATE_DEADLINE_ERROR_FEEDBACK:
    'We were unable to update the upload deadline. Please try again.',
  PLANNING_DEADLINE_MODAL_TITLE: '{{file}} upload deadline',

  //Cards empty
  PLANNING_EMPTY_CARD_BODY: 'Enter and start budgeting',

  //Notification budget
  PLANNING_COPY_BUDGET_ERROR_FEEDBACK: 'We were unable to create budget copy. Please try again.',
  PLANNING_DELETE_BUDGET_SUCCESS_FEEDBACK: 'Budget was successfully removed.',
  PLANNING_DELETE_BUDGET_ERROR_FEEDBACK: "We couldn't delete this budget. Please try again.",
  PLANNING_EDIT_NAME_BUDGET_SUCCESS_FEEDBACK: 'Budget name was successfully edited.',
  PLANNING_EDIT_NAME_BUDGET_ERROR_FEEDBACK: 'We were unable to edit budget name. Please try again.',
  PLANNING_NEXT_STEP_ERROR_FEEDBACK: 'Error when moving to the next stage',
  PLANNING_CLOSING_STEP_ERROR_FEEDBACK: 'Error when closing the budget',

  //Modal global budget create
  PLANNING_GLOBAL_BUDGET_CREATE_FORM_TITLE: 'Create global budget',
  PLANNING_GLOBAL_BUDGET_CREATE_FORM_INPUT_PLACEHOLDER: 'Eg: new global budget',

  //Drawer scenario
  PLANNING_VARS_EMPTY_SCREEN_UPLOAD_SUBTITLE: 'Start loading your variables',
  PLANNING_DRAWER_SCENARIO_NO_VARIABLES_VALUES: 'The variable still does not have assigned values',
  PLANNING_DRAWER_SCENARIO_NO_VARIABLES: 'There are no variables yet',

  PLANNING_RULES_APPLY_SUCCESS_FEEDBACK: 'The rule has been {{action}} correctly.',
  RULES_APPLY_TEXT: 'Applied',
  RULES_UNAPPLY_TEXT: 'Unapplied',
  PLANNING_RULES_APPLY_ERROR_FEEDBACK: 'We could not {{action}} the rule. Please, I tried again.',

  PLANNING_VARIABLE_EDIT_TOOLTIP: 'Click to edit variable',
  PLANNING_VARIABLE_CONFIG_TOOLTIP: 'To edit this field, please go to variables configuration',
  PLANNING_VARIABLE_CONFIG_LINK: 'Go to variables list',
  PLANNING_VARIABLE_CONFIG_LINK_ACTION: 'Edit from configuration',

  PLANNING_VARIABLES_RETURN_CONFIRM_TITLE: 'Return to variables` list ',
  PLANNING_VARIABLES_RETURN_CONFIRM_TEXT: 'Are you sure you want to return to variables` list?',
  PLANNING_VARIABLES_RETURN_CONFIRM_TOOLTIP:
    ' If you return without saving you will lose the values loaded so far',

  PLANNING_ACTION_MODAL_TITLE: '{{action}} budget',
  PLANNING_APPROVE_MODAL_TEXT:
    'You are about to finalize the budget process for your period. Are you sure you want to do it? Remember that once you approve the budget, you can no longer modify any data or upload more files.',
  PLANNING_SCREEN_SUCCESS_FEEDBACK: 'You have just {{action}} the {{period}} :)', //NOTE: COMENTADO  HASTA QUE SE PUEDA NOTIFICAR. Do you want to break the news to everyone who participated in this planning?',
  PLANNIG_PUBLISH_MODAL_TEXT:
    'You are about to publish your budget. In this instance you can edit, continue loading data, compare budgets with each other and visualize your data in analysis, forecast and actual section',

  //Empty screen
  PLANNING_EMPTY_SCREEN_TITLE: "You don't have any budget created yet!",
  PLANNING_EMPTY_SCREEN_BODY:
    'Create a budget, set priorities and build as many projections and scenarios as you need',
  PLANNING_BUDGET_CREATE_ACTION: 'Create budget',

  //Empty screen global budget
  PLANNING_GLOBAL_BUDGET_EMPTY_SCREEN_TITLE: 'You still have no global budget created!',

  PLANNING_GLOBAL_BUDGET_FIXED_POPOVER_TEXT:
    'You selected this budget as default. This means that you will visualize your data throughout the tool.',
  PLANNING_GLOBAL_BUDGET_NOT_DATA_BUDGET_TOOLTIP_TITLE:
    'To perform a general analysis you must have data in at least 2 budgets of different concepts.',
  PLANNING_GLOBAL_BUDGET_MODIFY_BUDGET_POPCONFIRM_TITLE:
    'If you change the settings for the global budget, all data will be updated. Are you sure you want to continue?',
  PLANNING_GLOBAL_BUDGET_INFORMATIVE_TOOLTIP_TITLE: 'Click to establish this budget as default.',
  PLANNING_GLOBAL_BUDGET_ADD_NEW_COMBINATION_ACTION: 'Add new combination',
  PLANNING_GLOBAL_BUDGET_DELETE_COMBINATION_ACTION: 'Eliminate combination',
  PLANNING_GLOBAL_BUDGET_CREATE_NEW_CHOOSE_TYPE_BUDGET_FORM_SELECT_LABEL:
    'Choose the budget types that will make up your {{projection}}',

  PLANNING_PHASES_INFORMATIVE_DISABLED_TOOLTIP_TITLE:
    'Once you send the information from the previous stage you can visualize this stage.',
  PLANNING_PHASES_ACTION_CONFIRM_POPCONFIRM_TITLE: `{{data}} Once you do this, you won't be able to edit your data, only view it. Are you sure you want to {{action}} now?`,
  PLANNING_PHASES_CLOSE_CONFIRM_POPCONFIRM_TITLE: 'You are to close this budget',
  PLANNING_PHASES_SEND_CONFIRM_POPCONFIRM_TITLE:
    'You are about to move your budget to the next stage',
  PLANNING_PHASES_SEND_TOPDOWN_ACTION: 'Send to Topdown',
  PLANNING_PHASES_SEND_BOTTOMUP_ACTION: 'Send to Bottomup',
  PLANNING_PHASES_SEND_CLOSING_ACTION: 'Send to closure',

  //reglas de negocio
  PLANNING_FORMULAS_DESCRIPTION_NOTIFICATION_SUCCESS: 'To display enter the factor details`.',
  PLANNING_FORMULA_SAVE_DATA_TOOLTIP_TITLE:
    'You must save the values ​​entered to enable this action.',
  PLANNING_FACTOR_POPCONFIRM_SAVE_CHANGES:
    'Are you sure you want to save the changes made? Keep in mind that this will impact the calculation and its results.',
  PLANNING_RULE_KEEP_DATA_INFORMATIVE_ALERT_DESCRIPTION:
    'To maintain the values ​​you modify, you must always apply the rule after saving them',

  PLANNING_DISPLAY_NEGATIVE_DATA_FORM_LABEL: 'Visualization negative values',

  //multimoneda
  PLANNING_CURRENCY_EMPTY_SCREEN_TITLE:
    'You do not have any activated origin currencies to show yet!',
  PLANNING_CURRENCY_CANCEL_POPCONFIRM_TITLE: 'Are you sure you want to cancel the changes?',
  PLANNING_CURRENCY_LOAD_ALL_EXCHANGE_RATE_ALERT_DESCRIPTION:
    'To save the exchange rate for every month they must have a loaded value.',
  PLANNING_CURRENCY_ALL_VALUES_TOOLTIP_TITLE: 'You must complete with values every month',

  PLANNING_PERCENTAGE_EDITION_CELL_INFORMATIVE_ALERT_MESSAGE:
    'When entering % the editing will be done in percentage',

  PLANNING_EMPTY_DATA_CARDS_BUDGET_TYPE_BODY_TEXT:
    'Enter to start loading {{title}} and to see a summary of your data',

  PLANNING_VALUES_FACTORS_APPLIED: 'Values ​​with rules applied',

  PLANNING_UPLOAD_VALUE_CERO_INFORMATIVE_TOOLTIP_TITLE:
    'Plika will give us a blank table, in the form of a “canvas”, so that we can complete it manually or with some other budget generation alternative.',
  PLANNING_UPLOAD_VALUE_AVERAGE_INFORMATIVE_TOOLTIP_TITLE:
    'This type of loading allows us to contemplate the historical information loaded in Plika, calculating the average of this data to establish our Base Budget.',
  PLANNING_UPLOAD_HISTORICAL_PREVIOUS_PERIOD_INFORMATIVE_TOOLTIP_TITLE:
    'It allows us to take pre-existing information in the tool as a basis, selecting a range of months and the dimension filter(s) to be considered.',
  PLANNING_UPLOAD_VALUE_WITHOUT_BASE_INFORMATIVE_TOOLTIP_TITLE:
    'It is the most common type of load. It is developed using an Excel template or a Spreadsheet template.',
  PLANNING_PHASES_BASE_INFORMATIVE_TOOLTIPE_TITLE:
    'Marks the first entry or loading of data on the selected card.',
  PLANNING_PHASES_TOPDOWN_INFORMATIVE_TOOLTIPE_TITLE:
    'This phase is distinguished by the modifications that the budget manager can make by proposing specific amounts for budget items assigned to members of his or her team or to those users in charge of reviewing the information later.',
  PLANNING_TRANSACTIONAL_TABLE_INFORMATIVE_TOOLTIP_TITLE:
    'It generates a pivoting and nested style visual, which allows creating a dynamic analysis between the different Plika dimensions and values.',
  PLANNING_PERCENTAGE_TABLE_INFORMATIVE_TOOLTIP_TITLE:
    'It allows applying business rules using a hierarchical order between dimensions, making use of the cascading functionality.',
  PLANNING_PHASES_BOTTOMUP_INFORMATIVE_TOOLTIP_TITLE:
    'This phase is distinguished by the modifications that those involved in each area or business unit propose to the budget granted.',
  PLANNING_PHASES_CIERRE_INFORMATIVE_TOOLTIP_TITLE:
    'It is the final stage of the process, where the area in charge of the budget generates the final adjustments.',

  PLANNING_PORCENTUAL_TABLE_ONLY_INDIVIDUAL_RULES_INFORMATIVE_ALERT_DESCRIPTION:
    'In the percentage view of business rules you can only work with those that contain individual behavior formula',

  PLANNING_PROJECTION_LIST_FORM_LABEL: 'List of projections',

  PLANNING_VIDEO_DESCRIPTION_1:
    'We will explain the different ways to create budgets and how to generate multiple projections for each one.',
  PLANNING_VIDEO_DESCRIPTION_2:
    'You will learn how to create a budget by uploading a file and discover some important tips to improve your financial projections.',
  PLANNING_VIDEO_DESCRIPTION_3:
    'We will explain the generation of scenarios based on the use of the Business Rules functionality.',
  PLANNING_VIDEO_DESCRIPTION_4: `You'll discover how to create a budget iteratively, moving step by step through each key stage of the process.`,
  PLANNING_VIDEO_DESCRIPTION_5:
    'We will show you the various functionalities available on each card of the different budget types.',
  PLANNING_VIDEO_DESCRIPTION_6: `We'll explore the different options for uploading data via files and show you how to manage and delete information previously uploaded to the tool.`,
  PLANNING_VIDEO_DESCRIPTION_7: 'We will explain the creation of global budgets and how they work.',

  PLANNING_SAC_EMPTY_SCREEN_DESCRIPTION: 'You do not have a request created yet',
  PLANNING_SAC_RETURN_APPLICATIONS_LIST_TYPOGRAPHY: 'Return to list of requests',
  PLANNING_SAC_INFORMATIVE_CREATION_TOOLTIP_TITLE: 'You must first select a type of adjustment',
  PLANNING_SAC_TEMPLATE_NAME: 'Plika Template - Request Upload {{date}}',
  PLANNING_SAC_CREATE_NEW_ACTION_BUTTON_NAME: 'Create request',
  PLANNING_SAC_COMBINATION_DIMENSIONS_STEP_DESCRIPTION: 'Combination of dimensions',
  PLANNING_SAC_CHOICE_AMOUNTS_STEP_DESCRIPTION: 'Choice of amounts',
  PLANNING_SAC_ADD_NEW_COMBINATION_ACTION_AREA_TITLE: 'Add dimension combination',
  PLANNING_SAC_HEADER_COMBINATION_TITLE: 'Combination {{index}}',
  PLANNING_SAC_INFORMATION_ALERT_DESCRIPTION_RECLASSIFICATION:
    'The amounts entered for reductions must be negative at source.',
  PLANNING_SAC_INFORMATION_ALERT_DESCRIPTION_REDUCTION:
    'Amounts entered for reductions must be negative',
  PLANNING_SAC_INFORMATION_ALERT_DESCRIPTION_EXTENSION:
    'The amounts entered for extensions must be positive',
  PLANNING_SAC_COMBINATION_DETAIL_DRAWER_TITLE: 'Combination detail',
  PLANNING_SAC_HISTORY_APPLICATION_DRAWER_TITLE: 'Application history',
  PLANNING_SAC_DELETE_AREA_CONFIRMATION_POPCONFIRM_TITLE:
    'Are you sure you want to delete this area?',
  PLANNING_SAC_AREA_EMPTY_SCREEN_DESCRIPTION: `You still don't have any area created`,
  PLANNING_SAC_CREATE_AREA_ACTION_MODAL_TITLE: 'Create area',
  PLANNING_SAC_DELETE_LEVEL_CONFIRMATION_POPCONFIRM_TITLE:
    'Are you sure you want to delete this level?',
  PLANNING_SAC_LEVEL_EMPTY_SCREEN: `You still don't have a level created`,
  PLANNING_SAC_CREATE_LEVEL_ACTION_MODAL_TITLE: 'Create level',
  PLANNING_SAC_INFORMATIVE_APPROVE_BUDGET_TOOLTIP_TITLE: 'You must first approve the budget',
  PLANNING_SAC_INFORMATIVE_CREATE_AREAS_TOOLTIP_TITLE:
    'First you must create areas from configuration',
  PLANNING_SAC_WITHOUT_SWITCH_TITLE: 'Without SAC',
  PLANNING_SAC_WITH_SWITCH_TITLE: 'With SAC',
  PLANNING_SAC_INFORMATIVE_SUM_VALUES_DATE_ALERT_DESCRIPTION:
    'The origin and destination months must be the same and result in zero',
  PLANNING_SAC_INFORMATIVE_NEGATIVE_VALUES_ALERT_DESCRIPTION:
    'Remember that the values ​​must be negative',
  PLANNING_SAC_INFORMATIVE_POSITIVE_VALUES_ALERT_DESCRIPTION:
    'Remember that the values ​​must be positive',
  PLANNING_SAC_ACTION_CONFIRM_MODAL_BODY_TEXT:
    'You are about to confirm the {{adjustmentType}} made by {{userName}} with an amount of ${{amount}}. Are you sure you want to do it?',
  PLANNING_SAC_ACTION_CONFIRM_MODAL_TITLE: 'Action confirmation',
  PLANNING_SAC_INFORMATIVE_VISIBLE_BUTTON_TOOLTIP_TITLE:
    'It will be available in the closing stage view and if the budget is approved',
}

export default planning
