import { Card, Col, message, notification, Row, Space, Table, Tag } from 'antd'
import { Button, ButtonActionBar, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { EyeOutlined, FolderViewOutlined } from '@ant-design/icons'
import { generateMessageError } from 'modules/core/utils'
import { ActionConfirmModal } from './components'
import _ from 'lodash'
import login from 'modules/login'
import planning from 'modules/planning'
import numeral from 'numeral'
import moment from 'moment'
import configuration from 'modules/configuration'

const GlobalBudgetSACList = ({
  isKpisLoading,
  adjustmentsKpis,
  adjustmentsList,
  onClickDetail,
  onClickHistorical,
  onClickCreate,
  onRefetch,
  areaList,
  fetchUsersList,
  usersList,
  loggedUser: { pkid },
  approveRevieweApplication,
  rejectedApplication,
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const [dataRow, setDataRow] = useState({})
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    fetchUsersList()
  }, [fetchUsersList])

  const getStatus = (completed) => {
    return {
      requested: completed ? 'STATUS_REQUESTED' : 'ACTION_REQUEST',
      reviewed_ordinary_area: completed ? 'STATUS_REVIEWED' : 'ACTION_REVIEW',
      approved_ordinary_area: completed ? 'STATUS_DATE_APPROVE' : 'ACTION_APPROVE',
      rejected_ordinary_area: completed ? 'STATUS_REQUEST_REJECTED' : 'ACTION_REJECTED',
    }
  }

  const ADJUSTMENTS_TYPE = {
    EXPANSION: 'LABEL_EXPANSION',
    REDUCTION: 'LABEL_REDUCTION',
    RECLASSIFICATION: 'LABEL_RECLASSIFICATION',
  }

  const STATUS = {
    pending_approval: 'STATUS_PENDING_APPROVE',
    pending_review: 'STATUS_PENDING_REVIEW',
    rejected_ordinary_area: 'STATUS_REJECTED',
    approved_ordinary_area: 'STATUS_APPROVED',
    total: 'FIELD_TOTAL',
  }

  const COLOR_STATUS = {
    requested: '',
    reviewed_ordinary_area: 'lime',
    approved_ordinary_area: 'green',
    rejected_ordinary_area: 'red',
  }

  const handleApproveReviewApplication = (id) => {
    setIsSaving(true)
    approveRevieweApplication(id)
      .then(() => {
        onRefetch()
        message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        setIsSaving(false)
        setDataRow({})
        setShowConfirmModal(false)
      })
      .catch((error) => {
        setIsSaving(false)
        setDataRow({})
        setShowConfirmModal(false)
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error),
          duration: 0,
        })
      })
  }

  const handleRejectedApplication = (id) => {
    setIsSaving(true)
    rejectedApplication(id)
      .then(() => {
        onRefetch()
        message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        setIsSaving(false)
      })
      .catch((error) => {
        setIsSaving(false)
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error),
          duration: 0,
        })
      })
  }

  const renderTitle = (filterNotComplete, data) => {
    if (data[data.length - 1] && pkid === data[data.length - 1]?.responsible) {
      return t(getStatus(data[data.length - 1]?.completed)[data[data.length - 1]?.status])
    }
    return t(getStatus(filterNotComplete[0]?.completed)[filterNotComplete[0]?.status])
  }

  const columns = [
    {
      title: t('LABEL_USER'),
      dataIndex: 'user',
      render: (user) => usersList.values.find((el) => el.id === user)?.full_name,
    },
    {
      title: t('FIELD_DATE'),
      dataIndex: 'created_at',
      render: (date) => moment(date).calendar(),
    },
    {
      title: t('FIELD_TYPE'),
      dataIndex: 'adjustment_type',
      render: (type) => t(ADJUSTMENTS_TYPE[type]),
    },
    {
      title: t('FIELD_OBSERVATIONS'),
      dataIndex: 'description',
    },
    {
      title: t('FIELD_AMOUNT'),
      dataIndex: 'total_amount',
    },
    {
      title: t('FIELD_STATUS'),
      dataIndex: 'statuses',
      render: (statuses) => {
        const data = statuses.filter((el) => el.completed)
        return (
          <Tag color={COLOR_STATUS[data[data.length - 1].status]}>
            {t(getStatus(data[data.length - 1].completed)[data[data.length - 1].status])}
          </Tag>
        )
      },
    },
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      render: (id, record) => {
        const data = [...record.statuses]
        data.sort((itemA, itemB) => itemA.position - itemB.position)
        const filterNotComplete = data.filter((el) => !el.completed)

        return (
          <Space direction="horizontal">
            {((filterNotComplete.length > 0 &&
              pkid === filterNotComplete[0].responsible &&
              !data[data.length - 1]?.completed) ||
              (data[data.length - 1] &&
                pkid === data[data.length - 1]?.responsible &&
                !data[data.length - 1]?.status.includes('rejected') &&
                !data[data.length - 1]?.completed)) && (
              <>
                <Button.Default
                  loading={isSaving && id === dataRow.id}
                  onClick={() => {
                    handleRejectedApplication(id)
                    setDataRow(record)
                  }}
                  title="ACTION_REJECTED"
                />
                <Button.Primary
                  onClick={() => {
                    setShowConfirmModal(true)
                    setDataRow(record)
                  }}
                  title={renderTitle(filterNotComplete, data)}
                />
              </>
            )}
            <Button.Icon
              type="text"
              shape="circle"
              icon={<Typography.Icon icon={EyeOutlined} />}
              title={t('ACTION_OPEN_DETAIL')}
              onClick={() => onClickDetail(id)}
            />
            <Button.Icon
              type="text"
              shape="circle"
              icon={<Typography.Icon icon={FolderViewOutlined} />}
              title={t('ACTION_OPEN_DETAIL')}
              onClick={() => onClickHistorical(id)}
            />
          </Space>
        )
      },
    },
  ]

  const renderKpis = () => {
    if (isKpisLoading) {
      return (
        <Row gutter={[24, 0]}>
          {[1, 2, 3, 4, 5].map((index) => (
            <Col span={6} key={index}>
              <Card loading={true} />
            </Col>
          ))}
        </Row>
      )
    }
    if (!isKpisLoading) {
      return (
        <Row gutter={[24, 0]}>
          {_.map({ total: adjustmentsList.length, ...adjustmentsKpis }, (data, key) => {
            return (
              <Col span={4} key={key}>
                <Card bodyStyle={key === 'total' ? { backgroundColor: '#e1effa' } : {}}>
                  {typeof data === 'number' ? (
                    <Row gutter={[4, 4]}>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Space direction="vertical">
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography.Headline level={4}>{data}</Typography.Headline>
                          </div>
                          <Typography.Body level={3}>{t(STATUS[key])}</Typography.Body>
                        </Space>
                      </Col>
                    </Row>
                  ) : (
                    <Row gutter={[24, 24]}>
                      <Col span={10} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Space direction="vertical">
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography.Headline level={4}>{data.quantity}</Typography.Headline>
                          </div>
                          <Typography.Body level={3}>{t(STATUS[key])}</Typography.Body>
                        </Space>
                      </Col>
                      <Col span={14} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Space direction="vertical">
                          <Typography.Headline level={5}>
                            ${numeral(data.total_amount).format('0,0a')}
                          </Typography.Headline>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography.Body level={2}>{t('FIELD_TOTAL')}</Typography.Body>
                          </div>
                        </Space>
                      </Col>
                    </Row>
                  )}
                </Card>
              </Col>
            )
          })}
        </Row>
      )
    }
  }

  return (
    <>
      <Col span={24}>
        <ButtonActionBar>
          <Button.Primary
            disabled={areaList.length === 0}
            title="ACTION_CREATE_NEW"
            onClick={() => onClickCreate()}
          />
        </ButtonActionBar>
      </Col>
      <Col span={24}>{renderKpis()}</Col>
      <Col span={24}>
        <Table size="small" columns={columns} dataSource={adjustmentsList} />
      </Col>
      <ActionConfirmModal
        visible={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false)
          setDataRow({})
        }}
        onConfirm={() => handleApproveReviewApplication(dataRow.id)}
        isSaving={isSaving}
        dataRow={dataRow}
        usersList={usersList}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  usersList: configuration.selectors.getUsersList(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  fetchUsersList: configuration.actions.fetchUsersList,
  approveRevieweApplication: planning.actions.approveRevieweApplication,
  rejectedApplication: planning.actions.rejectedApplication,
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalBudgetSACList)
