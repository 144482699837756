import { Alert, Space } from 'antd'
import { Typography } from 'modules/core/components'
import { CollapseA } from './components'
import { useTranslation } from 'react-i18next'

const ContentA = ({
  dataSelected,
  dataDimensions,
  amounts,
  setAmounts,
  reclassificationData,
  reclassificationAmounts,
  setReclassificationAmounts,
  onClickDetail,
  compareValueDates = null,
}) => {
  const { t } = useTranslation()

  return dataSelected.adjustment_type !== 'RECLASSIFICATION' ? (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Alert
        description={
          dataSelected.adjustment_type === 'EXPANSION'
            ? t('PLANNING_SAC_INFORMATION_ALERT_DESCRIPTION_EXTENSION')
            : t('PLANNING_SAC_INFORMATION_ALERT_DESCRIPTION_REDUCTION')
        }
        type="info"
        showIcon
        closable
        style={{ marginTop: 10 }}
      />
      <CollapseA
        data={dataDimensions}
        dataAmounts={amounts}
        setAmounts={(index, date, val) =>
          setAmounts((prevState) =>
            prevState.map((el, elIndex) => {
              if (index === elIndex) {
                return {
                  ...el,
                  [date]: val,
                }
              }
              return el
            }),
          )
        }
        onClickDetail={(index) => onClickDetail(index, 'other')}
        type={dataSelected.adjustment_type === 'EXPANSION' ? 'positive' : 'negative'}
      />
    </Space>
  ) : (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Alert
        description={t('PLANNING_SAC_INFORMATION_ALERT_DESCRIPTION_RECLASSIFICATION')}
        type="info"
        showIcon
        closable
        style={{ marginTop: 10 }}
      />
      {compareValueDates && (
        <Alert
          type="warning"
          showIcon
          description={t('PLANNING_SAC_INFORMATIVE_SUM_VALUES_DATE_ALERT_DESCRIPTION')}
        />
      )}
      <div>
        <div className="header-table" style={{ marginTop: 10 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography.Body>
              {t('CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_LABEL_ORIGIN')}
            </Typography.Body>
          </div>
        </div>
        <CollapseA
          data={reclassificationData.origin}
          dataAmounts={reclassificationAmounts.origin}
          setAmounts={(index, date, val) =>
            setReclassificationAmounts((prevState) => {
              return {
                ...prevState,
                origin: prevState.origin.map((el, elIndex) => {
                  if (index === elIndex) {
                    return {
                      ...el,
                      [date]: val,
                    }
                  }
                  return el
                }),
              }
            })
          }
          onClickDetail={(index) => onClickDetail(index, 'origin')}
          type="negative"
        />
      </div>
      <div>
        <div className="header-table">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography.Body>
              {t('CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_LABEL_DESTINATION')}
            </Typography.Body>
          </div>
        </div>
        <CollapseA
          data={reclassificationData.destination}
          dataAmounts={reclassificationAmounts.destination}
          setAmounts={(index, date, val) =>
            setReclassificationAmounts((prevState) => {
              return {
                ...prevState,
                destination: prevState.destination.map((el, elIndex) => {
                  if (index === elIndex) {
                    return {
                      ...el,
                      [date]: val,
                    }
                  }
                  return el
                }),
              }
            })
          }
          onClickDetail={(index) => onClickDetail(index, 'destination')}
          type="positive"
        />
      </div>
    </Space>
  )
}

export default ContentA
