import { Button, Tooltip } from 'antd'

const ButtonBase = ({ children, tooltipTitle = '', ...props }) => {
  return (
    <Tooltip title={tooltipTitle} placement="bottom">
      <Button {...props}>{children}</Button>
    </Tooltip>
  )
}

export default ButtonBase
