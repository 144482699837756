import React, { useEffect, useState } from 'react'
import { Checkbox, Col, DatePicker, Form, Row, Select, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { generateSelectOptions } from 'modules/core/utils'
import { connect } from 'react-redux'
import { PROJECTION } from 'modules/core/constants'
import { disabledDate } from '../../utils'
import { AddAndDeleteFormItem, Button, Typography } from 'modules/core/components'
import planning from 'modules/planning'
import moment from 'moment'
import _ from 'lodash'
import FilterDataCreateModal from '../FilterDataCreateModal/FilterDataCreateModal'

const HistoricalBase = ({
  projectionInstanceList,
  dataPeriod,
  periodList,
  filterDates,
  setFilterDates,
  setfilterData,
  setFormHistorical,
  filterData,
  concept_id,
}) => {
  const [showFilterData, setShowFilterData] = useState(false)
  const [index, setIndex] = useState(null)
  const [projectionId, setProjectionId] = useState(null)
  const [type, setType] = useState('')
  const { t } = useTranslation()
  const [form] = Form.useForm()

  useEffect(() => {
    setFormHistorical(form)
  }, [form])

  const generateOptionsValue = (index) => {
    const OPTIONS_VALUE = [
      {
        id: 'amount',
        name: 'FIELD_AMOUNT',
        disabled:
          form.getFieldValue('historical_list')[index] &&
          form.getFieldValue('historical_list')[index]?.type_value
            ? form.getFieldValue('historical_list')[index]?.type_value.includes('price') ||
              form.getFieldValue('historical_list')[index]?.type_value.includes('quantity')
            : false,
      },
      {
        id: 'price',
        name: 'FIELD_PRICE',
        disabled:
          form.getFieldValue('historical_list')[index] &&
          form.getFieldValue('historical_list')[index]?.type_value
            ? form.getFieldValue('historical_list')[index]?.type_value.includes('amount')
            : false,
      },
      {
        id: 'quantity',
        name: 'FIELD_COUNT',
        disabled:
          form.getFieldValue('historical_list')[index] &&
          form.getFieldValue('historical_list')[index]?.type_value
            ? form.getFieldValue('historical_list')[index]?.type_value.includes('amount')
            : false,
      },
    ]

    return OPTIONS_VALUE
  }

  return (
    <>
      <Form form={form} layout="vertical" initialValues={{ historical_list: [''] }}>
        {() => (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item
                name="dates"
                label={t('PLANNING_CREATE_NEW_BUDGET_BASE_DATES_FORM_LABEL')}
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
              >
                <DatePicker.RangePicker
                  format="MMM YYYY"
                  picker="month"
                  style={{ width: '100%' }}
                  onChange={(val) =>
                    setFilterDates({
                      date_start: moment(val[0]).startOf('month').format('YYYY-MM-DD'),
                      date_end: moment(val[1]).startOf('month').format('YYYY-MM-DD'),
                    })
                  }
                  disabledDate={(date) => disabledDate(date, periodList, dataPeriod)}
                  defaultPickerValue={
                    !_.isEmpty(dataPeriod)
                      ? [moment(dataPeriod?.start_date), moment(dataPeriod?.end_date)]
                      : null
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.List name="historical_list">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index2) => {
                        return (
                          <Row gutter={[8, 8]} type="flex" justify="end">
                            <Col
                              span={
                                form.getFieldValue('historical_list')[field.name] &&
                                form.getFieldValue('historical_list')[field.name].type_base ===
                                  PROJECTION.BUDGET_PERSONALIZED_REPORT.toUpperCase()
                                  ? 12
                                  : 24
                              }
                            >
                              <Form.Item
                                name={[field.name, 'type_base']}
                                label={[
                                  field.label,
                                  t('PLANNING_CREATE_NEW_BUSGET_BASE_CHOOSE_TYPE_BASE_FORM_LABEL'),
                                ]}
                                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                              >
                                <Select
                                  placeholder={t('ACTION_SELECT')}
                                  disabled={_.isEmpty(filterDates)}
                                  onChange={(val) => {
                                    const auxVal =
                                      val && val === 'PRESUPUESTO' ? 'planning' : 'real'
                                    val && setType(auxVal)
                                  }}
                                  options={generateSelectOptions({
                                    options: PROJECTION.PROJECTIONS_OPTIONS.map((op) => {
                                      return { ...op, name: t(op.name) }
                                    }),
                                  })}
                                />
                              </Form.Item>
                            </Col>
                            {form.getFieldValue('historical_list')[field.name] &&
                              form.getFieldValue('historical_list')[field.name].type_base ===
                                PROJECTION.BUDGET_PERSONALIZED_REPORT.toUpperCase() &&
                              !_.isEmpty(filterDates) && (
                                <Col span={12}>
                                  <Form.Item
                                    name={[field.name, 'budget_id']}
                                    label={[field.label, t('LABEL_BUDGETS')]}
                                    rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                                  >
                                    <Select
                                      placeholder={t('ACTION_SELECT')}
                                      showSearch
                                      onChange={(val) => setProjectionId(val)}
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      options={generateSelectOptions({
                                        options: projectionInstanceList,
                                      })}
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                            <Col span={24}>
                              <Form.Item
                                name={[field.name, 'type_value']}
                                label={[field.label, t('FIELD_VALUE')]}
                                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                              >
                                <Checkbox.Group>
                                  <Space direction="horizontal">
                                    {generateOptionsValue(field.name).map((el) => (
                                      <Space key={el.id} direction="horizontal">
                                        <Checkbox disabled={el.disabled} value={el.id} />
                                        <Typography.Body>{t(el.name)}</Typography.Body>
                                      </Space>
                                    ))}
                                  </Space>
                                </Checkbox.Group>
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                name={[field.name, 'filter_data']}
                                label={[
                                  field.label,
                                  t('PLANNING_CREATE_NEW_BUSGET_BASE_DATA_FILTER_FORM_LABEL'),
                                ]}
                                initialValue={[]}
                              >
                                <Button.Default
                                  title={t('ACTION_ADD_SEE_FILTER')}
                                  block
                                  disabled={
                                    form.getFieldValue('historical_list') &&
                                    form.getFieldValue('historical_list')[field.name] &&
                                    ((form.getFieldValue('historical_list')[field.name]
                                      .type_base === 'PRESUPUESTO' &&
                                      !form.getFieldValue('historical_list')[field.name]
                                        ?.budget_id) ||
                                      !form.getFieldValue('historical_list')[field.name]?.type_base)
                                  }
                                  onClick={() => {
                                    setShowFilterData(true)
                                    setIndex(index2)
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <AddAndDeleteFormItem
                              fieldsLength={fields.length}
                              index={index2}
                              addData={{
                                // disabled: isLoading,
                                onClick: () => {
                                  add()
                                },
                                buttonName: 'ACTION_ADD_NEW',
                              }}
                              deleteData={{
                                // disabled: isLoading,
                                onClick: () => {
                                  remove(field.name)
                                },
                                buttonName: 'ACTION_DELETE',
                              }}
                            />
                          </Row>
                        )
                      })}
                    </div>
                  )
                }}
              </Form.List>
            </Col>
          </Row>
        )}
      </Form>
      <FilterDataCreateModal
        visible={showFilterData}
        onClose={() => setShowFilterData(false)}
        setfilterData={setfilterData}
        index={index}
        filterData={filterData}
        type={type}
        projectionId={type === 'real' ? concept_id : projectionId}
        params={type === 'real' ? filterDates : {}}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  periodList: planning.selectors.getPeriodList(state),
})

export default connect(mapStateToProps, null)(HistoricalBase)
