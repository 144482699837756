import _, { isEmpty } from 'lodash'
import { CellStyle } from 'modules/core/components'
import React from 'react'
import {
  aggregators,
  flatKey,
  formatter,
  normalizeName,
  PivotData,
  redColorScaleGenerator,
  sortedColHeader,
  spanSize,
  usFmt,
  usFmtPct,
} from '../../../Utilities'

function makeRenderer(opts = {}) {
  class TableRenderer extends React.PureComponent {
    hasAccumulative = (arr1) => {
      let subArr1 = _.slice(arr1, 0, 1)
      let subArr2 = ['Proyecciones']
      return _.isEqual(subArr1, subArr2)
    }

    hasMonthly = (arr1) => {
      let subArr1 = _.slice(arr1, 0, 2)
      let subArr2 = ['Fecha', 'Proyecciones']
      return _.isEqual(subArr1, subArr2)
    }

    getBasePivotSettings() {
      const props = this.props
      const colAttrs = props.cols
      const rowAttrs = props.rows

      const pivotData = new PivotData(this.props, {
        rowEnabled: true,
        colEnabled: false,
      })
      const colKeys = pivotData.getColKeys()
      const rowKeys = pivotData.getRowKeys()

      const tableOptions = props.tableOptions
      // Custom lines
      const linesOrder = !isEmpty(tableOptions?.linesOrder) ? tableOptions.linesOrder : []
      const linesTotal = !isEmpty(tableOptions?.linesTotal) ? tableOptions.linesTotal : []
      const linesRatio = !isEmpty(tableOptions?.linesRatio) ? tableOptions.linesRatio : []
      const linesRatioN = !isEmpty(tableOptions?.linesRatioN) ? tableOptions.linesRatioN : []
      const referenceBase = !_.isEmpty(tableOptions?.referenceBase)
        ? tableOptions.referenceBase
        : null
      const referenceBasePosition = this.hasAccumulative(colAttrs)
        ? 0
        : this.hasMonthly(colAttrs)
        ? 1
        : 0

      return Object.assign(
        {
          pivotData,
          colAttrs,
          rowAttrs,
          colKeys,
          rowKeys,
          linesOrder,
          linesTotal,
          linesRatio,
          linesRatioN,
          referenceBase,
          referenceBasePosition,
        },
        TableRenderer.heatmapMappers(pivotData, props.tableColorScaleGenerator, colKeys, rowKeys),
      )
    }

    static heatmapMappers(pivotData, colorScaleGenerator, colKeys, rowKeys) {
      let valueCellColors = () => {}
      let rowTotalColors = () => {}
      let colTotalColors = () => {}
      if (opts.heatmapMode) {
        const rowTotalValues = colKeys.map((x) => pivotData.getAggregator([], x).value())
        rowTotalColors = colorScaleGenerator(rowTotalValues)
        const colTotalValues = rowKeys.map((x) => pivotData.getAggregator(x, []).value())
        colTotalColors = colorScaleGenerator(colTotalValues)

        if (opts.heatmapMode === 'full') {
          const allValues = []
          rowKeys.map((r) =>
            colKeys.map((c) => allValues.push(pivotData.getAggregator(r, c).value())),
          )
          const colorScale = colorScaleGenerator(allValues)
          valueCellColors = (r, c, v) => colorScale(v)
        } else if (opts.heatmapMode === 'row') {
          const rowColorScales = {}
          rowKeys.map((r) => {
            const rowValues = colKeys.map((x) => pivotData.getAggregator(r, x).value())
            rowColorScales[r] = colorScaleGenerator(rowValues)
          })
          valueCellColors = (r, c, v) => rowColorScales[r](v)
        } else if (opts.heatmapMode === 'col') {
          const colColorScales = {}
          colKeys.map((c) => {
            const colValues = rowKeys.map((x) => pivotData.getAggregator(x, c).value())
            colColorScales[c] = colorScaleGenerator(colValues)
          })
          valueCellColors = (r, c, v) => colColorScales[c](v)
        }
      }

      return { valueCellColors, rowTotalColors, colTotalColors }
    }

    esInfinity(num) {
      return num === Infinity || num === -Infinity
    }

    normalizeNumber(value, variation = 'positive') {
      if (!value) {
        return 0
      }
      if (variation === 'negative') {
        value = Math.abs(value)
      }

      const normalizedValue = parseFloat(value).toFixed(6)
      return parseFloat(normalizedValue)
    }

    calculateVariation(valueBase, valueReference, varitaion = 'positive') {
      let percentage_variation = 0
      let absolute_variation = this.normalizeNumber(valueBase, varitaion)

      if (valueReference !== 0.0) {
        absolute_variation =
          this.normalizeNumber(valueBase, varitaion) -
          this.normalizeNumber(valueReference, varitaion)
        if (isNaN(absolute_variation) || this.esInfinity(absolute_variation)) {
          absolute_variation = 0
        }

        percentage_variation =
          this.normalizeNumber(absolute_variation, varitaion) /
          this.normalizeNumber(valueReference, varitaion)
        if (isNaN(percentage_variation) || this.esInfinity(percentage_variation)) {
          percentage_variation = 0
        }
      }
      return [absolute_variation, percentage_variation]
    }

    getFormatter(pivotData) {
      let formatterFunction = usFmt
      if (formatter.hasOwnProperty(pivotData.props.aggregatorName)) {
        formatterFunction = formatter[pivotData.props.aggregatorName]
      }
      return formatterFunction
    }

    getAggregator(pivotData, rowKey, colKey) {
      try {
        const agg = pivotData.getAggregator(rowKey, colKey)
        return agg.value()
      } catch (e) {
        return 0
      }
    }

    renderColHeaderRow(attrName, attrIdx, pivotSettings) {
      const { colAttrs, rowAttrs, referenceBase, referenceBasePosition, visibleColKeys } =
        pivotSettings

      const spanCell =
        attrIdx === 0 && rowAttrs.length !== 0 ? (
          <th className={`header_0`} colSpan={rowAttrs.length} rowSpan={colAttrs.length} />
        ) : null

      const attrNameCell = (
        <th
          key="label"
          className={`pvtAxisLabel header_${rowAttrs.length} header_tot`}
          style={{ textAlign: 'right' }}
        >
          {attrName}
        </th>
      )

      const attrValueCells = []
      visibleColKeys.forEach((colKey, i) => {
        const x = spanSize(visibleColKeys, i, attrIdx)
        if (x === -1) {
          return null
        }
        const rowSpan = attrIdx === colAttrs.length - 1 && rowAttrs.length !== 0 ? 2 : 1

        const hasVar = attrIdx + 1 === colKey.length
        const hasReferenceBase = colKey[referenceBasePosition] === referenceBase
        let colSpan = x
        if (!hasReferenceBase && hasVar) {
          colSpan = 1
        } else if (hasReferenceBase && !hasVar) {
          colSpan = x * 3 - 2
        } else {
          colSpan = x
        }

        attrValueCells.push(
          <th className="pvtColLabel" key={`colKey-${i}`} colSpan={colSpan} rowSpan={rowSpan}>
            {colKey[attrIdx]}
          </th>,
        )
        if (!hasReferenceBase && hasVar) {
          attrValueCells.push(
            ...[
              <th
                className="pvtColLabel"
                key={'colKey-' + i + '-var-$'}
                colSpan={x}
                rowSpan={rowSpan}
              >
                VAR $
              </th>,
              <th
                className="pvtColLabel"
                key={'colKey-' + i + '-var-%'}
                colSpan={x}
                rowSpan={rowSpan}
              >
                VAR %
              </th>,
            ],
          )
        }
      })

      const totalCell =
        attrIdx === 0 ? (
          <th
            key="total"
            className="pvtTotalLabel"
            rowSpan={colAttrs.length + (rowAttrs.length === 0 ? 0 : 1)}
          >
            TOTAL
          </th>
        ) : null

      const cells = [spanCell, attrNameCell, ...attrValueCells, totalCell]
      return <tr key={`colAttr-${attrIdx}`}>{cells}</tr>
    }

    renderRowHeaderRow(pivotSettings) {
      const { rowAttrs, colAttrs } = pivotSettings
      return rowAttrs.length !== 0 ? (
        <tr key="rowHdr">
          {rowAttrs.map(function (r, i) {
            return (
              <th className={`pvtAxisLabel header_${i}`} key={`rowAttr${i}`}>
                {r}
              </th>
            )
          })}
          <th className={`pvtTotalLabel header_${rowAttrs.length} header_tot`}>
            {colAttrs.length === 0 ? 'Totals' : null}
          </th>
        </tr>
      ) : null
    }

    renderLinesRow(rowKey, rowIdx, pivotSettings, visibleRowKeys) {
      const { linesOrder, linesTotal, linesRatio, linesRatioN } = pivotSettings

      let isTree = false
      let flatRowKey = flatKey(rowKey)

      if (rowKey.length > 1) {
        flatRowKey = rowKey[0]
        isTree = true
      }
      const line = linesOrder?.find((it) => normalizeName(it.name) === flatRowKey)
      const lineTotal = linesTotal?.find((it) => normalizeName(it.name) === flatRowKey)
      const lineRatio = linesRatio?.find((it) => normalizeName(it.name) === flatRowKey)
      const lineRatioN = linesRatioN?.find((it) => normalizeName(it.name) === flatRowKey)
      const originalRowIdx = visibleRowKeys.findIndex((entry) => flatKey(entry) === flatKey(rowKey))

      if (lineTotal) {
        if (!isTree) {
          return this.renderTableTotalRow(rowKey, rowIdx, pivotSettings, lineTotal, line)
        }
      } else if (lineRatio) {
        if (!isTree) {
          return this.renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatio, usFmtPct, line)
        }
      } else if (lineRatioN) {
        if (!isTree) {
          return this.renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatioN, usFmt, line)
        }
      } else if (originalRowIdx < 0) {
        return this.renderTableLineZeroRow(rowKey, rowIdx, pivotSettings, line)
      } else {
        return this.renderTableRow(rowKey, originalRowIdx, pivotSettings, line)
      }
    }

    renderTableTotalRow(rowKey, rowIdx, pivotSettings, lineTotal, line) {
      const { visibleColKeys, rowAttrs, pivotData, referenceBasePosition, referenceBase } =
        pivotSettings
      const flatRowKey = flatKey(rowKey)
      const formatterFunction = this.getFormatter(pivotData)
      const variation = line?.calculation ?? 'positive'

      const lines = lineTotal.lines.reduce((mergedLines, it) => {
        return mergedLines.concat(it.lines)
      }, [])

      const attrValueCells = rowKey.map((r, i) => {
        return (
          <th
            key={`rowKeyLabel-${i}-${rowIdx}`}
            className={`pvtRowLabel fixed ${!line?.color ? 'pvtTotalizer' : ''}`}
            colSpan={rowAttrs.length + 1}
            style={{
              ...(line?.color
                ? { backgroundColor: line?.color }
                : { backgroundColor: '#eaeaea !important' }),
            }}
          >
            {r}
          </th>
        )
      })

      let valueCells = []
      visibleColKeys.forEach((colKey) => {
        const flatColKey = flatKey(colKey)
        const accumulator = lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          return acc + amount
        }, 0)

        const hasReferenceBase = colKey[referenceBasePosition] === referenceBase

        valueCells.push(
          <td
            className={`pvtVal 
                ${!line?.color ? 'pvtTotalizer' : ''} 
                ${!line?.color && hasReferenceBase ? 'pvtReferenceBase' : ' '}
              `}
            key={'pvtVal-' + flatColKey}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatterFunction(accumulator)}
          </td>,
        )

        if (!hasReferenceBase) {
          const colKeyReferenceBase = _.cloneDeep(colKey)
          colKeyReferenceBase[referenceBasePosition] = referenceBase
          const accumulatorBase = lines.reduce((acc, it) => {
            const amount = this.getAggregator(pivotData, [normalizeName(it)], colKeyReferenceBase)
            return acc + amount
          }, 0)

          const [absolute_variation, percentage_variation] = this.calculateVariation(
            accumulatorBase,
            accumulator,
            variation,
          )

          valueCells.push(
            ...[
              <td
                className={`pvtVal ${!line?.color ? 'pvtTotalizer' : ''}`}
                key={'pvtVal-' + flatColKey + '-var$'}
                style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
              >
                {formatterFunction(absolute_variation)}
              </td>,
              <td
                className={`pvtVal ${!line?.color ? 'pvtTotalizer' : ''}`}
                key={'pvtVal-' + flatColKey + '-var%'}
                style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
              >
                <CellStyle.Variation value={percentage_variation} amount={percentage_variation} />
              </td>,
            ],
          )
        }
      })

      const accumulator = lines.reduce((acc, it) => {
        const amount = this.getAggregator(pivotData, [normalizeName(it)], [])
        return acc + amount
      }, 0)

      const totalCell = (
        <td
          key="total"
          className={`pvtTotal ${!line?.color ? 'pvtTotalizer' : ''}`}
          style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {formatterFunction(accumulator)}
        </td>
      )

      const rowCells = [...attrValueCells, ...valueCells, totalCell]
      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    renderTableLineZeroRow(rowKey, rowIdx, pivotSettings, line) {
      const { visibleColKeys, rowAttrs, pivotData, referenceBasePosition, referenceBase } =
        pivotSettings
      const flatRowKey = flatKey(rowKey)
      const formatterFunction = this.getFormatter(pivotData)

      const attrValueCells = rowKey.map((r, i) => {
        return (
          <th
            key={`rowKeyLabel-${i}-${rowIdx}`}
            className={`pvtRowLabel fixed`}
            colSpan={rowAttrs.length + 1}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {r}
          </th>
        )
      })

      let valueCells = []
      visibleColKeys.forEach((colKey) => {
        const flatColKey = flatKey(colKey)
        const accumulator = 0

        const hasReferenceBase = colKey[referenceBasePosition] === referenceBase

        valueCells.push(
          <td
            className={`pvtVal ${!line?.color && hasReferenceBase ? 'pvtReferenceBase' : ' '}`}
            key={'pvtVal-' + flatColKey}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatterFunction(accumulator)}
          </td>,
        )

        if (!hasReferenceBase) {
          valueCells.push(
            ...[
              <td
                className={`pvtVal`}
                key={'pvtVal-' + flatColKey + 'var-$'}
                style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
              >
                {formatterFunction(accumulator)}
              </td>,
              <td
                className={`pvtVal`}
                key={'pvtVal-' + flatColKey + 'var-%'}
                style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
              >
                <CellStyle.Variation value={accumulator} amount={accumulator} />
              </td>,
            ],
          )
        }
      })

      const totalCell = (
        <td
          key="total"
          className="pvtTotal"
          style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {formatterFunction(0)}
        </td>
      )

      const rowCells = [...attrValueCells, ...valueCells, totalCell]
      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatio, formatter = usFmt, line) {
      const { visibleColKeys, rowAttrs, pivotData, referenceBasePosition, referenceBase } =
        pivotSettings
      const flatRowKey = flatKey(rowKey)

      let lines1 = []
      let lines2 = []
      if (!isEmpty(lineRatio.lines)) {
        lines1 = lineRatio.lines[0]
        lines2 = lineRatio.lines[1]
      }

      const variation = line?.calculation ?? 'positive'

      const attrValueCells = rowKey.map((r, i) => {
        return (
          <th
            key={`rowKeyLabel-${i}-${rowIdx}`}
            className={`pvtRowLabel fixed ${!line?.color ? 'pvtRatio' : ''}`}
            colSpan={rowAttrs.length + 1}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {r}
          </th>
        )
      })

      let valueCells = []
      visibleColKeys.forEach((colKey) => {
        const flatColKey = flatKey(colKey)

        let result = 0
        const accumulator1 = lines1.lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          return acc + amount
        }, 0)
        const accumulator2 = lines2.lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          return acc + amount
        }, 0)

        if (accumulator2 !== 0.0) {
          result = accumulator1 / accumulator2
        }

        const hasReferenceBase = colKey[referenceBasePosition] === referenceBase

        valueCells.push(
          <td
            className={`pvtVal 
              ${!line?.color ? 'pvtRatio' : ''} 
              ${!line.color && hasReferenceBase ? 'pvtReferenceBase' : ' '}
              `}
            key={'pvtVal-' + flatColKey}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatter(result)}
          </td>,
        )

        if (!hasReferenceBase) {
          const colKeyReferenceBase = _.cloneDeep(colKey)
          colKeyReferenceBase[referenceBasePosition] = referenceBase

          let resultAccumulatorBase = 0
          const accumulator1Base = lines1.lines.reduce((acc, it) => {
            const amount = this.getAggregator(pivotData, [normalizeName(it)], colKeyReferenceBase)
            return acc + amount
          }, 0)
          const accumulator2Base = lines2.lines.reduce((acc, it) => {
            const amount = this.getAggregator(pivotData, [normalizeName(it)], colKeyReferenceBase)
            return acc + amount
          }, 0)

          if (accumulator2Base !== 0.0) {
            resultAccumulatorBase = accumulator1Base / accumulator2Base
          }

          const [absolute_variation, percentage_variation] = this.calculateVariation(
            resultAccumulatorBase,
            result,
            variation,
          )

          valueCells.push(
            ...[
              <td
                className="pvtVal pvtRatio"
                key={'pvtVal-' + flatColKey + '-var$'}
                style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
              >
                {formatter(absolute_variation)}
              </td>,
              <td
                className="pvtVal pvtRatio"
                key={'pvtVal-' + flatColKey + '-var%'}
                style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
              >
                <CellStyle.Variation value={percentage_variation} amount={percentage_variation} />
              </td>,
            ],
          )
        }
      })

      let result = 0
      const accumulator1 = lines1.lines.reduce((acc, it) => {
        const amount = this.getAggregator(pivotData, [normalizeName(it)], [])
        return acc + amount
      }, 0)

      const accumulator2 = lines2.lines.reduce((acc, it) => {
        const amount = this.getAggregator(pivotData, [normalizeName(it)], [])
        return acc + amount
      }, 0)

      if (accumulator2 !== 0.0) {
        result = accumulator1 / accumulator2
      }

      const totalCell = (
        <td
          key="total"
          className={`pvtTotal ${!line?.color ? 'pvtRatio' : ''}`}
          style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {formatter(result)}
        </td>
      )

      const rowCells = [...attrValueCells, ...valueCells, totalCell]
      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    renderTableRow(rowKey, rowIdx, pivotSettings, line) {
      const {
        visibleColKeys,
        pivotData,
        rowAttrs,
        rowTotalColors,
        valueCellColors,
        referenceBase,
        referenceBasePosition,
      } = pivotSettings

      const flatRowKey = flatKey(rowKey)
      const formatterFunction = this.getFormatter(pivotData)
      const variation = line?.calculation ?? 'positive'

      const attrValueCells = rowKey.map((txt, j) => {
        const x =
          rowKey.length < rowAttrs.length ? rowAttrs.length + 1 : j === rowAttrs.length - 1 ? 2 : 1

        return (
          <th key={`rowKeyLabel-${rowIdx}-${j}`} className={`pvtRowLabel header_${j}`} colSpan={x}>
            {txt}
          </th>
        )
      })

      let valueCells = []
      visibleColKeys.forEach((colKey, j) => {
        const aggValue = this.getAggregator(pivotData, rowKey, colKey)
        const style = valueCellColors(rowKey, colKey, aggValue)

        const hasReferenceBase = colKey[referenceBasePosition] === referenceBase

        valueCells.push(
          <td
            className={`pvtVal ${hasReferenceBase ? 'pvtReferenceBase' : ' '}`}
            key={`pvtVal-${rowIdx}-${j}`}
            style={{ ...(style ? style : line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatterFunction(aggValue)}
          </td>,
        )

        if (!hasReferenceBase) {
          const colKeyReferenceBase = _.cloneDeep(colKey)
          colKeyReferenceBase[referenceBasePosition] = referenceBase
          const aggBase = pivotData.getAggregator(rowKey, colKeyReferenceBase)
          const valueBase = aggBase.value()
          const [absolute_variation, percentage_variation] = this.calculateVariation(
            valueBase,
            aggValue,
            variation,
          )

          valueCells.push(
            ...[
              <td
                className="pvtVal"
                key={`pvtVal-${rowIdx}-${j}-var$`}
                style={{ ...(style ? style : line?.color ? { backgroundColor: line?.color } : {}) }}
              >
                {formatterFunction(absolute_variation)}
              </td>,
              <td
                className="pvtVal"
                key={`pvtVal-${rowIdx}-${j}-var%`}
                style={{ ...(style ? style : line?.color ? { backgroundColor: line?.color } : {}) }}
              >
                <CellStyle.Variation value={percentage_variation} amount={percentage_variation} />
              </td>,
            ],
          )
        }
      })

      const aggValue = this.getAggregator(pivotData, rowKey, [])
      const style = rowTotalColors(aggValue)
      const totalCell = (
        <td
          key="total"
          className="pvtTotal"
          style={{ ...(style ? style : line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {formatterFunction(aggValue)}
        </td>
      )

      const rowCells = [...attrValueCells, ...valueCells, totalCell]
      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    render() {
      if (this.cachedProps !== this.props) {
        this.cachedProps = this.props
        this.cachedBasePivotSettings = this.getBasePivotSettings()
      }
      const {
        colAttrs,
        rowAttrs,
        colKeys,
        rowKeys,
        linesOrder,
        lineVertical,
        referenceBase,
        referenceBasePosition,
      } = this.cachedBasePivotSettings

      const sortedVisibleColKeys = sortedColHeader(referenceBase, colKeys, referenceBasePosition)

      const pivotSettings = Object.assign(
        {
          visibleColKeys: sortedVisibleColKeys,
        },
        this.cachedBasePivotSettings,
      )

      let visibleRowKeys = []
      linesOrder.forEach((it) => {
        if (it.type === 'grouper') {
          const listFilter = rowKeys.filter((entry) => it.name === entry[0])
          if (listFilter.length > 0) {
            const firstItem = listFilter[0].length
            const sortedFilter = listFilter.filter((entry) => entry.length === firstItem)
            visibleRowKeys.push(...sortedFilter)
          } else {
            visibleRowKeys.push([it.name])
          }
        } else {
          visibleRowKeys.push([it.name])
        }
      })

      return (
        <table className="pvtTable">
          <thead>
            {colAttrs.map((it, index) =>
              this.renderColHeaderRow(it, index, pivotSettings, lineVertical),
            )}
            {rowAttrs.length !== 0 && this.renderRowHeaderRow(pivotSettings)}
          </thead>
          <tbody>
            {visibleRowKeys.map((r, i) => this.renderLinesRow(r, i, pivotSettings, rowKeys))}
          </tbody>
        </table>
      )
    }
  }

  TableRenderer.defaultProps = PivotData.defaultProps
  TableRenderer.propTypes = PivotData.propTypes
  TableRenderer.defaultProps.aggregators = aggregators
  TableRenderer.defaultProps.tableColorScaleGenerator = redColorScaleGenerator
  TableRenderer.defaultProps.tableOptions = {}
  return TableRenderer
}

export default makeRenderer
