import { Input, Select, Space } from 'antd'
import { Typography } from 'modules/core/components'
import configuration from 'modules/configuration'
import { generateSelectOptions } from 'modules/core/utils'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import login from 'modules/login'

const SelectsOptions = ({
  setDataSelected,
  current,
  fetchAreasList,
  fetchCurrencyList,
  currencyList,
  areaList,
  loggedUser: { pkid },
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    fetchCurrencyList()
  }, [fetchCurrencyList])

  useEffect(() => {
    fetchAreasList({ user_id: pkid })
  }, [fetchAreasList, pkid])

  const CREATE_OPTIONS = [
    {
      id: 'EXPANSION',
      name: 'LABEL_EXPANSION',
    },
    {
      id: 'REDUCTION',
      name: 'LABEL_REDUCTION',
    },
    {
      id: 'RECLASSIFICATION',
      name: 'LABEL_RECLASSIFICATION',
    },
  ]

  const dataSelects = [
    {
      id: 'adjustment_type',
      name: 'FIELD_TYPE',
      options: CREATE_OPTIONS,
    },
    {
      id: 'currency',
      name: 'LABEL_CURRENCY',
      options: currencyList.data,
    },
    {
      id: 'area',
      name: 'LABEL_AREA',
      options: areaList,
    },
  ]

  return (
    <Space direction="horizontal">
      {dataSelects.map((data) => (
        <Space key={data.id} direction="vertical">
          <Typography.Body level={2}>{t(data.name)}</Typography.Body>
          <Select
            placeholder={t('ACTION_SELECT')}
            disabled={current > 0 && data.id === 'adjustment_type'}
            options={generateSelectOptions({
              options: data.options.map((el) => {
                return { ...el, name: t(el.name) }
              }),
            })}
            style={{ width: 200 }}
            onChange={(val) =>
              setDataSelected((prevState) => {
                return { ...prevState, [data.id]: val }
              })
            }
          />
        </Space>
      ))}
      <Space direction="vertical">
        <Typography.Body level={2}>{t('FIELD_OBSERVATIONS')}</Typography.Body>
        <Input.TextArea
          placeholder={t('WRITE_SOMETHING_PLACEHOLDER')}
          rows={1}
          style={{ width: 250 }}
          onChange={(v) =>
            setDataSelected((prevState) => {
              return { ...prevState, description: v.target.value }
            })
          }
        />
      </Space>
    </Space>
  )
}

const mapStateToProps = (state) => ({
  currencyList: configuration.selectors.getCurrencyList(state),
  areaList: configuration.selectors.getAreaList(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  fetchCurrencyList: configuration.actions.fetchCurrencyList,
  fetchAreasList: configuration.actions.fetchAreasList,
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectsOptions)
