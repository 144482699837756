import { Col, message, notification, Popconfirm, Row, Spin, Table } from 'antd'
import {
  Button,
  ButtonActionBar,
  DeleteAndUpdate,
  DropdownMenu,
  EmptyScreen,
  InputCell,
  Navigator,
  SelectCell,
} from 'modules/core/components'
import { SiderLayout } from 'modules/core/layouts'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { LevelCreateModal } from './components'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import configuration from 'modules/configuration'
import { generateMessageError } from 'modules/core/utils'

const AreaDetail = ({
  fetchAreaDetail,
  areaDetail,
  fetchLevels,
  levelsList,
  fetchUsersList,
  usersList,
  editLevel,
  deleteLevel,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const [editRow, setEditRow] = useState(null)
  const [dataCellEdit, setDataCellEdit] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  let { areaId } = useParams()
  const { t } = useTranslation()

  useEffect(() => {
    setIsLoading(true)
    Promise.all([fetchAreaDetail(areaId), fetchLevels(areaId)]).then(() => setIsLoading(false))
  }, [fetchAreaDetail, fetchLevels, areaId])

  useEffect(() => {
    fetchUsersList()
  }, [fetchUsersList])

  const onCellEdit = (name, value) => {
    setDataCellEdit({ ...dataCellEdit, [`${name}`]: value })
  }

  const onConfirm = () => {
    setEditRow(null)
    setDataCellEdit({})
  }

  const onCancel = () => setEditRow(null)

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion`,
        },
        {
          name: t('Areas'),
          url: '/configuracion/seguridad/?tab=areas',
        },
        {
          name: areaDetail?.name,
        },
      ]}
    />
  )

  const handleDelete = () => {
    setIsSaving(true)
    deleteLevel(deleteId)
      .then(() =>
        Promise.all([fetchAreaDetail(areaId), fetchLevels(areaId)]).then(() => {
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
          setIsSaving(false)
          setDeleteId(null)
        }),
      )
      .catch((error) => {
        setIsSaving(false)
        setDeleteId(null)
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error),
          duration: 0,
        })
      })
  }

  const handleEdit = (id) => {
    setIsSaving(true)
    editLevel(id, dataCellEdit)
      .then(() =>
        Promise.all([fetchAreaDetail(areaId), fetchLevels(areaId)]).then(() => {
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
          setIsSaving(false)
          setEditRow(null)
          setDataCellEdit({})
        }),
      )
      .catch((error) => {
        setIsSaving(false)
        setEditRow(null)
        setDataCellEdit({})
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error),
          duration: 0,
        })
      })
  }

  const columns = [
    {
      dataIndex: 'name',
      title: t('FIELD_NAME'),
      render: (name, row) => {
        if (editRow && row.id === editRow) {
          return <InputCell initialValue={name} name="name" onCellUpdate={onCellEdit} />
        } else {
          return name
        }
      },
    },
    {
      dataIndex: 'users',
      title: t('LABEL_USERS'),
      render: (users, row) => {
        if (editRow && row.id === editRow) {
          return (
            <SelectCell
              initialValue={users}
              name="users"
              onCellUpdate={onCellEdit}
              mode="multiple"
              maxTagCount={1}
              maxTagTextLength={8}
              showArrow
              allowClear
              style={{ width: 250 }}
              options={usersList?.values.map((el) => {
                return { id: el.id, name: el.full_name }
              })}
            />
          )
        } else {
          return users
            .map((el) =>
              usersList?.values.length > 0
                ? usersList?.values.find((user) => user.id === el).full_name
                : '',
            )
            .join(', ')
        }
      },
    },
    {
      dataIndex: 'id',
      title: t('FIELD_ACTIONS'),
      align: 'right',
      render: (id) => {
        return id !== editRow ? (
          <Popconfirm
            placement="bottomRight"
            title={t('PLANNING_SAC_DELETE_LEVEL_CONFIRMATION_POPCONFIRM_TITLE')}
            okText={t('ACTION_DELETE')}
            onConfirm={() => handleDelete()}
            okButtonProps={{ loading: isSaving }}
            cancelButtonProps={{ disabled: isSaving }}
            cancelText={t('ACTION_CANCEL')}
            onCancel={() => setDeleteId(null)}
            visible={deleteId === id}
          >
            <DropdownMenu
              title={t('ACTION_MORE')}
              menu={[
                {
                  title: t('ACTION_EDIT'),
                  icon: <EditOutlined />,
                  onClick: () => setEditRow(id),
                },
                {
                  title: t('ACTION_DELETE'),
                  icon: <DeleteOutlined />,
                  onClick: () => setDeleteId(id),
                },
              ]}
            />
          </Popconfirm>
        ) : (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSave={() => handleEdit(id)}
            dataCellEdit={dataCellEdit}
            loading={isSaving}
          />
        )
      },
    },
  ]

  return (
    <SiderLayout>
      {renderHeader()}
      <Spin spinning={isLoading}>
        {levelsList.length === 0 && (
          <EmptyScreen
            image={emptyStateImage}
            description={t('PLANNING_SAC_LEVEL_EMPTY_SCREEN')}
            footer={
              <Button.Primary title="ACTION_CREATE_NEW" onClick={() => setShowCreateModal(true)} />
            }
          />
        )}
        {levelsList.length > 0 && (
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <ButtonActionBar>
                <Button.Primary
                  title="ACTION_CREATE_NEW"
                  onClick={() => setShowCreateModal(true)}
                />
              </ButtonActionBar>
            </Col>
            <Col span={24}>
              <Table size="small" loading={isSaving} columns={columns} dataSource={levelsList} />
            </Col>
          </Row>
        )}
        <LevelCreateModal
          visible={showCreateModal}
          onClose={() => setShowCreateModal(false)}
          areaId={areaId}
          onRefetch={() => Promise.all([fetchAreaDetail(areaId), fetchLevels(areaId)])}
          usersList={usersList}
        />
      </Spin>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  areaDetail: configuration.selectors.getAreaDetail(state),
  levelsList: configuration.selectors.getLevelsList(state),
  usersList: configuration.selectors.getUsersList(state),
})

const mapDispatchToProps = {
  fetchAreaDetail: configuration.actions.fetchAreaDetail,
  fetchLevels: configuration.actions.fetchLevels,
  fetchUsersList: configuration.actions.fetchUsersList,
  editLevel: configuration.actions.editLevel,
  deleteLevel: configuration.actions.deleteLevel,
}

export default connect(mapStateToProps, mapDispatchToProps)(AreaDetail)
