import { Drawer, Space, Timeline } from 'antd'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { CheckCircleOutlined, StopOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Typography } from 'modules/core/components'
import planning from 'modules/planning'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const ApplicationHistoricalDrawer = ({
  visible,
  onClose,
  rowId,
  fetchStatusApplication,
  statusApplicationList,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (visible && rowId) {
      fetchStatusApplication(rowId)
    }
  }, [fetchStatusApplication, visible, rowId])

  const getStatus = (completed) => {
    return {
      requested: completed ? 'STATUS_REQUESTED' : 'ACTION_REQUEST',
      reviewed_ordinary_area: completed ? 'STATUS_REVIEWED' : 'ACTION_REVIEW',
      approved_ordinary_area: completed ? 'STATUS_DATE_APPROVE' : 'ACTION_APPROVE',
      rejected_ordinary_area: completed ? 'STATUS_REQUEST_REJECTED' : 'ACTION_REJECTED',
    }
  }

  const renderColor = (el) => {
    if (el.completed && !el.status.includes('rejected')) return 'green'
    if (!el.completed) return 'gray'
    if (el.completed && el.status.includes('rejected')) return 'red'
  }

  const renderDot = (el) => {
    if (el.completed && !el.status.includes('rejected')) return <CheckCircleOutlined />
    if (!el.completed) return <StopOutlined />
    if (el.completed && el.status.includes('rejected')) return <CloseCircleOutlined />
  }

  return (
    <Drawer
      title={t('PLANNING_SAC_HISTORY_APPLICATION_DRAWER_TITLE')}
      visible={visible}
      onClose={onClose}
    >
      <Timeline>
        {statusApplicationList.map((el) => (
          <Timeline.Item color={renderColor(el)} dot={renderDot(el)}>
            <Space direction="vertical">
              <Typography.Body>{t(getStatus(el.completed)[el.status])}</Typography.Body>
              <Space direction="vertical">
                <Typography.Body level={2}>{`${t('FIELD_NAME')}: ${
                  el.responsible_name
                }`}</Typography.Body>
                <Typography.Body level={2}>
                  {`${t('FIELD_DATE')}: ${moment(el.modified_at).calendar()}`}
                </Typography.Body>
              </Space>
            </Space>
          </Timeline.Item>
        ))}
      </Timeline>
    </Drawer>
  )
}

const mapStateToProps = (state) => ({
  statusApplicationList: planning.selectors.getStatusApplicationList(state),
})

const mapDispatchToProps = {
  fetchStatusApplication: planning.actions.fetchStatusApplication,
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationHistoricalDrawer)
