import { Col, message, notification, Popconfirm, Row, Space, Spin, Table } from 'antd'
import {
  Button,
  ButtonActionBar,
  DeleteAndUpdate,
  DropdownMenu,
  EmptyScreen,
  InputCell,
  Typography,
} from 'modules/core/components'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import configuration from 'modules/configuration'
import { AreaCreateModal } from './components'
import { useTranslation } from 'react-i18next'
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { generateMessageError } from 'modules/core/utils'
import { usePagination } from 'modules/core/customHooks'
import { useHistory } from 'react-router-dom'

const AreasList = ({ fetchAreasList, areaList, deleteArea, editArea }) => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const [editRow, setEditRow] = useState(null)
  const [dataCellEdit, setDataCellEdit] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [tablePagination, setTablePagination] = usePagination()
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  let history = useHistory()

  useEffect(() => {
    setIsLoading(true)
    fetchAreasList().then(() => setIsLoading(false))
  }, [fetchAreasList])

  const onCellEdit = (name, value) => {
    setDataCellEdit({ ...dataCellEdit, [`${name}`]: value })
  }

  const onConfirm = () => {
    setEditRow(null)
    setDataCellEdit({})
  }

  const onCancel = () => setEditRow(null)

  const handleDelete = () => {
    setIsSaving(true)
    deleteArea(deleteId)
      .then(() =>
        fetchAreasList().then(() => {
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
          setIsSaving(false)
          setDeleteId(null)
        }),
      )
      .catch((error) => {
        setIsSaving(false)
        setDeleteId(null)
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error),
          duration: 0,
        })
      })
  }

  const handleEdit = (id) => {
    setIsSaving(true)
    editArea(id, dataCellEdit)
      .then(() =>
        fetchAreasList().then(() => {
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
          setIsSaving(false)
          setDataCellEdit({})
          setEditRow(null)
        }),
      )
      .catch((error) => {
        setIsSaving(false)
        setDataCellEdit({})
        setEditRow(null)
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error),
          duration: 0,
        })
      })
  }

  const columns = [
    {
      dataIndex: 'name',
      title: t('FIELD_NAME'),
      render: (name, row) => {
        if (editRow && row.id === editRow) {
          return <InputCell initialValue={name} name="name" onCellUpdate={onCellEdit} />
        } else {
          return name
        }
      },
    },
    {
      dataIndex: 'code',
      title: t('FIELD_CODE'),
      render: (code, row) => {
        if (editRow && row.id === editRow) {
          return <InputCell initialValue={code} name="code" onCellUpdate={onCellEdit} />
        } else {
          return code
        }
      },
    },
    {
      dataIndex: 'levels',
      title: t('FIELD_LEVELS'),
      render: (levels) => levels.length,
    },
    {
      dataIndex: 'id',
      title: t('FIELD_ACTIONS'),
      align: 'right',
      render: (id) => {
        return id !== editRow ? (
          <Popconfirm
            placement="bottomRight"
            title={t('PLANNING_SAC_DELETE_AREA_CONFIRMATION_POPCONFIRM_TITLE')}
            okText={t('ACTION_DELETE')}
            onConfirm={() => handleDelete()}
            okButtonProps={{ loading: isSaving }}
            cancelButtonProps={{ disabled: isSaving }}
            cancelText={t('ACTION_CANCEL')}
            onCancel={() => setDeleteId(null)}
            visible={deleteId === id}
          >
            <Space size="middle">
              <Button.Icon
                icon={<Typography.Icon icon={EyeOutlined} />}
                title="Ver detalle"
                onClick={() => history.push(`/configuracion/areas/${id}`)}
              />
              <DropdownMenu
                title={t('ACTION_MORE')}
                menu={[
                  {
                    title: t('ACTION_EDIT'),
                    icon: <EditOutlined />,
                    onClick: () => setEditRow(id),
                  },
                  {
                    title: t('ACTION_DELETE'),
                    icon: <DeleteOutlined />,
                    onClick: () => setDeleteId(id),
                  },
                ]}
              />
            </Space>
          </Popconfirm>
        ) : (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSave={() => handleEdit(id)}
            dataCellEdit={dataCellEdit}
            loading={isSaving}
          />
        )
      },
    },
  ]

  return (
    <Spin spinning={isLoading}>
      {areaList.length === 0 && (
        <EmptyScreen
          image={emptyStateImage}
          description={t('PLANNING_SAC_AREA_EMPTY_SCREEN_DESCRIPTION')}
          footer={
            <Button.Primary title="ACTION_CREATE_NEW" onClick={() => setShowCreateModal(true)} />
          }
        />
      )}
      {areaList.length > 0 && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <ButtonActionBar>
              <Button.Primary title="ACTION_CREATE_NEW" onClick={() => setShowCreateModal(true)} />
            </ButtonActionBar>
          </Col>
          <Col span={24}>
            <Table
              size="small"
              columns={columns}
              dataSource={areaList}
              loading={isSaving}
              onChange={setTablePagination}
              pagination={{
                pageSize: tablePagination.page_size,
                current: tablePagination.page,
                total: areaList.length,
                size: 'small',
                pageSizeOptions: ['10', '20', '30'],
                showSizeChanger: true,
              }}
            />
          </Col>
        </Row>
      )}
      <AreaCreateModal
        visible={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onRefetch={() => fetchAreasList()}
      />
    </Spin>
  )
}

const mapStateToProps = (state) => ({
  areaList: configuration.selectors.getAreaList(state),
})

const mapDispatchToProps = {
  fetchAreasList: configuration.actions.fetchAreasList,
  deleteArea: configuration.actions.deleteArea,
  editArea: configuration.actions.editArea,
}

export default connect(mapStateToProps, mapDispatchToProps)(AreasList)
